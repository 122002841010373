<template>
    <div>
        <button @click="toggleModalEditar" type="button" class="btn btn-success btn-sm"><i class="fa fa-pencil-alt mr-2"></i>Editar</button>
        <div v-if="deleted" class="d-inline">
            <a :href="'/admin/usuarios/restaurar/'+user.id" role="button" class="btn btn-primary btn-sm"><i class="fa fa-check mr-2"></i>Restaurar</a>
            <a :href="'/admin/usuarios/apagar/'+user.id" role="button" class="btn btn-dark btn-sm"><i class="fa fa-trash mr-2"></i>Apagar</a>
        </div>
        <a v-else :href="'/admin/usuarios/remover/'+user.id" role="button" class="btn btn-danger btn-sm"><i class="fa fa-ban mr-2"></i>Remover</a>

        <div class="modal fade" id="modal_editar" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Editar {{ user.name }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row justify-content-center" v-if="informacoes != null">
                            <div class="col-lg-12">
                                <h5 class="mt-5 h3 text-primary w-100">Informações pessoais</h5>
                                <div class="form-group">
                                    <label class="control-label position-relative w-100"><span class="pr-5">Nome completo</span> <span class="float-right text-right font-weight-bold small text-primary">todos os campos são requisitados</span></label>
                                    <input autocomplete="off" type="text" class="form-control form-control-lg text-capitalize" v-model="data_to_send.nome_completo" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="control-label position-relative w-100">E-mail</label>
                                    <input autocomplete="off" type="email" class="form-control form-control-lg text-lowercase" v-model="data_to_send.email" required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label">Data de nascimento</label>
                                    <input autocomplete="off" type="date" class="form-control form-control-lg" v-model="data_to_send.data_nascimento" :max="vinteCincoAnos" required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label">Nacionalidade</label>
                                    <input autocomplete="off" type="text" class="form-control form-control-lg" v-model="data_to_send.nacionalidade"  required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label">Naturalidade/UF</label>
                                    <input autocomplete="off" @blur="checkUF('naturalidade')" ref="naturalidade" type="text" class="form-control form-control-lg" v-model="data_to_send.naturalidade"  required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label" for="profissao">Profissão</label>
                                    <input autocomplete="off" type="text" id="profissao" class="form-control form-control-lg" v-model="data_to_send.profissao"  required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label" for="estado_civil">Estado civil</label>
                                    <select v-model="data_to_send.estado_civil" id="estado_civil" class="form-control form-control-lg">
                                        <option :value="estado" :key="estado" v-for="estado in estados_civis">{{ estado }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label">Sexo</label>
                                    <div class="form-input-radio mt-3">
                                        <div class="custom-control custom-radio text-small">
                                            <input name="sexo" required type="radio" value="Masculino" class="custom-control-input" id="sexo_m" v-model="data_to_send.sexo">
                                            <label class="custom-control-label h6" for="sexo_m">
                                            Masculino
                                            </label>
                                        </div>

                                        <div class="custom-control custom-radio text-small">
                                            <input name="sexo" required type="radio" value="Feminino" class="custom-control-input" id="sexo_f" v-model="data_to_send.sexo">
                                            <label class="custom-control-label h6" for="sexo_f">
                                            Feminino
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12"><hr class="mt-0 mb-4"></div>
                            <div class="col-12">
                                <h5 class="mt-5 h3 text-primary">Filiação</h5>
                            </div>
                            <div class="col-12 col-lg-9">
                                <div class="form-group">
                                    <label class="control-label">Nome do pai</label>
                                    <input autocomplete="off" type="text" :disabled="data_to_send.filiacao_pai_nao_consta == true" class="form-control form-control-lg text-capitalize" v-model="data_to_send.filiacao_pai" >
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 align-self-center filiacao text-center text-lg-left">
                                <div class="form-group mt-3 mt-lg-7">
                                    <div class="custom-control custom-checkbox text-small">
                                        <input required type="checkbox" :false-value="0" :true-value="1" class="custom-control-input" id="filiacao_pai_nao_consta" v-model="data_to_send.filiacao_pai_nao_consta">
                                        <label class="custom-control-label h6" for="filiacao_pai_nao_consta">
                                            Não consta
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-9 mt-5 mt-lg-0">
                                <div class="form-group">
                                    <label class="control-label">Nome da mãe</label>
                                    <input autocomplete="off" type="text" :disabled="data_to_send.filiacao_mae_nao_consta == true" class="form-control form-control-lg text-capitalize" v-model="data_to_send.filiacao_mae" >
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 align-self-center filiacao text-center text-lg-left">
                                <div class="form-group mt-3 mt-lg-7">
                                    <div class="custom-control custom-checkbox text-small">
                                        <input required type="checkbox" :false-value="0" :true-value="1" class="custom-control-input" id="filiacao_mae_nao_consta" v-model="data_to_send.filiacao_mae_nao_consta">
                                        <label class="custom-control-label h6" for="filiacao_mae_nao_consta">
                                            Não consta
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12"><hr class="mt-0 mb-4"></div>
                            <div class="col-12">
                                <h5 class="mt-5 h3 text-primary">Documentos</h5>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="control-label">RG</label>
                                    <input autocomplete="off" type="text" class="form-control form-control-lg" v-mask="['##.###.###-#', '###.###.###-#']" v-model="data_to_send.rg" required>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <label class="control-label">Orgão emissor/UF</label>
                                    <input autocomplete="off" @blur="checkUF('orgao')" ref="orgao" type="text" class="form-control form-control-lg" v-model="data_to_send.rg_orgao" required>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="form-group">
                                    <label class="control-label">Data de emissão</label>
                                    <input autocomplete="off" type="date" class="form-control form-control-lg" v-model="data_to_send.rg_emissao" required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label">CPF</label>
                                    <input autocomplete="off" type="text" class="form-control form-control-lg" v-mask="'###.###.###-##'" v-model="data_to_send.cpf" required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label">Título de eleitor</label>
                                    <input type="text" autocomplete="off" class="form-control form-control-lg" v-mask="'#### #### ####'" v-model="data_to_send.titulo_eleitor" required>
                                </div>
                            </div>
                            <div class="col-lg-12"><hr class="mt-0 mb-4"></div>
                            <div class="col-12">
                                <h5 class="mt-5 h3 text-primary w-100">Telefone para contato</h5>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label class="control-label w-100" for="numero">Número <span class="text-primary float-right small">não esqueça o DDD</span></label>
                                    <input autocomplete="off" type="text" class="form-control form-control-lg" v-mask="['(##) ####-####', '(##) #####-####']" id="numero" v-model="data_to_send.telefone_numero" required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label d-block">Tipo</label>
                                    <div class="">
                                        <div class="custom-control custom-radio text-small">
                                            <input name="telefone_tipo" required type="radio" value="Residência" class="custom-control-input" id="telefone_tipo_residencia" v-model="data_to_send.telefone_tipo">
                                            <label class="custom-control-label h6" for="telefone_tipo_residencia">
                                            Residência
                                            </label>
                                        </div>

                                        <div class="custom-control custom-radio text-small">
                                            <input name="telefone_tipo" required type="radio" value="Comércio" class="custom-control-input" id="telefone_tipo_comercio" v-model="data_to_send.telefone_tipo">
                                            <label class="custom-control-label h6" for="telefone_tipo_comercio">
                                            Comércio
                                            </label>
                                        </div>

                                        <div class="custom-control custom-radio text-small">
                                            <input name="telefone_tipo" required type="radio" value="Celular" class="custom-control-input" id="telefone_tipo_celular" v-model="data_to_send.telefone_tipo">
                                            <label class="custom-control-label h6" for="telefone_tipo_celular">
                                            Celular
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12"><hr class="mt-0 mb-4"></div>
                            <div class="col-12">
                                <h5 class="mt-5 h3 text-primary w-100">Endereço residencial </h5>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label">Rua/Avenida/Estrada.. </label>
                                    <input autocomplete="off" type="text" class="form-control form-control-lg" v-model="data_to_send.residencia_endereco" required>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <label class="control-label">Número</label>
                                    <input autocomplete="off" type="text" class="form-control form-control-lg" v-model="data_to_send.residencia_numero"  required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="control-label w-100">Complemento <span class="text-primary small float-right">opcional</span></label>
                                    <input autocomplete="off" type="text" class="form-control form-control-lg" v-model="data_to_send.residencia_complemento" >
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label w-100">Bairro </label>
                                    <input autocomplete="off" type="text" class="form-control form-control-lg" v-model="data_to_send.residencia_bairro" required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label">CEP</label>
                                    <input autocomplete="off" type="text" class="form-control form-control-lg" v-mask="'##.###-###'" v-model="data_to_send.residencia_cep" required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label" for="residencia_estado">Estado</label>
                                    <select required v-model="data_to_send.residencia_estado" id="residencia_estado" class="form-control form-control-lg">
                                        <option :value="estado" v-for="(estado, index) in data_to_send.residencia_estado_options" :key="index">
                                            {{ estado.nome }}
                                            </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label" for="residencia_cidade">Cidade</label>
                                    <select required v-model="data_to_send.residencia_cidade" id="residencia_cidade" class="form-control form-control-lg">
                                        <option :value="cidade" v-for="(cidade, index) in data_to_send.residencia_cidade_options" :key="index">{{ cidade }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12"><hr class="mt-0 mb-4 py-5"></div>
                            <div class="col-lg-12 mb-5">
                                <h3 class="h3 text-primary">Informações importantes</h3>
                                <p class="font-weight-bold alert alert-primary text-center mt-2">Se o cliente se enquadrar em alguma opção abaixo, marque-a, essas informações podem influenciar a documentação necessária.</p>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="custom-control custom-checkbox text-small">
                                                <input required type="checkbox" :false-value="0" :true-value="1" class="custom-control-input" id="comprovante_residencia" v-model="data_to_send.comprovante_residencia">
                                                <label class="custom-control-label h6" for="comprovante_residencia">
                                                    Comprovante de residência em seu nome <span class="d-block d-lg-inline mt-3 mt-lg-0 small text-primary">(água/luz/telefone fixo) [últimos 90 dias]</span>

                                                    <p class="alert alert-info py-4 px-5 text-center text-lg-left mt-3" style="line-height: 1.8em;">
                                                        Se <strong>não</strong> tiver, será necessário na documentação que requisitar o comprovante adicionar o comprovante no nome do responsável do comprovante e uma declaração preenchida pelo responsável do comprovante declarando que você é morador do local.
                                                        <a v-if="data_to_send.comprovante_residencia == false" id="link-declaracao-imovel" class="btn btn-xs btn-outline-primary mt-2 imprimir d-block" href="/envios/arquivos/DECLARAÇÃO_DE_RESIDÊNCIA_CAC.pdf" target="_blank">
                                                            <i class="fa fa-print mr-3"></i> Imprimir modelo de declaração do responsável pelo comprovante
                                                        </a>
                                                        <span v-if="data_to_send.comprovante_residencia == true" class="alert alert-success d-none d-block"><i class="fa fa-check text-success mr-3"></i> você informou possuir um comprovante.</span>
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="custom-control custom-checkbox text-small">
                                                <input required type="checkbox" :false-value="0" :true-value="1" class="custom-control-input" id="confirmar_laudos" v-model="data_to_send.confirmar_laudos">
                                                <label class="custom-control-label h6" for="confirmar_laudos">
                                                    Dispensado da capacidade técnica

                                                    <p class="alert alert-info py-4 px-5 text-center text-lg-left mt-3" style="line-height: 1.8em;">
                                                        Estão dispensados da capacidade técnica: – Os integrantes dos órgãos, instituições e corporações mencionados nos incisos I, II e VI do caput do <a target="_blank" class="font-weight-bold" href="http://www.planalto.gov.br/ccivil_03/Leis/2003/L10.826.htm">art. 6º da Lei 10.826/03</a>. – O interessado que comprove estar autorizado a portar arma da mesma espécie daquela a ser adquirida, desde que o porte de arma de fogo esteja válido e o interessado tenha se submetido a avaliações em período não superior a um ano, contado a partir do requerimento de aquisição (<a target="_blank" class="font-weight-bold" href="https://www.jusbrasil.com.br/topicos/10957125/paragrafo-6-artigo-12-do-decreto-n-5123-de-01-de-julho-de-2004">§6º do art. 12 do Decreto 5.123/04)</a>. – As pessoas físicas que solicitarem concessão e revalidação de CR para colecionamento e as pessoas jurídicas.
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="custom-control custom-checkbox text-small">
                                                <input required type="checkbox" :false-value="0" :true-value="1" class="custom-control-input" id="psicologica" v-model="data_to_send.psicologica">
                                                <label class="custom-control-label h6" for="psicologica">
                                                    Dispensado da aptidão psicológica

                                                    <p class="alert alert-info py-4 px-5 text-center text-lg-left mt-3" style="line-height: 1.8em;">
                                                        A aptidão psicológica deve ser expedida por psicólogo registrado no respectivo conselho de classe.
                                                        A validade do laudo psicológico é de três anos.
                                                        Estão dispensados da aptidão psicológica os integrantes dos órgãos, instituições e corporações mencionados nos incisos I, II e VI do caput do <a target="_blank" class="font-weight-bold" href="http://www.planalto.gov.br/ccivil_03/Leis/2003/L10.826.htm">art. 6º da Lei 10.826/03</a>, os magistrados e os membros do Ministério Público, desde que no serviço ativo e com estabilidade.
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="custom-control custom-checkbox text-small">
                                                <input required type="checkbox" :false-value="0" :true-value="1" class="custom-control-input" id="confirmar_certidoes" v-model="data_to_send.confirmar_certidoes">
                                                <label class="custom-control-label h6" for="confirmar_certidoes">
                                                    Dispensado de certidões negativas

                                                    <p class="alert alert-info py-4 px-5 text-center text-lg-left mt-3" style="line-height: 1.8em;">
                                                        Estão dispensados da aptidão psicológica os integrantes dos órgãos, instituições e corporações mencionados nos incisos I, II e VI do caput do <a target="_blank" class="font-weight-bold" href="http://www.planalto.gov.br/ccivil_03/Leis/2003/L10.826.htm">art. 6º da Lei 10.826/03</a>, os magistrados e os membros do Ministério Público, desde que no serviço ativo e com estabilidade.
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="informacoes != null">
                            <div class="col-lg-12"><hr class="mt-0 mb-4 pt-5"></div>
                            <div class="col-lg-12" id="cac">
                                <h3 class="h3 text-white py-3 bg-primary text-center">CACs</h3>
                            </div>
                        </div>
                        <div v-show="e_cac" class="row">
                            <div class="col-lg-12 mb-5">
                                <h3 class="h3 text-primary pt-5">Informações</h3>
                                <p class="font-weight-bold alert alert-danger text-center mt-2">Se ainda <u>não</u> tiver um Certificado de Registro pelo Exército Brasileiro, pode deixar estes campos em branco.</p>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="control-label">Clube de tiro</label>
                                    <input autocomplete="off" type="text" class="form-control form-control-lg" v-model="data_to_send.clube" >
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="clube_estado">Estado do clube de tiro</label>
                                    <select required v-model="data_to_send.clube_estado" id="clube_estado" class="form-control form-control-lg">
                                        <option :value="estado" :key="estado.nome" v-for="estado in data_to_send.clube_estado_options">{{ estado.nome }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="control-label" for="clube_cidade">Cidade do clube de tiro</label>
                                    <select required v-model="data_to_send.clube_cidade" id="clube_cidade" class="form-control form-control-lg">
                                        <option :value="cidade" :key="cidade" v-for="cidade in data_to_send.clube_cidade_options">{{ cidade }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label">Número do CR <span data-toggle="tooltip" data-title="CAC verificado" v-if="informacoes.verificado == 1"><i class="far fa-check-circle text-primary"></i></span></label>
                                            <input type="tel" :disabled="informacoes.verificado == 1" autocomplete="off" v-mask="'##############'" class="form-control form-control-lg" v-model="data_to_send.cr">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label">Validade do CR</label>
                                            <input type="date" autocomplete="off" class="form-control form-control-lg" v-model="data_to_send.cr_validade">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <p class="text-center alert alert-success" v-if="vencido == false && diferenca_mes > 3">
                                            Tudo certo, o CR ainda possui {{ diferenca_mes }} meses de validade.
                                        </p>
                                        <p class="text-center alert alert-warning" v-if="vencido == false && diferenca_mes < 3">
                                            Hmm, o CR está vencendo em {{ diferenca_mes }} meses.
                                        </p>
                                        <p class="text-center alert alert-danger" v-if="vencido == true">
                                            Nada bom, o CR venceu há {{ diferenca_mes }} meses.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label" for="rm">Região militar</label>
                                            <select v-model="data_to_send.rm" id="rm" class="form-control form-control-lg">
                                                <option :value="index+1" :key="rm" v-for="(rm, index) in rms">{{ rm }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="alert alert-warning text-center" style="border-radius: 8px">Este campo será preenchido automáticamente de acordo com o endereço <strong>se você não</strong> escolher uma Região Militar.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="!e_cac" class="row justify-content-center align-itens-center">
                            <div class="col-12 py-5">
                                <h3>Se já for CAC clique abaixo:</h3>
                            </div>
                            <div class="col-12 pb-7">
                                <div class="d-flex text-center justify-content-center">
                                    <button @click="sou_cac = true" type="button" class="d-block px-6 btn rounded btn-primary btn-lg">
                                            <i class="fa fa-crosshairs mr-3"></i>É <strong>CAC</strong>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <button @click="salvar" id="ficha" :disabled="enviando" :class="'btn btn-'+ botao_cor +' btn-block rounded'" v-html="botao"></button>
                        </div>
                        <div class="col-12 py-7 mt-5 text-center" v-if="clone == true" data-aos="fade-down" data-aos-delay="500" data-aos-once="true" >
                            <h2>Já existe uma conta em nosso sistema com essas informações</h2>
                            <p class="lead text-left px-5 px-lg-7">Caso tenha esquecido sua senha saia desta conta clicando na imagem de exibição no canto superior direito e clique em seguida em <span class="font-weight-bold">Sair da conta</span>.<br> Clique em <span class="font-weight-bold">Entrar em sua conta</span> e escolha a opção <span class="font-weight-bold">Esqueci minha senha</span> para recuperar sua conta caso tenha esquecido sua senha.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs';
    export default {
        name: 'AdminUsuarioControles',
        props: {
            deleted: Boolean,
            user: Object,
        },
        data() {
            return {
                data_to_send: {},
                informacoes: false,
                modal_editar: false,
                sou_cac: false,
                clone:false,
                enviando: false,

				botao: '<i class="fa fa-check mr-3"></i>Clique aqui para salvar alterações',
				botao_cor: 'primary',
				desabilitar: false,

                data_to_send: {
	                //-- usuário
	                nome_completo: null,
	                email: null,
	                //-- informações
	                data_nascimento: null,
	                nacionalidade: null,
	                naturalidade: null,
	                profissao: null,
	                estado_civil: null,
	                sexo: null,
	                //-- filiação
	                filiacao_pai: null,
	                filiacao_mae: null,
	                filiacao_pai_nao_consta: null,
	                filiacao_mae_nao_consta: null,
	                //-- documentos
	                rg: null,
	                rg_orgao: null,
	                rg_emissao: null,
	                cpf: null,
	                titulo_eleitor: null,
	                //-- contato
	                telefone_numero: null,
	                telefone_tipo: null,
	                //-- endereço
	                residencia_endereco: null,
	                residencia_numero: null,
	                residencia_complemento: null,
	                residencia_bairro: null,
	                residencia_cep: null,
	                residencia_estado_options: null,
	                residencia_estado: null,
	                residencia_estado_selecionado: null,
	                residencia_cidade_options: [],
	                residencia_cidade: null,
	                //-- comprovantes
	                confirmar_certidoes: false,
	                confirmar_laudos: false,
	                psicologica: false,
	                comprovante_residencia: false,
	                clube_estado_options: null,
	                clube_cidade_options: [],
	                clube_estado: null,
	                clube_estado_selecionado: null,
	                clube_cidade: null,
	                rm: null,
	            },
                rms: [
	                '1ª Região Militar',
	                '2ª Região Militar',
	                '3ª Região Militar',
	                '4ª Região Militar',
	                '5ª Região Militar',
	                '6ª Região Militar',
	                '7ª Região Militar',
	                '8ª Região Militar',
	                '9ª Região Militar',
	                '10ª Região Militar',
	                '11ª Região Militar',
	                '12ª Região Militar',
	            ],
	            estados_civis: [
	            	'Solteiro(a)',
	            	'União estável',
	            	'Casado(a)',
	            	'Divorciado(a)',
	            	'Separado(a)',
	            	'Viúvo(a)',
	            ]
            }
        },
        mounted() {
            $(document).on('shown.bs.modal', '.modal', function () {
                $('.modal-backdrop').before($(this));
            });
            if(this.user) {
                this.getEstadosECidades();
                this.informacoes = true;
                this.data_to_send = this.user.ficha;
                if(this.user.cr) {
                    this.sou_cac = true;
                }
                this.data_to_send.nome_completo = this.user.name;
                this.data_to_send.email = this.user.email;

                this.data_to_send.residencia_endereco = this.user.endereco.rua;
                this.data_to_send.residencia_numero = this.user.endereco.numero;
                this.data_to_send.residencia_complemento = this.user.endereco.complemento;
                this.data_to_send.residencia_bairro = this.user.endereco.bairro;
                this.data_to_send.residencia_cep = this.user.endereco.cep;

                this.data_to_send.telefone_numero = this.user.telefone.numero;
                if(this.user.telefone.residencia) {
                    this.data_to_send.telefone_tipo = 'Residência';
                } else if(this.user.telefone.celular) {
                    this.data_to_send.telefone_tipo = 'Celular';
                } else if (this.user.telefone.comercio) {
                    this.data_to_send.telefone_tipo = 'Comércio';
                }
            }
        },
        methods: {
            getEstadosECidades: function () {
                const instance = axios.create({
	                baseURL: 'http://'+window.location.hostname,
	            });
	            instance.get('/json/estados_cidades.json')
	            .then(res => {
	                // console.log(res);
	                this.data_to_send.clube_estado_options = res.data;
                    this.data_to_send.residencia_estado_options = res.data;

                    if (this.user.ficha.clube_estado != null && this.data_to_send.clube_estado_options != null && this.data_to_send.clube_estado_selecionado == null) {
                        this.data_to_send.clube_estado_selecionado = this.user.ficha.clube_estado;
                        var opcoes = this.data_to_send.clube_estado_options;
                        var result = opcoes.filter(obj => obj.nome.toLowerCase() == this.user.ficha.clube_estado.toLowerCase());
                        this.data_to_send.clube_estado = result[0];
                        this.data_to_send.clube_cidade_options = this.data_to_send.clube_estado.cidades;
                    }

                    if (this.user.ficha.clube_cidade != null && this.data_to_send.clube_cidade == null) {
                        this.data_to_send.clube_cidade = this.user.ficha.clube_cidade;
                    }

                    if (this.user.endereco.estado != null && this.data_to_send.residencia_estado_options != null && this.data_to_send.residencia_estado_selecionado == null) {
                        var opcoes = this.data_to_send.residencia_estado_options;
                        var result = opcoes.filter(obj => obj.nome.toLowerCase() == this.user.endereco.estado.toLowerCase());
                        this.data_to_send.residencia_estado = result[0];
                        this.data_to_send.residencia_estado_selecionado = this.data_to_send.residencia_estado.nome;
                        this.data_to_send.residencia_cidade_options = this.data_to_send.residencia_estado.cidades;
                    }
                    if (this.user.endereco.cidade != null && this.data_to_send.residencia_cidade == null) {
                        this.data_to_send.residencia_cidade = this.user.endereco.cidade;
                    }
                });
            },
            checkUF: function(valor) {
	          if(valor == 'naturalidade') {
	            if(!this.data_to_send.naturalidade.includes('/')) {
	                this.$toast.error('Em Naturalidade não se esqueça de colocar a Unidade da Federação.<br />Ex: Rio de Janeiro = RJ, ficando então: '+this.data_to_send.naturalidade+'/RJ', "", {
	                    timeout:9999,
	                    progressBar: false,
	                    position: "center",
	                    overlay: true,
	                    close: false,
	                    drag: false,
	                    id: 'uf',
	                    displayMode: 'once',
	                    buttons: [
	                        ['<button>Ok</button>', function (instance, toast) {
	                            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
	                        }, true], // true to focus
	                    ]
	                });
	                this.$refs.naturalidade.focus();
	            } else if (this.data_to_send.naturalidade.split('/')[1].length > 2) {
	                this.$toast.error('Em Naturalidade confira se a Unidade da Federação está correto.<br />Ex: Rio de Janeiro = RJ, ficando então: '+this.data_to_send.naturalidade.split('/')[0]+'/RJ', "", {
	                    timeout:9999,
	                    progressBar: false,
	                    position: "center",
	                    overlay: true,
	                    close: false,
	                    drag: false,
	                    id: 'uf',
	                    displayMode: 'once',
	                    buttons: [
	                        ['<button>Ok</button>', function (instance, toast) {
	                            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
	                        }, true], // true to focus
	                    ]
	                });
	            } else if (this.data_to_send.naturalidade.split('/')[0].length == 2) {
                    this.$toast.error('Em Naturalidade preencha o nome da cidade por extenso.<br />Ex: RJ = Rio de Janeiro, ficando então: '+this.data_to_send.naturalidade.split('/')[0]+'/RJ', "", {
                        timeout:9999,
                        progressBar: false,
                        position: "center",
                        overlay: true,
                        close: false,
                        drag: false,
                        id: 'uf',
                        displayMode: 'once',
                        buttons: [
                            ['<button>Ok</button>', function (instance, toast) {
                                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                            }, true], // true to focus
                        ]
                    });
                }
	          } else if(valor == 'orgao') {
	            if(!this.data_to_send.rg_orgao.includes('/')) {
	                this.$toast.error('Em Orgão de emissão não se esqueça de colocar a Unidade da Federação.<br />Ex: Rio de Janeiro = RJ, ficando então: '+this.data_to_send.rg_orgao+'/RJ', "", {
	                    timeout:9999,
	                    progressBar: false,
	                    position: "center",
	                    overlay: true,
	                    close: false,
	                    drag: false,
	                    id: 'uf',
	                    displayMode: 'once',
	                    buttons: [
	                        ['<button>Ok</button>', function (instance, toast) {
	                            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
	                        }, true], // true to focus
	                    ]
	                });
	              this.$refs.orgao.focus();
                } else if (this.data_to_send.rg_orgao.split('/')[1].length > 2) {
	                this.$toast.error('Em Orgão de emissão confira se a Unidade da Federação está correto.<br />Ex: Rio de Janeiro = RJ, ficando então: '+this.data_to_send.rg_orgao.split('/')[0]+'/RJ', "", {
	                    timeout:9999,
	                    progressBar: false,
	                    position: "center",
	                    overlay: true,
	                    close: false,
	                    drag: false,
	                    id: 'uf',
	                    displayMode: 'once',
	                    buttons: [
	                        ['<button>Ok</button>', function (instance, toast) {
	                            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
	                        }, true], // true to focus
	                    ]
	                });
	            }
	          }
	        },
            toggleModalEditar() {
                if (modal_editar) {
                    $('#modal_editar').modal('hide');
                    this.informacoes = false;
                }
                $('#modal_editar').modal('show');
                this.informacoes = true;
            },
            salvar: function() {
                this.botao = '<i class="fa fa-spin fa-spinner mr-3"></i>Salvando alterações';
                this.botao_cor = 'primary';
                this.enviando = true;
	            if (!this.data_to_send.residencia_endereco && !this.data_to_send.residencia_numero && !this.data_to_send.residencia_bairro && !this.data_to_send.residencia_cep && !this.data_to_send.telefone_numero && !this.data_to_send.telefone_tipo && !this.data_to_send.sexo && !this.data_to_send.nome_completo) {
                    this.botao = "Você possui campos obrigatórios em branco";
                    this.botao_cor = "danger";
                    this.botao = '<i class="fa fa-check mr-3"></i>Clique aqui para salvar alterações';
                    this.botao_cor = 'primary';
                    this.enviando = false;
	            } else {
	                this.botao = "<i class='fa fa-spin fa-spinner mr-2'></i>Salvando";
	                axios.post('/admin/usuarios/atualizar/'+this.user.id, this.data_to_send)
	                .then(res => {
	                  // console.log(res);
	                  var mk = this;
	                  this.botao = "Salvo, atualizando página";
	                  this.botao_cor = "success";
	                  setTimeout(function() {
                        document.location.reload();
	                  }, 2000);
	                })
	                .catch(error => {
	                  if (error == 'Já existe uma conta com essas informações') {
	                    this.clone = true;
	                    this.botao = error.response.data.error + ", veja abaixo como prosseguir..";
	                  } else {
	                    this.clone = false;
	                    this.botao = error.response.data.error + ", tente novamente..";
	                  }
	                  this.botao_cor = "danger";
                      this.enviando = false;
	                });
	            }
	        }
        },
		computed: {
            vinteCincoAnos: function() {
	          var dia = ("0" + new Date().getDate()).slice(-2);
	          var mes = ("0" + new Date().getMonth()).slice(-2);
	          var ano = new Date().getFullYear()-18;
	          return ano+'-'+mes+'-'+dia
	        },
	        vencido: function () {
	            if(this.data_to_send.cr_validade) {
	                if (dayjs(new Date()).isBefore(dayjs(this.data_to_send.cr_validade), 'day')) {
	                    return false;
	                } else {
	                    return true;
	                }
	            }
	        },
	        diferenca_mes: function () {
	            if(this.data_to_send.cr_validade) {
	                return Math.abs(dayjs(this.data_to_send.cr_validade).diff(dayjs(new Date()), 'month'));
	            }
            },
            e_cac: {
                get() {
                    if(this.user.ficha.cr)
                        return this.sou_cac = true;
                    else
                        return this.sou_cac;
                },
                set(newvalue) {
                    this.sou_cac = newvalue;
                }
            }
	    },
        watch: {
            "data_to_send.residencia_estado" (val) {
                if (this.data_to_send.residencia_estado != null) {
                    this.data_to_send.residencia_estado_selecionado = val.nome;
                    this.data_to_send.residencia_cidade_options = val.cidades;
                }
	        },
	        "data_to_send.clube_estado" (val) {
	            this.data_to_send.clube_estado_selecionado = val.nome;
	            this.data_to_send.clube_cidade_options = val.cidades;
	        },
        }
    }
</script>

<style scoped>

</style>
