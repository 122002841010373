<template>
	<form class="col-xl-12" method="post" @submit.prevent="fazerPedido()" v-if="documentacao != null">
		<p class="lead">
			Confirme abaixo:
		</p>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_informacoes" value="true" class="custom-control-input" id="leu-informacoes">
			    <label class="custom-control-label h6" for="leu-informacoes">Eu {{ nome }}, afirmo que os dados que estão em minha <a href="/minhas-informacoes">ficha de informações</a> estão corretos.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_termos" value="true" class="custom-control-input" id="leu-termos">
			    <label class="custom-control-label h6" for="leu-termos">Eu {{ nome }}, li os <a href="/termos" target="_blank">termos de uso</a> e <a href="/privacidade" target="_blank">privacidade</a> e concordo com os mesmos para a utilização dos serviços oferecidos pelo cacdocs.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_checklist" value="true" class="custom-control-input" id="leu-checklist">
			    <label class="custom-control-label h6" for="leu-checklist">Eu {{ nome }}, li as informações da documentação <a :href="'/documentacoes/'+ documentacao.categoria.slug +'/'+ documentacao.slug" target="_blank">{{ documentacao.nome }}</a> e sua <a :href="'/documentacoes/checklist/'+ documentacao.slug" target="_blank">lista de verificação</a> e sei quais documentos serão gerados pelo cacdocs e quais são de minha responsabilidade.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_custos" value="true" class="custom-control-input" id="leu-custos">
			    <label class="custom-control-label h6" for="leu-custos">Eu {{ nome }}, entendo que não é responsabilidade do cacdocs o <span class="text-primary">pagamento de taxas</span> e <span class="text-primary">custos</span> sendo esses de minha responsabilidade. Exemplo: Guia de Recolhimento da União (GRU).</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_comprovante" value="true" class="custom-control-input" id="possui-comprovante">
			    <label class="custom-control-label h6" for="possui-comprovante">Eu {{ nome }}, confirmo que possuo um comprovante de residência <strong class="text-primary">(luz ou água ou telefone fixo)</strong> dos últimos <strong class="text-primary">90 dias</strong> em meu nome ou em nome de terceiro, neste caso adicionarei a <a href="/envios/arquivos/DECLARAÇÃO_DE_RESIDÊNCIA_CAC.pdf" target="_blank">declaração do responsável pelo imóvel</a> com firma reconhecida e cópia de documento de identidade junto ao comprovante de residência na documentação.</label>
			</div>
		</div>
		<div v-if="enviando == true" class="text-center py-11">
			<i class="fa fa-spin fa-spinner fa-2x"></i>
		</div>
		<span v-if="enviando == false">
			<!-- <p class="lead mt-7 mb-0">
				Preencha abaixo e adicione a listagem as atividades presentes em seu CR.
			</p>
			<p>Digite como está escrito em seu Certificado de Registro:</p>
			<div class="row justify-content-between align-items-center pb-5 no-gutters">
				<div class="col-7 col-lg-9">
					<input placeholder="Ex: Tiro desportivo" type="text" v-model="atividade_atual" class="form-control">
				</div>
				<div class="col-5 col-md-3 text-right">
					<button type="button" @click="adicionarALista()" class="btn btn-primary"><i class="fa fa-plus mr-3 d-none d-lg-inline"></i>Adicionar</button>
				</div>
			</div>
			<div class="form-row border rounded justify-content-between align-items-center" v-for="(atividade, index) in atividades" v-if="atividades.length > 0">
				<div class="col-8 align-self-center">
					<h3 class="mb-0"><strong class="small">#{{index+1}}</strong> {{ atividade }}</h3>
				</div>
				<div class="col-4 text-right py-2">
					<button type="button" @click="removerDaLista(index)" class="btn btn-sm btn-danger"><i class="fa fa-trash"></i><span class="d-none d-lg-inline ml-3">Remover</span></button>
				</div>
			</div>
			<div class="form-group pt-6">
				<div class="custom-control custom-checkbox text-small">
				    <input required type="checkbox" name="confirma_atividades" value="true" class="custom-control-input" id="confirma-atividades">
				    <label class="custom-control-label h6" for="confirma-atividades">Eu {{ nome }}, confirmo que as atividades adicionadas acima estão corretas e foram preenchidas conforme estão descritas no meu Certificado de Registro.</label>
				</div>
			</div> -->
			<p class="lead mt-7 mb-0">
				Informações do segundo endereço.
			</p>
			<p>Preencha abaixo o formulário com as informações de sua <strong>segunda</strong> residência:</p>
			<div class="form-row">
				<div class="col-lg-6">
	                <div class="form-group">
	                    <label class="control-label">Rua/Avenida/Estrada.. </label>
	                    <input autocomplete="off" type="text" class="form-control" v-model="residencia_endereco" required>
	                </div>
	            </div>
	            <div class="col-lg-2">
	                <div class="form-group">
	                    <label class="control-label">Número</label>
	                    <input autocomplete="off" type="text" class="form-control" v-model="residencia_numero"  required>
	                </div>
	            </div>
	            <div class="col-lg-4">
	                <div class="form-group">
	                    <label class="control-label w-100">Complemento <span class="text-primary small float-right">opcional</span></label>
	                    <input autocomplete="off" type="text" class="form-control" v-model="residencia_complemento" >
	                </div>
	            </div>
	            <div class="col-lg-6">
	                <div class="form-group">
	                    <label class="control-label w-100">Bairro </label>
	                    <input autocomplete="off" type="text" class="form-control" v-model="residencia_bairro" required>
	                </div>
	            </div>
	            <div class="col-lg-6">
	                <div class="form-group">
	                    <label class="control-label">CEP</label>
	                    <input autocomplete="off" type="text" class="form-control" v-mask="'##.###-###'" v-model="residencia_cep" required>
	                </div>
	            </div>
	            <div class="col-lg-6">
	                <div class="form-group">
	                    <label class="control-label" for="residencia_estado">Estado</label>
	                    <select required v-model="residencia_estado" id="residencia_estado" class="form-control">
	                    	<option :value="estado" v-for="estado in residencia_estado_options">{{ estado.nome }}</option>
	                    </select>
	                </div>
	            </div>
	            <div class="col-lg-6">
	                <div class="form-group">
	                    <label class="control-label" for="residencia_cidade">Cidade</label>
	                    <select required v-model="residencia_cidade" id="residencia_cidade" class="form-control">
	                    	<option :value="cidade" v-for="cidade in residencia_cidade_options">{{ cidade }}</option>
	                    </select>
	                </div>
	            </div>
			</div>
			<div class="form-group pt-6">
				<div class="custom-control custom-checkbox text-small">
				    <input required type="checkbox" name="confirma_endereco" value="true" class="custom-control-input" id="confirma-endereco">
				    <label class="custom-control-label h6" for="confirma-endereco">Eu {{ nome }}, confirmo que as informações acima estão corretas.</label>
				</div>
			</div>
		</span>
		<div class="form-group">
			<button :disabled="pedindo" class="btn btn-primary" v-html="botao"></button>
		</div>
	</form>
</template>

<script>
	export default {
		name: 'Cr013',
		data() {
			return {
				enviando: false,
				nome: 'Usuário',

				atividades: [],
				atividade_atual: null,
				residencia_endereco: null,
				residencia_numero: null,
				residencia_complemento: null,
				residencia_bairro: null,
				residencia_cidade: null,
				residencia_estado: null,
				residencia_cep: null,

				residencia_cidade_options: [],
				residencia_estado_selecionado: null,
				residencia_estado_options: [],

				documentacao: null,
                currentURL: window.location.pathname,
                botao: '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento',
                pedindo: false,
			}
		},
		mounted() {
			this.getAcervo();
			this.getNome();
			this.getDocumentacao();
			this.getEstadosECidades();
		},
		methods: {
			getAcervo: function () {
				var app = this;
				axios.get('/acervo')
				.then((res) => {
					app.acervos = res.data;
				});
			},
			getNome: function () {
				var app = this;
				axios.get('/info/name')
				.then((res) => {
					app.nome = res.data;
				});
			},
			getDocumentacao: function () {
				var app = this;
				axios.get('/documentacao/'+this.currentURL.replace('/contratar/', ''))
				.then((res) => {
					app.documentacao = res.data;
				});
			},
			getEstadosECidades: function () {
				const instance = axios.create({
	                baseURL: window.location.origin,
	            });
	            instance.get('/json/estados_cidades.json')
	            .then(res => {
	                // console.log(res);
	                this.residencia_estado_options = res.data;
	            })
	            .catch(err => {
	                // console.error(err);
	                this.$toast.error('Não conseguimos carregar os estados e cidades, vamos atualizar esta página');
	                setTimeout(function () {
	                    location.reload();
	                }, 2000);
	            });
			},
			adicionarALista: function () {
				if(this.atividade_atual != null)
					this.atividades.push(this.atividade_atual);
				this.atividade_atual = null;
			},
			removerDaLista: function (index) {
				this.$delete(this.atividades, index);
			},
			fazerPedido: function () {
            	this.botao = '<i class="fa fa-spin fa-spinner mr-2"></i>Concluindo pedido e direcionando para pagamento';
				this.pedindo = true;
				if(this.residencia_endereco == null || this.residencia_estado_selecionado == null && this.residencia_cidade == null || this.residencia_cep == null) {
                	this.botao = '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento';
					this.pedindo = false;
					this.$toast.error('Você precisa as informações de sua residência', '', {
						position: 'center',
						overlay: true,
						timeout: 10000,
					});
				} else {
					this.enviando = true;
					axios.post(this.currentURL, {
						doc_id: this.documentacao.id,
						outro_acervo_endereco: this.residencia_endereco,
						outro_acervo_numero: this.residencia_numero,
						outro_acervo_complemento: this.residencia_complemento,
						outro_acervo_bairro: this.residencia_bairro,
						outro_acervo_cidade: this.residencia_cidade,
						outro_acervo_estado: this.residencia_estado_selecionado,
						outro_acervo_cep: this.residencia_cep,
						apostilamento_opcao: 2,
						atividades: this.atividades,
					})
					.then(res => {
						this.$toast.info("Pedido efetuado, direcionando para página de pagamento.");
						setTimeout(function() {
							window.location.href = '/meu-pedido/'+res.data+'/pagar';
						}, 4000);
					})
					.catch(err => {
						this.enviando = false;
	                	this.botao = '<i class="fa fa-check mr-2"></i>Tente novamente';
						this.pedindo = false;
						this.$toast.error(err.response.data.error);
					});
				}
			}
		},
		watch: {
			residencia_estado: function (val) {
	            this.residencia_estado_selecionado = val.nome;
	            this.residencia_cidade_options = val.cidades;
	        },
		}
	}
</script>

<style>

</style>
