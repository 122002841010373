<template>
</template>

<script>
	export default {
		name: 'Avisos',
		props: {
			logged_in: Boolean,
			user: Object,
		},
		data: function () {
	    	return {
		      	posicao: 'bottomCenter',
		      	URL: '/api/v1/aviso',
		    }
	  	},
		mounted() {
			if(!this.isMobile) {
		      	this.posicao = 'bottomLeft';
		    }
		    if(this.logged_in == true) {
		    	this.URL = '/api/v1/aviso/logado';
		    }
		    var mk = this;
		    // Avisos
	      	if(this.currentURL == "/") {
		        axios.post(this.URL, {route: 'inicio'})
		        .then(res => {
	            	this.$toast.show(res.data.mensagem, '', { 
	              		id: 'aviso',
	              		theme: 'light',
	              		layout: 1,
	              		zindex: 2147483999,
	              		closeOnClick: true,
	              		close: false,
	              		backgroundColor: '#518ef8',
	              		messageColor: '#fff',
	              		displayMode: 'replace',
	              		balloon: true,
	              		image: '/img/logo.svg',
	              		position: mk.posicao, // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
	              		timeout: res.data.tempo,
	              		progressBar: true,
	            	});
	        	});
	      	} else if(this.currentURL.split('/')[1] == "documentacoes") {
	      		if (document.getElementById("mostrar-aviso")){
			        axios.post(this.URL, {route: 'documentacoes'})
			        .then(res => {
		            	this.$toast.show(res.data.mensagem, '', { 
		              		id: 'aviso',
		              		theme: 'light',
		              		backgroundColor: '#518ef8',
		              		messageColor: '#fff',
		              		layout: 1,
		              		zindex: 2147483999,
		              		closeOnClick: true,
		              		drag: false,
		              		close: true,
		              		displayMode: 'replace',
		              		balloon: false,
		              		image: '/img/logo.svg',
		              		target: '#mostrar-aviso',
		              		targetFirst: true,
		              		position: mk.posicao, // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
		              		timeout: res.data.tempo,
		              		progressBar: false,
		            	});
			        });
			    }
	      	} else if(this.currentURL == "/market") {
		        axios.post(this.URL, {route: 'market-inicio'})
		        .then(res => {
	            	this.$toast.show(res.data.mensagem, '', { 
						id: 'aviso',
						theme: 'light',
						backgroundColor: '#518ef8',
						messageColor: '#fff',
						layout: 1,
						zindex: 2147483999,
						closeOnClick: true,
						close: true,
						drag: false,
						displayMode: 'replace',
						balloon: false,
						image: '/img/logo.svg',
						target: '#mostrar-aviso',
          				targetFirst: true,
						position: mk.posicao, // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
						timeout: res.data.tempo,
						progressBar: false,
	            	});
		        });
	      	} else if(this.currentURL.split('/')[1] == "market" && this.currentURL.split('/')[2] == 'categoria') {
		        axios.post(this.URL, {route: 'market-categoria', categoria: this.currentURL.split('/')[3]})
		        .then(res => {
	            	this.$toast.show(res.data.mensagem, '', { 
						id: 'aviso',
						theme: 'light',
						backgroundColor: '#518ef8',
						messageColor: '#fff',
						layout: 1,
						zindex: 2147483999,
						closeOnClick: true,
						close: true,
						displayMode: 'replace',
						drag: false,
						balloon: false,
						image: '/img/logo.svg',
						target: '#mostrar-aviso',
          				targetFirst: true,
						position: mk.posicao, // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
						timeout: res.data.tempo,
						progressBar: false,
	            	});
		        });
		    }
		},
		computed: {
		    isMobile() {
		      	if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		        	return true;
		      	} else {
		        	return false;
		      	}
		    },
            currentURL() { return window.location.pathname; }
	  	},
	}
</script>