<template>
	<form class="col-xl-12" method="post" @submit.prevent="fazerPedido()" v-if="documentacao != null">
		<p class="lead">
			Confirme abaixo:
		</p>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_informacoes" value="true" class="custom-control-input" id="leu-informacoes">
			    <label class="custom-control-label h6" for="leu-informacoes">Eu {{ nome }}, afirmo que os dados que estão em minha <a href="/minhas-informacoes">ficha de informações</a> estão corretos.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_endereco" value="true" class="custom-control-input" id="leu-endereco">
			    <label class="custom-control-label h6" for="leu-endereco">Eu {{ nome }}, afirmo que o endereço cadastrado em minha <a href="/minhas-informacoes">ficha de informações</a> é o mesmo endereço que consta em meu CR.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_termos" value="true" class="custom-control-input" id="leu-termos">
			    <label class="custom-control-label h6" for="leu-termos">Eu {{ nome }}, li os <a href="/termos" target="_blank">termos de uso</a> e <a href="/privacidade" target="_blank">privacidade</a> e concordo com os mesmos para a utilização dos serviços oferecidos pelo cacdocs.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_checklist" value="true" class="custom-control-input" id="leu-checklist">
			    <label class="custom-control-label h6" for="leu-checklist">Eu {{ nome }}, li as informações da documentação <a :href="'/documentacoes/'+ documentacao.categoria.slug +'/'+ documentacao.slug" target="_blank">{{ documentacao.nome }}</a> e sua <a :href="'/documentacoes/checklist/'+ documentacao.slug" target="_blank">lista de verificação</a> e sei quais documentos serão gerados pelo cacdocs e quais são de minha responsabilidade.</label>
			</div>
		</div>
        <div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_crafs" value="true" class="custom-control-input" id="leu-crafs">
			    <label class="custom-control-label h6" for="leu-crafs">Eu {{ nome }}, entendo que é condição para a revalidação do registro, que de acordo com o <a href="http://www.dfpc.eb.mil.br/images/port_150_.pdf" target="_blank">Art. 27. da Portaria 150</a>, que  <span class="text-primary">os CRAF das armas do acervo do requerente estejam válidos</span>.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_custos" value="true" class="custom-control-input" id="leu-custos">
			    <label class="custom-control-label h6" for="leu-custos">Eu {{ nome }}, entendo que não é responsabilidade do cacdocs o <span class="text-primary">pagamento de taxas</span> e <span class="text-primary">custos</span> sendo esses de minha responsabilidade. Exemplo: Guia de Recolhimento da União (GRU).</label>
			</div>
		</div>
		<!-- <div v-if="enviando == false">
			<p class="lead mt-7 mb-0">
				Preencha abaixo e adicione a listagem as atividades presentes em seu CR.
			</p>
			<p>Digite como está escrito em seu Certificado de Registro:</p>
			<div class="row justify-content-between align-items-center pb-5 no-gutters">
				<div class="col-7 col-lg-9">
					<input placeholder="Ex: Tiro desportivo" type="text" v-model="atividade_atual" class="form-control">
				</div>
				<div class="col-5 col-md-3 text-right">
					<button type="button" @click="adicionarALista()" class="btn btn-primary"><i class="fa fa-plus mr-3 d-none d-lg-inline"></i>Adicionar</button>
				</div>
			</div>
			<div class="form-row border rounded justify-content-between align-items-center" v-for="(atividade, index) in atividades" v-if="atividades.length > 0">
				<div class="col-8 align-self-center">
					<h3 class="mb-0"><strong class="small">#{{index+1}}</strong> {{ atividade }}</h3>
				</div>
				<div class="col-4 text-right py-2">
					<button type="button" @click="removerDaLista(index)" class="btn btn-sm btn-danger"><i class="fa fa-trash"></i><span class="d-none d-lg-inline ml-3">Remover</span></button>
				</div>
			</div>
			<div class="form-group pt-6">
				<div class="custom-control custom-checkbox text-small">
				    <input required type="checkbox" name="confirma_atividades" value="true" class="custom-control-input" id="confirma-atividades">
				    <label class="custom-control-label h6" for="confirma-atividades">Eu {{ nome }}, confirmo que as atividades adicionadas acima estão corretas e foram preenchidas conforme estão descritas no meu Certificado de Registro.</label>
				</div>
			</div>
		</div> -->
		<div v-if="enviando == true" class="text-center py-11">
			<i class="fa fa-spin fa-spinner fa-2x"></i>
		</div>
        <div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="revalidacao" value="true" class="custom-control-input" id="leu-revalidacao">
			    <label class="custom-control-label h6" for="leu-revalidacao">Eu {{ nome }}, afirmo que desejo contratar a documentação {{ documentacao.nome }}.</label>
			</div>
		</div>
		<div class="form-group">
			<button :disabled="pedindo" class="btn btn-primary" v-html="botao"></button>
		</div>
	</form>
</template>

<script>
	export default {
		name: 'Cr004',
		data() {
			return {
				enviando: false,
				nome: 'Usuário',

				atividade_atual: null,
				atividades: [],

				documentacao: null,
                currentURL: window.location.pathname,
                botao: '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento',
                pedindo: false,
			}
		},
		mounted() {
			this.getAcervo();
			this.getNome();
			this.getDocumentacao();
		},
		methods: {
			getAcervo: function () {
				var app = this;
				axios.get('/acervo')
				.then((res) => {
					app.acervos = res.data;
				});
			},
			getNome: function () {
				var app = this;
				axios.get('/info/name')
				.then((res) => {
					app.nome = res.data;
				});
			},
			getDocumentacao: function () {
				var app = this;
				axios.get('/documentacao/'+this.currentURL.replace('/contratar/', ''))
				.then((res) => {
					app.documentacao = res.data;
				});
			},
			adicionarALista: function () {
				if(this.atividade_atual != null)
					this.atividades.push(this.atividade_atual);
				this.atividade_atual = null;
			},
			removerDaLista: function (index) {
				this.$delete(this.atividades, index);
			},
			fazerPedido: function () {
            	this.botao = '<i class="fa fa-spin fa-spinner mr-2"></i>Concluindo pedido e direcionando para pagamento';
				this.pedindo = true;
				// if(this.atividades.length == 0) {
                // 	this.botao = '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento';
				// 	this.pedindo = false;
				// 	this.$toast.error('Você precisa adicionar as atividades atuais de seu CR para continuar', '', {
				// 		position: 'center',
				// 		overlay: true,
				// 		timeout: 10000,
				// 	});
				// } else {
					this.enviando = true;
					axios.post(this.currentURL, {
						doc_id: this.documentacao.id,
						atividades: this.atividades,
					})
					.then(res => {
						this.$toast.info("Pedido efetuado, direcionando para página de pagamento.");
						setTimeout(function() {
							window.location.href = '/meu-pedido/'+res.data+'/pagar';
						}, 4000);
					})
					.catch(err => {
						this.enviando = false;
	                	this.botao = '<i class="fa fa-check mr-2"></i>Tente novamente';
						this.pedindo = false;
						this.$toast.error(err.response.data.error);
					});
				// }
			}
		},
	}
</script>

<style>

</style>
