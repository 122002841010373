<template>
    <div>
        <div v-if="enviado == true">
            <div class="text-center">
                <i class="fa fa-check fa-4x text-success"></i>
                <h2 class="text-success">Enviamos sua denúncia!</h2>
                <p class="lead">Lhe responderemos o mais rápido possível.</p>
            </div>
        </div>
        <div v-if="enviado == false">
            <div class="text-center">
                <i class="fa fa-times fa-4x text-danger"></i>
                <h2 class="text-danger">Não conseguimos enviar sua denúncia!</h2>
                <p class="lead">Carregaremos novamente o formulário, por favor tente novamente.</p>
            </div>
        </div>
        <form @submit.prevent="captcha()" method="post" v-if="enviado == null">
            <div class="form-row">
                <div class="col-sm">
                    <div class="form-group">
                        <label for="denuncia-name">Seu nome completo</label>
                        <input placeholder="Preencha com seu nome" type="text" v-model="nome" class="form-control" id="denuncia-name" required>
                        <div class="invalid-feedback">
                            Por favor, preencha com seu nome.
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group">
                        <label for="denuncia-email">Endereço de e-mail</label>
                        <input placeholder="Preencha com seu e-mail" type="email" v-model="email" class="form-control" id="denuncia-email"
                            required>
                        <div class="invalid-feedback">
                            Por favor, preencha com seu endereço de e-mail.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="denuncia-endereco" class="w-100">Endereço do anúncio</label>
                        <input required placeholder="https://cacdocs.com.br/market/produto/xxxxxxxxxx" type="text" v-model="url_denunciada" class="form-control" id="denuncia-endereco">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="denuncia-motivo">Motivo</label>
                <textarea placeholder="Descreva os motivos para a denúncia" id="denuncia-motivo" v-model="mensagem" rows="10" class="form-control"
                    required></textarea>
                <div class="invalid-feedback">
                    Por favor, preencha com sua motivo.
                </div>
            </div>
            <div class="form-group" v-if="confirmar">
                <vue-recaptcha @verify="enviar" sitekey="6Let4SIUAAAAAOV74azmciDRh7sN_kdd5QtalSK2"></vue-recaptcha>
            </div>
            <div class="form-group">
                <button class="btn btn-primary btn-loading" type="submit" v-html="botao" :disabled="confirmar"></button>
            </div>
        </form>
    </div>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';
    export default {
        name: 'FormularioContato',
        data() {
            return {
                enviado: null,
                confirmar: false,
                url_denunciada: null,
                nome: null,
                email: null,
                mensagem: null,
                botao: '<i class="fa fa-check mr-2"></i>Enviar denúncia',
                search: window.location.search,
            }
        },
        mounted() {
            if (this.search) {
                this.url_denunciada = unescape(window.location.search.split('=')[1]);
            }
        },
        methods: {
            captcha: function () {
                this.confirmar = true;
                this.botao = '<i class="fa fa-check mr-2"></i>Confirme acima';
            },
            enviar: function () {
                this.botao = '<i class="fa fa-spin fa-spinner mr-2"></i>Enviando denúncia';
                if (this.assunto_selecionado != 'Outro')
                    var assunto = this.assunto_selecionado;
                else 
                    var assunto = this.assunto;
                axios.post('/market/denunciar', {
                    nome: this.nome,
                    email: this.email,
                    url_denunciada: this.url_denunciada,
                    mensagem: this.mensagem,
                })
                .then(res => {
                    this.enviado = true;
                })
                .catch(err => {
                    this.enviado = false;
                    var app = this;
                    setTimeout(function() {
                        app.enviado = null;
                        app.confirmar = false;
                        app.botao = '<i class="fa fa-check mr-2"></i>Enviar mensagem';
                    }, 5000);
                });
            }
        },
        components: { VueRecaptcha }
    }
</script>
