<template>
	<div class="col-12 text-left">
		<form v-on:submit.prevent="adicionarProduto()" method="post">
            <div class="row text-center justify-content-center">
                <div class="col-md-12 col-lg-10 pt-7">
					<div class="alert alert-primary py-6 my-7"><i class="fa fa-info-circle mr-3"></i>Lembre-se, todos os produtos cadastrados em nossos classificados devem seguir nossos <a href="/termos">Termos de uso</a>.</div>
					<div class="text-left">
						<div class="form-row pb-4">
							<div class="col-12 col-lg-4">
								<label for="loja">Você anunciará como:</label>
								<div class="d-flex pt-1">
									<div class="custom-control custom-radio mr-4">
					                    <input v-model="loja" name="loja" :value="null" type="radio" class="custom-control-input" id="loja-1" required>
					                    <label class="custom-control-label" for="loja-1">CAC</label>
					                </div>
									<div class="custom-control custom-radio mr-4">
					                    <input name="loja" v-model="loja" :value="true" type="radio" class="custom-control-input" id="loja-2" required>
					                    <label class="custom-control-label" for="loja-2">Comércio</label>
		                  			</div>
								</div>
							</div>
							<div class="col-12 col-lg-8" v-if="checkLoja.length > 0 && loja == true">
								<div class="form-group">
									<label for="lojas_usadas">Comércio usado por você</label>
									<select name="lojas_usadas" v-model="loja_selecionada" id="lojas_usadas" class="form-control">
										<option :value="null">Não utilizar dados antigos</option>
										<option :value="loja_usada" v-for="loja_usada in checkLoja">{{ loja_usada.loja_nome }} ({{ loja_usada.loja_email }})</option>
									</select>
								</div>
							</div>
						</div>
						<transition name="fadeHeight">
						<div id="loja_info" class="form-row pb-4" v-if="loja == true">
							<div class="col-12">
								<p class="mb-3 alert alert-primary text-center">Você pode utilizar a imagem de exibição da sua conta para colocar a imagem de sua comércio, nas publicações que fizer como CAC não exibiremos sua imagem de exibição e sim suas iniciais.</p>
							</div>
							<div class="col-12 col-lg-4">
								<div class="form-group">
									<label for="loja_nome">Nome do comércio</label>
									<input v-model="loja_nome" type="text" class="form-control" id="loja_nome" placeholder="Preencha com o nome do comércio." required>
								</div>
							</div>
							<div class="col-12 col-lg-4">
								<div class="form-group">
									<label for="loja_telefone">Telefone do comércio</label>
									<input v-model="loja_telefone" type="tel" class="form-control celular" id="loja_telefone" placeholder="Telefone com DDD do comércio." required>
								</div>
							</div>
							<div class="col-12 col-lg-4">
								<div class="form-group">
									<label for="loja_email">E-mail do comércio</label>
									<input v-model="loja_email" type="email" class="form-control" id="loja_email" placeholder="Preencha com endereço de e-mail." required>
								</div>
							</div>
							<div class="col-12">
								<div class="form-group">
									<label for="loja_descricao" class="mb-0">Texto descritivo do comércio</label>
									<span class="small d-block mb-1">Não coloque dados de contato no campo abaixo.</span>
									<textarea rows="4" v-model="loja_descricao" required class="form-control" id="loja_descricao"></textarea>
								</div>
							</div>
							<div class="col-12">
								<hr>
							</div>
						</div>
						</transition>
						<div class="form-group">
							<label for="titulo">Título do anúncio</label>
							<input v-model="titulo" type="text" class="form-control" id="titulo" placeholder="Preencha com uma breve informação de seu produto. Exemplo: Nome/Marca/Calibre." required>
						</div>
						<div class="form-group">
							<label for="descricao">Descrição do produto <small>(opcional)</small></label>
							<textarea v-model="descricao" id="descricao" class="form-control" cols="30" rows="7" placeholder="Preencha com uma descrição de seu produto."></textarea>
						</div>
						<div class="row">
							<div class="col-12 col-lg-4">
								<div class="form-group">
									<label for="valor">Valor</label>
									<input type="tel" style="font-size: 30px;" v-model="valor" class="form-control dinheiro" id="valor" required>
								</div>
							</div>
							<div class="col-12 col-lg-4">
								<div class="form-group">
									<label for="valor">Aceitará propostas?</label>
									<div class="d-flex pt-5">
										<div class="custom-control custom-radio mr-4">
						                    <input name="aceita_propostas" v-model="aceita_propostas" :value="true" type="radio" class="custom-control-input" id="check-1" required>
						                    <label class="custom-control-label" for="check-1">Sim</label>
						                </div>
										<div class="custom-control custom-radio mr-4">
						                    <input name="aceita_propostas" v-model="aceita_propostas" :value="false" type="radio" class="custom-control-input" id="check-2" required>
						                    <label class="custom-control-label" for="check-2">Não</label>
			                  			</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-lg-4">
								<div class="form-group">
									<label for="categoria">Categoria</label>
									<select v-model="categoria_id" id="categoria" class="form-control form-control-lg" required>
										<option :value="null" selected hidden disabled>Escolha uma opção</option>
										<option v-for="categoria in categorias" :value="categoria.id">{{ categoria.nome }}</option>
									</select>
								</div>
							</div>
							<div class="col-12" v-if="categoria_id == null">
								<div class="alert alert-info text-center">Escolha uma categoria para continuar.</div>
							</div>
						</div>
						<div id="armas" class="row justify-content-center" v-if="categoria_id != null">
							<div class="col-12 col-lg-4">
								<div class="form-group">
									<label for="marca">Marca</label>
									<input v-model="marca" type="text" class="form-control" id="marca">
								</div>
							</div>
							<div class="col-12 col-lg-4">
								<div class="form-group">
									<label for="modelo">Modelo</label>
									<input v-model="modelo" type="text" class="form-control" id="modelo">
								</div>
							</div>
							<div class="col-12 col-lg-4" v-if="categoria_id < 6">
								<div class="form-group">
									<label for="funcionamento">Funcionamento</label>
									<input v-model="funcionamento" type="text" class="form-control" id="funcionamento">
								</div>
							</div>
							<div class="col-12 col-lg-4" v-if="categoria_id < 6">
								<div class="form-group">
									<label for="calibre">Calibre</label>
									<input v-model="calibre" type="text" class="form-control" id="calibre">
								</div>
							</div>
							<div class="col-12 col-lg-4">
								<div class="form-group">
									<label for="acabamento">Acabamento</label>
									<input v-model="acabamento" type="text" class="form-control" id="acabamento">
								</div>
							</div>
							<div class="col-12 col-lg-4" v-if="categoria_id < 6">
								<div class="form-group">
									<label for="canos">Canos</label>
									<input v-model="canos" type="number" class="form-control" id="canos">
								</div>
							</div>
							<div class="col-8 col-lg-4" v-if="categoria_id < 6">
								<div class="form-group">
									<label for="comprimento_cano">Comprimento do cano (milímetros)</label>
									<input v-model="comprimento_cano" type="number" step="0.01" class="form-control" id="comprimento_cano">
								</div>
							</div>
							<div class="col-4 col-lg-1" v-if="categoria_id < 6">
								<label class="d-block d-lg-none">Calculadora conversora</label>
								<a href="#calculadora" role="button" data-toggle="modal" class="btn btn-primary mt-0 mt-lg-6"><i class="fa fa-calculator"></i></a>
							</div>
							<div class="col-12 col-lg-4" v-if="categoria_id < 6">
								<div class="form-group">
									<label for="capacidade_carregamento">Capacidade de carregamento</label>
									<input v-model="capacidade_carregamento" type="text" class="form-control" id="capacidade_carregamento">
								</div>
							</div>
							<div class="col-12 col-lg-3">
								<div class="form-group">
									<label for="pais">País de fabricação</label>
									<input v-model="pais" type="text" class="form-control" id="pais">
								</div>
							</div>
							<div class="col-12 col-lg-4" v-if="categoria_id < 6">
								<div class="form-group">
									<label for="restricao">Restrição da arma</label>
									<div class="d-flex pt-3">
										<div class="custom-control custom-radio mr-4">
						                    <input v-model="restricao" name="restricao" value="Permitido" type="radio" class="custom-control-input" id="check-permitido">
						                    <label class="custom-control-label" for="check-permitido">Permitido</label>
						                </div>
										<div class="custom-control custom-radio mr-4">
						                    <input v-model="restricao" name="restricao" value="Restrito" type="radio" class="custom-control-input" id="check-restrito">
						                    <label class="custom-control-label" for="check-restrito">Restrito</label>
			                  			</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-lg-4" v-if="categoria_id < 6 && loja == null">
								<div class="form-group">
									<label for="acervo">Acervo da arma</label>
									<select v-model="acervo" class="form-control" id="acervo">
										<option value="null" selected hidden disabled>Escolha uma opção</option>
										<option value="Caçador">Caçador</option>
										<option value="Atirador desportivo">Atirador desportivo</option>
										<option value="Colecionador">Colecionador</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12">
        			<button type="submit" :disabled="enviando" v-bind:class="[enviado ? 'btn-success' : 'btn-primary']" id="botao_adicionar" v-if="categoria_id != null" class="btn mt-5" v-html="botao"></button>
        		</div>
            </div>
		</form>
		<div class="modal" id="calculadora" tabindex="-1" role="dialog" aria-hidden="true">
		    <div class="modal-dialog modal-lg" role="document">
		        <div class="modal-content bg-primary-3 rounded">
		            <div class="modal-header my-auto border-0">
		                <h5 class="mb-0 text-light">
							Calculadora conversora
						</h5>
		                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		                    <i class="fa fa-times text-light"></i>
		                </button>
		            </div>
		            <div class="modal-body p-4 text-center py-7 text-light" id="result">
		            	<h1 class="mb-2 mb-lg-0 text-light">
							Olá {{ userName }},
						</h1>
						<div class="lead mb-7">Preencha as o campo abaixo caso queira converter Polegadas para Milímetros.</div>
						<input type="number" class="form-control" v-model="polegadas" placeholder="Preencha com um valor em polegadas.">
		            </div>
		            <div class="modal-footer border-0">
		                <button type="button" @click="converterPolegada()" id="pesquisar" class="btn btn-primary"><i class="fa fa-exchange-alt mr-3"></i>Converter</button>
		            </div>
		        </div>
		    </div>
		</div>
    </div>
</template>

<script>
	export default {
		name: 'FormularioProduto',
		props: {
			userName: String,
			categorias: Array,
			checkLoja: Array,
		},
		data() {
			return {
				loja: null,
				loja_nome: null,
				loja_telefone: null,
				loja_email: null,
				loja_descricao: 'Exemplo:\nCNPJ (se tiver)\nEndereço completo\nDias e horários de funcionamento.',

				titulo: null,
				descricao: null,
				valor: null,
				aceita_propostas: false,
				categoria_id: null,

				marca: null,
				modelo: null,
				funcionamento: null,
				calibre: null,
				acabamento: null,
				canos: null,
				comprimento_cano: null,
				capacidade_carregamento: null,
				pais: null,
				restricao: null,
				acervo: null,
				especie: null,

				polegadas: null,

				loja_selecionada: null,
				botao: '<i class="fa fa-save mr-3"></i>Adicionar produto',
				enviado: false,
				enviando: false,
			}
		},
		mounted() {
            $(document).on('shown.bs.modal', '.modal', function () {
                $('.modal-backdrop').before($(this));
            });
        },
		methods: {
			converterPolegada: function() {
				if (this.polegadas != null) {
					this.comprimento_cano = (this.polegadas * 25.4).toFixed(2).toString();
				}
			},
			adicionarProduto: function() {
				this.botao = '<i class="fa fa-spin fa-spinner mr-3"></i>Adicionando produto';
				this.enviando = true;
				axios.post('/novo-produto', {
					loja: this.loja,
					loja_nome: this.loja_nome,
					loja_telefone: this.loja_telefone,
					loja_email: this.loja_email,
					loja_descricao: this.loja_descricao,

					titulo: this.titulo,
					descricao: this.descricao,
					valor: this.valor,
					aceita_propostas: this.aceita_propostas,
					categoria_id: this.categoria_id,

					marca: this.marca,
					modelo: this.modelo,
					funcionamento: this.funcionamento,
					calibre: this.calibre,
					acabamento: this.acabamento,
					canos: this.canos,
					comprimento_cano: this.comprimento_cano,
					capacidade_carregamento: this.capacidade_carregamento,
					pais: this.pais,
					restricao: this.restricao,
					acervo: this.acervo,
				})
				.then((res) => {
					this.enviando = true;
					this.enviado = true;
					this.botao = '<i class="fa fa-check mr-3"></i>Produto adicionado';
					this.$toast.success('Produto adicionado com sucesso');
					setTimeout(function() {
						window.location.href="https://cacdocs.com.br/meu-produto/"+res.data.slug;
					}, 400);
				})
				.catch(err => {
					this.enviando = false;
					this.enviado = false;
					this.botao = '<i class="fa fa-save mr-3"></i>Tentar novamente';
					this.$toast.error(err.response.data.error);
				});
			},
		},
		watch: {
			loja_selecionada(val) {
				if(val != null) {
					this.loja_nome = val.loja_nome;
					this.loja_telefone = val.loja_telefone;
					this.loja_email = val.loja_email;
					this.loja_descricao = val.loja_descricao;
				} else {
					this.loja_nome = null;
					this.loja_telefone = null;
					this.loja_email = null;
					this.loja_descricao = null;
				}
			},
			loja(val) {
				if (val == null) {
					this.loja_selecionada = null;
				}
			}
		}
	}
</script>

<style scoped>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to
{
  opacity: 0;
  max-height: 0px;
}
</style>
