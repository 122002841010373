<template>
	<div class="row">
		<div class="col-12 mt-7" v-if="clubes.length > 0">
			<h3 class="mb-0">Clubes do seu estado</h3>
			<p>Veja abaixo clubes próximos a você que poderá marcar uma visita.</p>
		</div>
		<div v-if="clubes.length > 0" class="d-flex justify-content-start align-items-center py-6 scroll-custom scroll-custom-blue" style="overflow-x: auto;">
			<div class="col-6 col-lg-2 my-2 my-md-3 my-lg-0 text-center" v-for="(clube, index) in clubes">
		        <div class="card my-auto px-3 categoria">
		            <a target="_blank" class="card-body p-0" :href="'/clube/'+clube.abreviacao">
	                    <div class="text-center" v-if="clube.plano_id != null" style="position:absolute;min-width:100px;right:-8px;top:-16px;z-index:44;">
	                        <h6 class="text-uppercase bg-primary px-4 py-2 rounded text-white mb-0 text-center small">parceiro</h6>
	                    </div>
		                <img v-if="clube.logo" class="img-fluid lazy hover-fade-out p-4 categoria-imagem" :src="clube.logo">
		                <img v-else class="img-fluid lazy hover-fade-out p-4 categoria-imagem" :src="'/img/logo.svg'">
		                <h6 class="mb-1">{{ clube.nome }}</h6>
	                    <p class="small text-muted">{{ clube.cidade }}</p>
		            </a>
		        </div>
		    </div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ExibeClubes',
		props: {
			estado: String,
		},
		data() {
			return {
				clubes: [],
			}
		},
		mounted() {
			this.getClubes();
		},
		methods: {
			getClubes: function() {
				axios.get('/clubes/'+this.estado)
				.then((res) => {
					this.clubes = res.data;
				})
				.catch((err) => {
					this.$toast.error(err.response.data.error);
				});
			}
		},
	}
</script>