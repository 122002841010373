<template>
    <span>
        <div class="d-none d-md-block bg-primary text-white pl-6 py-0" style="bottom: 0px;left:0px;position:fixed;z-index:99999;border-top-right-radius: 5px;" v-if="armasSelecionadas != null && armasSelecionadas.length > 0">
            <span v-if="armasSelecionadas.length == 1">Arma selecionada:</span><span v-else>{{ armasSelecionadas.length }} armas selecionadas:</span> <span v-if="armasSelecionadas.length == 1"><span style="text-decoration:underline;font-weight:800;font-size:20px;">{{ armasSelecionadas[0].marca}}, {{ armasSelecionadas[0].modelo }}({{ armasSelecionadas[0].sigma }})</span></span> <span v-else v-for="(armas, index) in armasSelecionadas" :key="index"><span style="text-decoration:underline;font-weight:800;font-size:20px;"><span v-if="index != 0">&nbsp;</span>{{ armasSelecionadas[0].marca}}<span v-if="(index+1) != armasSelecionadas.length">,</span></span></span> <button class="btn-primary btn ml-5" @click="armas_selecionadas = []"><i class="fa fa-times"></i></button>
        </div>
        <form class="col-xl-12" method="post" @submit.prevent="fazerPedido()" v-if="documentacao != null">
            <p class="lead">
                Confirme abaixo:
            </p>
            <div class="form-group">
                <div class="custom-control custom-checkbox text-small">
                    <input required type="checkbox" name="confirma_informacoes" value="true" class="custom-control-input" id="leu-informacoes">
                    <label class="custom-control-label h6" for="leu-informacoes">Eu {{ nome }}, afirmo que os dados que estão em minha <a href="/minhas-informacoes">ficha de informações</a> estão corretos.</label>
                </div>
            </div>
            <div class="form-group">
                <div class="custom-control custom-checkbox text-small">
                    <input required type="checkbox" name="confirma_acervo" value="true" class="custom-control-input" id="leu-acervo">
                    <label class="custom-control-label h6" for="leu-acervo">Eu {{ nome }}, afirmo que os dados que estão em meu <a href="/meu-acervo">acervo de armas</a> estão corretos e atualizados.</label>
                </div>
            </div>
            <div class="form-group">
                <div class="custom-control custom-checkbox text-small">
                    <input required type="checkbox" name="confirma_termos" value="true" class="custom-control-input" id="leu-termos">
                    <label class="custom-control-label h6" for="leu-termos">Eu {{ nome }}, li os <a href="/termos" target="_blank">termos de uso</a> e <a href="/privacidade" target="_blank">privacidade</a> e concordo com os mesmos para a utilização dos serviços oferecidos pelo cacdocs.</label>
                </div>
            </div>
            <div class="form-group">
                <div class="custom-control custom-checkbox text-small">
                    <input required type="checkbox" name="confirma_checklist" value="true" class="custom-control-input" id="leu-checklist">
                    <label class="custom-control-label h6" for="leu-checklist">Eu {{ nome }}, li as informações da documentação <a :href="'/documentacoes/'+ documentacao.categoria.slug +'/'+ documentacao.slug" target="_blank">{{ documentacao.nome }}</a> e sua <a :href="'/documentacoes/checklist/'+ documentacao.slug" target="_blank">lista de verificação</a> e sei quais documentos serão gerados pelo cacdocs e quais são de minha responsabilidade.</label>
                </div>
            </div>
            <div class="form-group">
                <div class="custom-control custom-checkbox text-small">
                    <input required type="checkbox" name="confirma_custos" value="true" class="custom-control-input" id="leu-custos">
                    <label class="custom-control-label h6" for="leu-custos">Eu {{ nome }}, entendo que não é responsabilidade do cacdocs o <span class="text-primary">pagamento de taxas</span> e <span class="text-primary">custos</span> sendo esses de minha responsabilidade. Exemplo: Guia de Recolhimento da União (GRU).</label>
                </div>
            </div>
            <div v-if="enviando == false">
                <p class="lead mt-7 mb-0">
                    Abaixo estão as armas que estão listadas em seu <a href="/meu-acervo" target="_blank">acervo de armas</a>.
                </p>
                <p>Escolha quais armas deseja incluir no pedido de guia de tráfego:</p>
            </div>
            <div v-if="enviando == true" class="text-center py-11">
                <i class="fa fa-spin fa-spinner fa-2x"></i>
            </div>
            <div v-if="enviando == false">
                <div v-if="acervos != null && acervos.length > 0">
                    <div class="row mt-6">
                        <div class="col-12 col-lg-4 w-100" v-for="(arma, index) in acervos" :key="arma.id">
                            <div class="">
                                <input :disabled="arma.sigma == null" type="checkbox" v-model="armas_selecionadas[index]" style="visibility: hidden;" :id="'arma-'+arma.id" :true-value="arma.id" :false-value="null">
                                <label class="w-100" :for="'arma-'+arma.id">
                                    <div class="card position-relative w-100" v-bind:class="{ 'border border-primary': armas_selecionadas[index], 'border border-danger': arma.sigma == null }" style="cursor: pointer;">
                                        <div v-if="armas_selecionadas[index]" class="text-center" style="position:absolute;min-width:100px;right:-8px;top:-16px;z-index:44;">
                                            <h6 class="text-uppercase bg-primary px-4 py-2 rounded text-white mb-0 text-center small">selecionada</h6>
                                        </div>
                                        <div v-if="arma.sigma == null" class="text-center" style="position:absolute;min-width:100px;right:-8px;top:-16px;z-index:44;">
                                            <h6 class="text-uppercase bg-danger px-4 py-2 rounded text-white mb-0 text-center small">desativada</h6>
                                        </div>
                                        <div class="card-header text-center w-100">
                                            <img style="max-width: 120px" src="/img/market-categoria/espingarda.png" class="img-fluid align-middle" v-if="arma.especie.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes('espingarda')">
                                            <img style="max-width: 120px" src="/img/market-categoria/pistola.png" class="img-fluid align-middle" v-else-if="arma.especie.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes('pistola')">
                                            <img style="max-width: 120px" src="/img/market-categoria/revolver.png" class="img-fluid align-middle" v-else-if="arma.especie.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes('revolver')">
                                            <img style="max-width: 120px" src="/img/market-categoria/rifle.png" class="img-fluid align-middle" v-else-if="arma.especie.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes('rifle')">
                                            <img style="max-width: 120px" src="/img/market-categoria/incognita.png" class="img-fluid align-middle" v-else>
                                        </div>
                                        <div class="card-body text-center py-5" v-if="arma.sigma == null">
                                            <h4>Olá <span class="text-capitalize">{{ nome.toLowerCase().split(' ')[0] }}</span>, sua arma está sem o número do SIGMA.</h4>
                                            <p class="mb-3">
                                                Clique no botão abaixo para atualizar os dados cadastrais de sua arma.
                                            </p>
                                            <a :href="'/meu-acervo/'+arma.id+'/editar'" class="btn btn-primary">Atualizar arma</a>
                                        </div>
                                        <div class="card-body" v-if="arma.sigma != null">
                                            <p class="mb-0"><strong>Sigma:</strong> {{ arma.sigma }}</p>
                                            <p class="mb-0"><strong>Nº de série:</strong> {{ arma.numero_serie }}</p>
                                            <p class="mb-0"><strong>Marca:</strong> {{ arma.marca }}</p>
                                            <p class="mb-0"><strong>Modelo:</strong> {{ arma.modelo }}</p>
                                            <p class="mb-0"><strong>Calibre:</strong> {{ arma.calibre }}</p>
                                            <p class="mt-2" v-if="armas_selecionadas[index]">
                                                <label :for="'municao-'+index">Quantidade de munição</label>
                                                <input type="number" v-model="municao_armas_selecionadas[index]" min="1" style="font-size:20pt" :id="'municao-'+index" class="form-control">
                                            </p>
                                            <p class="my-2" v-if="armas_selecionadas[index]">
                                                <label :for="'tipo_municao-'+index">Qual tipo de munição?</label>
                                                <select class="form-control" v-model="tipo_municao_armas_selecionadas[index]" :id="'tipo_municao-'+index">
                                                    <option value="CBC/Cartucho">CBC/Cartucho</option>
                                                    <option value="Recarregável/Cartucho">Recarregável/Cartucho</option>
                                                    <option value="Outros/Cartucho">Outros/Cartucho</option>
                                                </select>
                                            </p>
                                            <p class="my-2" v-if="armas_selecionadas[index] && municao_armas_selecionadas[index] > 750">
                                                <label :for="'motivo_municao-'+index">Quantidades maiores de <strong>750</strong> munições precisam de justificativa:</label>
                                                <textarea :id="'motivo_municao'+index" v-model="motivos_municoes_armas_selecionadas[index]" rows="8" placeholder="Escreva aqui sua justificativa." class="form-control"></textarea>
                                            </p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row justify-content-center align-items-center">
                    <div class="col-10 col-lg-8 text-center mt-7 border border-primary rounded p-4 p-lg-7">
                        <h3 class="mb-0">Olá {{ nome.split(' ')[0] }}!</h3>
                        <p class="lead">Para continuarmos com a contratação é preciso que adicione suas armas em seu acervo.</p>
                        <a href="/meu-acervo" class="btn btn-primary">Acervo de armas</a>
                    </div>
                </div>
            </div>
            <span v-if="acervos != null && acervos.length > 0 && enviando == false">
                <p class="lead mt-7 mb-0">
                    Onde será sediado o Treinamento/Competição?
                </p>
                <p>Escolha uma opção abaixo:</p>
                <div class="form-group">
                    <div class="custom-control custom-radio text-small">
                        <input type="radio" required v-model="local_treino_opcao" name="local_treino_opcao" value="brasil" class="custom-control-input" id="brasil" >
                        <label class="custom-control-label h6" for="brasil">No Brasil
                            <p class="alert alert-info mt-2 font-weight-light">Guia de tráfego para Treinamento/Competição Nacional.</p>
                        </label>
                    </div>
                </div>
                <div class="form-group">
                    <div class="custom-control custom-radio text-small">
                        <input type="radio" required v-model="local_treino_opcao" name="local_treino_opcao" value="exterior" class="custom-control-input" id="exterior">
                        <label class="custom-control-label h6" for="exterior">No exterior
                            <p class="alert alert-info mt-2 font-weight-light">Guia de tráfego para Treinamento/Competição no Exterior.</p>
                        </label>
                    </div>
                </div>
            </span>
            <span v-if="local_treino_opcao == 'exterior' && enviando == false">
                <p class="lead mt-7 mb-0">
                    Informações sobre a viagem
                </p>
                <p>Preencha as informações abaixo:</p>
                <div class="form-row">
                    <div class="col-lg-6">
                        <div class="form-group">
                        <label class="control-label">Data de partida</label>
                        <input type="date" class="form-control form-control-lg" v-model="data_partida" required>
                    </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                        <label class="control-label">Data de retorno</label>
                        <input type="date" class="form-control form-control-lg" :min='data_partida'  v-model="data_retorno" required>
                    </div>
                    </div>
                    <div class="col-12">
                    <div class="form-group">
                        <label class="control-label">Instituição Organizadora</label>
                        <input type="text" class="form-control form-control-lg" v-model="instituicao_organizadora" required>
                    </div>
                </div>
                    <div class="col-lg-4">
                    <div class="form-group">
                        <label class="control-label">Sediada no país</label>
                        <input type="text" class="form-control form-control-lg" v-model="sediada" required>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label class="control-label">Cidade</label>
                        <input type="text" class="form-control form-control-lg" v-model="cidade" required>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label class="control-label">Estado</label>
                        <input type="text" class="form-control form-control-lg" v-model="estado" required>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="control-label w-100">Endereço completo de onde ficará</label>
                        <textarea style="height:80px;" class="form-control" v-model="endereco" required>
                        </textarea>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="control-label w-100">Meio de transporte utilizado para a viagem será</label>
                        <input type="text" class="form-control form-control-lg" v-model="transporte" required>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="control-label w-100">A rota seguida será</label>
                        <textarea style="height:140px;" class="form-control" v-model="rota" required>
                        </textarea>
                    </div>
                </div>
                </div>
            </span>
            <span v-if="enviando == false">
                <div class="form-group">
                    <div class="custom-control custom-checkbox text-small">
                        <input required type="checkbox" name="confirma_guia" value="true" class="custom-control-input" id="leu-guia">
                        <label class="custom-control-label h6" for="leu-guia">Eu {{ nome }}, confirmo que os dados acima estão corretos e estou solicitando uma <span v-if="local_treino_opcao != null"><span v-if="local_treino_opcao == 'brasil'">guia de tráfego nacional.</span><span v-else>guia de tráfego internacional.</span></span><span v-else>guia de tráfego.</span></label>
                    </div>
                </div>
            </span>
            <div class="form-group" v-if="acervos != null && acervos.length > 0">
                <button :disabled="pedindo" class="btn btn-primary" v-html="botao"></button>
            </div>
        </form>
    </span>
</template>

<script>
	export default {
		name: 'Gt001',
		data() {
			return {
				acervos: null,
				enviando: false,
				local_treino_opcao: null,
				nome: 'Usuário',
				armas_selecionadas: [],
				municao_armas_selecionadas: [],
				tipo_municao_armas_selecionadas: [],
				motivos_municoes_armas_selecionadas: [],

				documentacao: null,
                currentURL: window.location.pathname,
                botao: '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento',
                pedindo: false,

                data_partida: null,
                data_retorno: null,
                instituicao_organizadora: null,
                sediada: null,
                cidade: null,
                estado: null,
                endereco: null,
                transporte: null,
                rota: null,
			}
		},
		mounted() {
			this.getAcervo();
			this.getNome();
			this.getDocumentacao();
		},
		methods: {
			getAcervo: function () {
				var app = this;
				axios.get('/acervo')
				.then((res) => {
					app.acervos = res.data;
				});
			},
			getNome: function () {
				var app = this;
				axios.get('/info/name')
				.then((res) => {
					app.nome = res.data;
				});
			},
			getDocumentacao: function () {
				var app = this;
				axios.get('/documentacao/'+this.currentURL.replace('/contratar/', ''))
				.then((res) => {
					app.documentacao = res.data;
				});
			},
			checkBox: function (index, event) {
				if (!event.target.checked) {
					this.removerDaLista(index);
				}
			},
			removerDaLista: function (index) {
				this.$delete(this.armas_selecionadas, index);
				this.$delete(this.municao_armas_selecionadas, index);
				this.$delete(this.tipo_municao_armas_selecionadas, index);
				this.$delete(this.motivos_municoes_armas_selecionadas, index);
			},
			fazerPedido: function () {
            	this.botao = '<i class="fa fa-spin fa-spinner mr-2"></i>Concluindo pedido e direcionando para pagamento';
				this.pedindo = true;
				if(Object.keys(this.armas_selecionadas).length == 0 || Object.keys(this.municao_armas_selecionadas).length == 0 || Object.keys(this.tipo_municao_armas_selecionadas).length == 0) {
                	this.botao = '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento';
					this.pedindo = false;
					this.$toast.error('Você precisa selecionar uma arma e preencher a quantidade de munições que serão trafegadas com ela e o seu tipo de munição', '', {
						position: 'center',
						overlay: true,
						timeout: 10000,
					});
				} else if(this.municao_armas_selecionadas.some(el => el > 750) && this.motivos_municoes_armas_selecionadas.length == 0) {
                	this.botao = '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento';
					this.pedindo = false;
					this.$toast.error('Você precisa dar a justificativa da quantidade de munições acima de 750', '', {
						position: 'center',
						overlay: true,
						timeout: 10000,
					});
				} else if(this.local_treino_opcao == null) {
                	this.botao = '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento';
					this.pedindo = false;
					this.$toast.error('Escolha onde será sediado o/a Treinamento/Competição', '', {
						position: 'center',
						overlay: true,
						timeout: 10000,
					});
				} else if(this.armas_selecionadas.length != this.municao_armas_selecionadas.length && this.armas_selecionadas.length != this.tipo_municao_armas_selecionadas.length && this.tipo_municao_armas_selecionadas.length != this.municao_armas_selecionadas.length) {
                	this.botao = '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento';
					this.pedindo = false;
					this.$toast.error('Você não preencheu a munição de uma arma selecionada ou esqueceu de selecionar alguma arma que tem munições preenchidas ou selecionar o tipo de munição', '', {
						position: 'center',
						overlay: true,
						timeout: 10000,
					});
				} else {
					this.enviando = true;
					var array_municao_armas_selecionadas = this.municao_armas_selecionadas;
			    	var result = array_municao_armas_selecionadas.filter(obj => {
				        return obj != null && obj != "" && obj != undefined;
			      	});
					// console.log(result);
					this.municao_armas_selecionadas = result;

		    		var array_armas_selecionadas = this.armas_selecionadas;
			    	var result = array_armas_selecionadas.filter(obj => {
				        return obj != null && obj != "" && obj != undefined;
			      	});
					// console.log(result);
					this.armas_selecionadas = result;

		    		var array_tipo_municao_armas_selecionadas = this.tipo_municao_armas_selecionadas;
			    	var result = array_tipo_municao_armas_selecionadas.filter(obj => {
				        return obj != null && obj != "" && obj != undefined;
			      	});
					// console.log(result);
					this.tipo_municao_armas_selecionadas = result;

					if(this.motivos_municoes_armas_selecionadas.length) {
						var array_motivos_municoes_armas_selecionadas = this.motivos_municoes_armas_selecionadas;
				    	var result = array_motivos_municoes_armas_selecionadas.filter(obj => {
					        return obj != null && obj != "" && obj != undefined;
				      	});
      					// console.log(result);
						this.motivos_municoes_armas_selecionadas = result;
				    }

					axios.post(this.currentURL, {
						doc_id: this.documentacao.id,
						local_treino_opcao: this.local_treino_opcao,
						endereco: this.endereco,
						estado: this.estado,
						cidade: this.cidade,
						armas_selecionadas: this.armas_selecionadas,
						municoes: this.municao_armas_selecionadas,
						tipo_municao: this.tipo_municao_armas_selecionadas,
						motivos: this.motivos_municoes_armas_selecionadas,
						data_partida: this.data_partida,
						data_retorno: this.data_retorno,
						instituicao: this.instituicao_organizadora,
						pais: this.sediada, //país
						transporte: this.transporte,
						rota: this.rota,
					})
					.then(res => {
						this.$toast.info("Pedido efetuado, direcionando para página de pagamento.");
						setTimeout(function() {
							window.location.href = '/meu-pedido/'+res.data+'/pagar';
						}, 4000);
					})
					.catch(err => {
						this.enviando = false;
	                	this.botao = '<i class="fa fa-check mr-2"></i>Tente novamente';
						this.pedindo = false;
						this.$toast.error(err.response.data.error);
					});
				}
			}
        },
        computed: {
            armasSelecionadas() {
                if (this.armas_selecionadas.length > 0) {
                    let armas = []
                    this.armas_selecionadas.filter(a => {
                        this.acervos.filter(k => {
                            if (a != null) {
                                if(k.id == a) {
                                    armas.push(k)
                                }
                            }
                        })
                    })
                    return armas
                }
                return null
            }
        }
	}
</script>

<style>

</style>
