<template>
	<span>
		<div class="d-flex justify-content-around align-items-center flex-wrap mb-5 mb-lg-0" v-if="pedido != null && downloadsLiberados.length > 0">
			<div class="small-2 my-3" v-if="!pedido.concluido"><i class="fa fa-spinner text-primary fa-spin mr-2"></i>gerando documentos restantes</div>
			<div v-if="currentURL.includes('admin') == true">
				<button @click="abrirModalEnviar()" id="pesquisar" class="btn btn-primary px-6">
					<i class="fas fa-cloud-upload-alt mr-2"></i>Enviar e configurar arquivos
				</button>
				<button v-if="!pedido.concluido" @click="concluirPedido(true)" id="pesquisar" class="btn btn-success px-6">
					<i class="fas fa-check mr-2"></i>Concluir documentação
				</button>
				<button v-if="!pedido.concluido" @click="concluirPedido()" id="pesquisar" class="btn btn-success px-6">
					<i class="fas fa-check mr-2"></i>Concluir documentação em silêncio
				</button>
				<button v-if="pedido.concluido" @click="reabrirPedido()" id="pesquisar" class="btn btn-danger px-6">
					<i class="fas fa-check mr-2"></i>Reabrir documentação
				</button>
			</div>
		</div>
		<div class="row py-5">
			<div class="col-12" v-if="pedido != null">
				<div class="accordion accordion-connected shadow-5" id="accordion-manual" v-show="downloadsLiberados.length > 0">
					<div class="card" v-for="(download, index) in downloadsLiberados">
						<h6 class="card-title mb-0 bg-dark">
						<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" :href="'#collapse-download-' + download.id"
							aria-expanded="false">
							<p class="mb-0 w-100 text-white lead">{{ download.nome }} <span class="float-right small">Adicionado {{ formatarData(download.created_at) }}</span></p>
						</a>
						</h6>
						<div :id="'collapse-download-' + download.id" class="collapse" data-parent="#accordion-manual" style="">
							<div class="card-body">
								<div class="row no-gutters">
									<div class="col-12 col-md-10">
										<p class="mb-0" v-html="download.descricao"></p>
									</div>
									<!-- <div class="col-12 col-md-2 align-self-center" v-for="link in JSON.parse(download.arquivo)">
													<a class="btn btn-xs btn-block btn-primary imprimir" target="_blank" :href="'/storage/' + link.download_link"><i class="fa fa-print mr-3"></i>Imprimir</a>
									</div> -->
									<div class="col-12 col-md-2 align-self-center">
										<button :id="'download-'+download.id" class="btn btn-primary imprimir" @click="baixar(null,download.id)"><i class="fa fa-print mr-3"></i><span>Imprimir</span></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-show="downloadsLiberados.length == 0">
					<div class="col-12 py-7 text-center">
						<i class="fa fa-spinner fa-spin fa-3x mb-5"></i>
						<h5 v-if="currentURL.includes('admin') == false">Ainda não adicionamos nenhum documento gerado manualmente à sua documentação. <br> Você será notificado quando adicionarmos documentos e quando a documentação for concluída.</h5>
						<div v-else>
							<h5>Ainda não adicionamos nenhum documento gerado manualmente à documentação. <br> Siga os passo a passos para gerar os documentos e após adiciona-los conclua a documentação.</h5>
							<button @click="abrirModalEnviar()" id="pesquisar" class="btn btn-primary px-6">
								<i class="fas fa-cloud-upload-alt mr-2"></i>Enviar e configurar arquivos
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12" v-if="pedido != null && pedido.documentacao.codigo.toLowerCase().includes('cr') == true && currentURL.includes('admin') == false">
				<certidoes-idoneidade :pedido="pedido"></certidoes-idoneidade>
			</div>
			<div class="col-12 mt-7" v-if="downloadsLiberados.length > 0 && pedido.guia != null && pedido.documentacao.codigo.toLowerCase().includes('gt') == true">
				<h1 class="mb-0">Sistema de Guia de Tráfego Eletrônica</h1>
				<p v-if="currentURL.includes('admin') == false">Preencha abaixo com o(s) Número(s) de Autenticação(ões) da(s) GRU(s) e a data de pagamento da GRU para que possamos gerar o(s) pedido(s) no SGTE, o número de autenticação estará no recibo de pagamento.</p>
				<p v-else>
					Abaixo estarão o(s) Número(s) de Autenticação(ões) da(s) GRU(s) e a data de pagamento da GRU para que possamos gerar o(s) pedido(s) no SGTE.
				</p>
			</div>
			<div class="col-12 my-5" v-if="downloadsLiberados.length > 0 && pedido.guia != null && pedido.documentacao.codigo.toLowerCase().includes('gt') == true">
				<div class="row py-2 justify-content-center align-items-center" v-for="(armas, index) in pedido.guia.armas">
					<div class="col-12 col-lg-2 text-center"><h1 class="d-inline">{{ index+1 }}.</h1>
						<span class="font-weight-bold"> GRU:</span>
					</div>
					<div class="col-12 col-lg-5" v-if="armas.numero_autenticacao_gru == null && currentURL.includes('admin') == false">
						<label>Nº de autenticação</label><input required type="text" v-model="numero_autenticacao[index]" class="form-control form-control-lg">
					</div>
					<div class="col-12 col-lg-5" v-else-if="armas.numero_autenticacao_gru == null && currentURL.includes('admin') == true">
						<label>Nº de autenticação</label><span class="d-block text-center font-weight-bold">Aguardando preenchimento pelo requerente</span>
					</div>
					<div class="col-12 col-lg-5" v-if="armas.numero_autenticacao_gru != null">
						<label>Nº de autenticação</label>
						<p class="text-center font-weight-bold">{{ armas.numero_autenticacao_gru }}</p>
					</div>
					<div class="col-12 col-lg-3" v-if="armas.data_pagamento_guia == null && currentURL.includes('admin') == false">
						<label>Data de pagamento</label><input required type="date" v-model="data_pagamento[index]" class="form-control form-control-lg">
					</div>
					<div class="col-12 col-lg-5" v-else-if="armas.data_pagamento_guia == null && currentURL.includes('admin') == true">
						<label>Data de pagamento</label><span class="d-block text-center font-weight-bold">Aguardando preenchimento pelo requerente</span>
					</div>
					<div class="col-12 col-lg-3" v-if="armas.data_pagamento_guia != null">
						<label>Data de pagamento</label>
						<p class="text-center font-weight-bold">{{ formatarData(armas.data_pagamento_guia) }}</p>
					</div>
					<div class="col-12 col-lg-2 align-items-center" v-if="armas.numero_autenticacao_gru == null && currentURL.includes('admin') == false">
						<button type="button" @click="salvarNumeroAutenticacao(armas.id, numero_autenticacao[index], data_pagamento[index])" class="btn btn-success">Salvar</button>
					</div>
					<div class="col-12 col-lg-2 align-items-center text-center" v-if="armas.numero_autenticacao_gru != null">
						<i class="fa fa-check text-success mr-2"></i>
					</div>
				</div>
				<div class="row my-4" v-if="numero_autenticacao_completos && currentURL.includes('admin') == false">
					<div class="col-12 alert alert-info text-center py-3">
						<p>Agora que você colocou o(s) Número(s) de Autenticação(ões) nós iremos gerar o(s) pedido(s) no SGTE e adicionaremos na lista de <strong>Documentos gerados manualmente pela cacdocs</strong>, aguarde<span class="blink">.</span><span class="blink">.</span><span class="blink">.</span></p>
					</div>
				</div>
			</div>
			<div v-if="pedido != null && currentURL.includes('admin') == true" class="modal" :id="'modalEnviar'" tabindex="-1" role="dialog" aria-hidden="true">
	            <div class="modal-dialog modal-lg" role="document">
	                <div class="modal-content rounded-0">
	                    <div class="modal-header my-auto">
	                        <h5 class="mb-0 text-muted">
	                            Enviar e configurar documentos para {{ pedido.codigo }}
	                        </h5>
	                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
	                            <i class="fa fa-times"></i>
	                        </button>
	                    </div>
	                    <div class="modal-body p-0" id="result">
	                        <div class="row text-center justify-content-center">
	                            <div class="col-md-12 col-lg-10 pt-7">
	                                <h2 class="mb-2 mb-lg-0">
	                                    Olá {{ userNome }},
	                                </h2>
	                                <div class="lead mb-7">
	                                    <p class="mb-2" v-if="arquivos.length == 0">Escolha abaixo o(s) arquivo(s) para enviar:</p>
	                                	<p class="mb-2" v-else>Preencha os campos de cada arquivo para enviar:</p>
	                                    <input ref="arquivos" id="arquivos" type="file" multiple required class="form-control" accept="application/pdf" v-on:change="onChangeArquivos">
	                                </div>
	                                <div class="mb-7" v-if="arquivosEnviar > 0">
	                                	<h4>Arquivos para a serem enviados:</h4>
	                                	<div class="row align-items-center text-left border border-primary p-3 mb-2 position-relative" :key="index" v-if="arquivos.length > 0" v-for="(documento, index) in arquivos">
		                  					<button data-toggle="tooltip" title="Remover arquivo" @click="removerArquivo(index)" class="position-absolute btn btn-danger btn-sm" style="top:-5px;right:-5px;cursor:pointer;z-index: 99">
												<i class="fa fa-times mr-2"></i> Remover
		                  					</button>

	                                		<div class="col-12 text-center text-lg-left">
	                                			<label class="mb-0">Arquivo</label>
	                                			<p class="small mb-0" v-html="documento.name + ' (' + Number((documento.size / 1024 / 1024).toFixed(1)) + 'MB)'"></p>
	                                		</div>
	                                	</div>
	                        			<button @click="enviarArquivos()" type="button" class="btn btn-primary mt-3" v-html="botao"></button>
	                                </div>
	                                <div v-if="downloads.length > 0">
	                                	<h4>Arquivos enviados:</h4>
	                                	<div class="row align-items-center text-left border border-primary p-3 mb-2 position-relative" :key="index" v-if="downloads.length > 0" v-for="(documento, index) in downloads">
		                  					<button data-toggle="tooltip" title="Remover arquivo" @click="apagarDocumento(documento.id)" class="position-absolute btn btn-danger btn-sm" style="top:-5px;right:-5px;cursor:pointer;z-index: 99">
												<i class="fa fa-times mr-2"></i> Remover
		                  					</button>

	                                		<div class="col-12 text-center text-lg-left">
	                                			<label class="mb-0">Arquivo</label>
	                                			<div class="small mb-3">{{ jsonParsing(documento.arquivo) }}</div>
	                                		</div>
	                                		<div class="col-12 col-lg-3 text-center text-lg-left">
	                                			<label :for="'liberado_'+documento.id">Liberado?</label>
	                                			<div class="form-input-radio mt-3">
							                    	<div class="custom-control custom-radio text-small">
													    <input :name="'liberado_'+documento.id" type="radio" :true-value="1" value="1" class="custom-control-input" :id="'sim_'+documento.id" v-model="downloads[index]['liberado']">
													    <label class="custom-control-label h6" :for="'sim_'+documento.id">
							                            Sim
													    </label>
													</div>

							                        <div class="custom-control custom-radio text-small">
													    <input :name="'liberado_'+documento.id" type="radio" :false-value="0" value="0" class="custom-control-input" :id="'nao_'+documento.id" v-model="downloads[index]['liberado']">
													    <label class="custom-control-label h6" :for="'nao_'+documento.id">
							                            Não
													    </label>
													</div>
							                    </div>
	                                		</div>
	                                		<div class="col-12 col-lg-4 text-center text-lg-left">
	                                			<label :for="'nome_'+documento.id">Nome</label>
	                                			<input :id="'nome_'+documento.id" type="text" v-model="downloads[index]['nome']" class="form-control">
	                                		</div>
	                                		<div class="col-12 col-lg-5 text-center text-lg-left">
	                                			<label :for="'descricao_'+documento.id">Descrição</label>
	                                			<input :id="'descricao_'+documento.id" type="text" v-model="downloads[index]['descricao']" class="form-control">
	                                		</div>
	                                		<div class="col-12 text-center justify-content-around flex-wrap mt-4">
	                                			<button :disabled="downloads[index]['nome'] == null && downloads[index]['descricao'] == null || downloads[index]['nome'] == '' && downloads[index]['descricao'] == ''" @click="salvarDados(documento.id, downloads[index]['nome'], downloads[index]['descricao'], downloads[index]['liberado'])" class="btn btn-primary btn-sm" type="button"><i class="fa fa-save mr-2" id="pesquisar"></i>Salvar</button>
												<button :id="'download-'+documento.id" class="btn btn-primary btn-sm imprimir" @click="baixar(null,documento.id)"><i class="fa fa-print mr-3"></i><span>Visualizar</span></button>
	                                		</div>
	                                	</div>
	                                </div>
	                            </div>
	                        </div>
	                    </div>
	                    <div class="modal-footer">
	                        <button @click="fecharModalEnviar()" class="btn btn-primary" id="pesquisar"><i class="fa fa-times mr-2"></i>Fechar</button>
	                    </div>
	                </div>
	            </div>
	        </div>
		</div>
	</span>
</template>

<script>
	import CertidoesIdoneidade from './CertidoesIdoneidade.vue';
	import dayjs from 'dayjs';
	export default {
		name: 'DocumentosManuais',
		props: {
			userNome: String,
		},
		data() {
			return {
                currentURL: window.location.pathname,
                getURL: '/meu-pedido',
				downloads: [],
				downloadsLiberados: [],
				pedido: null,
				codigo: null,
				numero_autenticacao: [],
				data_pagamento: [],

				arquivos: [],
				data_arquivos: new FormData(),
				botao: '<i class="fa fa-check mr-3"></i>Enviar arquivos',
			}
		},
		mounted() {
            if (this.currentURL.includes('admin')) {
            	this.codigo = this.currentURL.replace('/admin/pedido/', '');
            	this.getURL = '/admin/pedido';
			} else {
            	this.codigo = this.currentURL.replace('/meu-pedido/', '');
            	this.getURL = '/meu-pedido';
            }
            if (pedido.concluido != true) {
				this.getPedido();
            	var app = this;
            	var refreshIntervalId = setInterval(function() {
            		if (pedido.concluido == true) {
            			clearInterval(refreshIntervalId);
            		}
            		app.getPedido()
            		console.log('verificando pedido (5 min)');
            	}, 300000);
            } else {
				this.getPedido();
			}
			$(document).on('shown.bs.modal', '.modal', function () {
                $('.modal-backdrop').before($(this));
            });
			$(document).on('show.bs.tooltip', '.modal', function () {
			  $('[data-toggle="tooltip"]').tooltip({
			  	container: 'body',
			  })
			})
		},
		methods: {
			jsonParsing: function(array) {
				var parse = JSON.parse(array);
				return parse[0].original_name;
			},
			abrirModalEnviar: function (id) {
                $('div[id="modalEnviar"]').modal('show');
            },
            fecharModalEnviar: function (id) {
                $('div[id="modalEnviar"]').modal('hide');
            },
            onChangeArquivos: function(e) {
		      	var files = e.target.files || e.dataTransfer.files;
		      	if (!files.length)
		        	return;
		      	if(this.arquivos.length > 0) {
		        	var arquivosSalvas = this.arquivos.length;
		      	} else {
		        	var arquivosSalvas = 0;
		      	}
		      	if(files.length > 1) {
			        for (var i =0; i < files.length; i++) {
			          this.arquivos.push(files[i]);
			        }
		      	} else {
		        	this.arquivos.push(files[0]);
		      	}
		      	document.getElementById("arquivos").value = [];
		    },
		    removerArquivo: function(index) {
				this.$delete(this.arquivos, index);
		    },
		    prepareFields() {
		      	if (this.arquivos.length > 0) {
		        	for (var key in this.arquivos) {
		          		this.data_arquivos.append('arquivos[]', this.arquivos[key]);
		        	}
		      	}
		    },
		    enviarArquivos: function()
		    {
		    	if (this.arquivos.length > 0) {
		    		this.botao = '<i class="fa fa-spin fa-spinner mr-3"></i>Enviando arquivos';
		    		this.prepareFields();
		    		var data = this.data_arquivos;
			    	axios.post('/admin/pedido/'+ this.codigo +'/arquivos', 
	    			data,
    				{
			    		headers: {
			        		'Content-Type': 'multipart/form-data'
			    		}
			    	})
			    	.then(res => {
			    		// console.log(res);
			    		this.downloads = res.data.downloads;
			    		this.arquivos = [];
			    		this.$toast.success('Documentos enviados com sucesso!');
			    	})
			    	.catch(err => {
			    		// console.log(err.response);
		    			this.botao = '<i class="fa fa-check mr-3"></i>Tentar novamente';
			    		this.$toast.error(err.response.data.error);
			    	});
			    } else {
			    	this.$toast.error('Você precisa selecionar documentos para serem enviados.');
			    }
		    },
		    apagarDocumento: function(id) {
				axios.delete('/admin/pedido/'+ this.codigo +'/arquivo/'+id)
				.then(res => {
					this.downloads = res.data.downloads;
					this.downloadsLiberados = res.data.downloadsLiberados;
					this.$toast.success('Apagamos o documento com sucesso!');
				})
				.catch(err => {
					this.$toast.error(err.response.data.error);
				});
		    },
		    salvarDados: function(id, nome, descricao, liberado) {
		    	axios.put('/admin/pedido/'+ this.codigo +'/arquivo/'+id, {
		    		nome: nome,
		    		descricao: descricao,
		    		liberado: liberado
		    	})
		    	.then(res => {
					this.downloads = res.data.downloads;
					this.downloadsLiberados = res.data.downloadsLiberados;
					this.$toast.success('Atualizamos o documento com sucesso!');
		    	})
		    	.catch(err => {
					this.$toast.error(err.response.data.error);
		    	});
		    },
		    concluirPedido: function(notificar = null) {
		    	axios.put('/admin/pedido/'+ this.codigo +'/concluir', {
		    		notificar: notificar
		    	})
		    	.then(res => {
					this.pedido = res.data.pedido;
					this.$toast.success('Pedido concluído com sucesso!');
		    	})
		    	.catch(err => {
					this.$toast.error(err.response.data.error);
		    	})
		    },
		    reabrirPedido: function() {
		    	axios.put('/admin/pedido/'+ this.codigo +'/reabrir')
		    	.then(res => {
					this.pedido = res.data.pedido;
					this.$toast.success('Pedido reaberto com sucesso!');
		    	})
		    	.catch(err => {
					this.$toast.error(err.response.data.error);
		    	})
		    },
			formatarData: function (data) {
				return dayjs(data).format('DD/MM/YYYY');
			},
			getPedido: function () {
				axios.get(this.getURL+'/'+this.codigo)
				.then((res) => {
					this.pedido = res.data.pedido;
					this.downloads = res.data.downloads;
					this.downloadsLiberados = res.data.downloadsLiberados;
				})
				.catch((err) => {
					this.$toast.error(err.response.data.error);
				});
			},
			baixar: function(documento = null, arquivo = null) {
		      	var carregandoPagina = window.open('/aguarde', '_blank');
	    	  	$('#download-'+arquivo).addClass('btn-success').removeClass('btn-primary');
                $('#download-'+arquivo).find('svg').attr('data-icon', 'check');
                $('#download-'+arquivo).find('svg').removeClass('mr-3');
		      	$('#download-'+arquivo).children('span').addClass('d-none');
		      	axios.post(this.getURL+'/'+this.codigo+'/imprimir/'+documento+'/'+arquivo)
		      	.then(res => {
			        // let link = document.createElement('a');
			        // link.href = res.url;
			        // link.target = '_blank';
			        // link.click();
			        carregandoPagina.location.href = res.data.url;
			        this.$toast.success(res.data.message);
		      	})
	      		.catch(err => {
			        // console.error(err);
			        this.$toast.error(err.response.data.error);
	      		});
		  	},
		  	salvarNumeroAutenticacao: function(id, nr, data) {
		      	if(nr == undefined || nr == null || data == undefined || data == null) {
		        	this.$toast.error('Preencha o Número de Autenticação e a Data de pagamento para Salvar');
		      	} else {
			        axios.post('/meu-pedido/'+this.codigo+'/guia/numero-autenticacao', {
		            	arma_id: id,
		            	numero_autenticacao: nr,
		            	data_pagamento: data,
			        })
			        .then(res => {
			          this.$toast.success('Número de Autenticação: '+nr+' adicionado com sucesso');
			          this.pedido = res.data.pedido;
			        })
			        .catch(err => {
			          this.$toast.error('Algo ocorreu e não conseguimos adicionar o número de autenticação');
			        });
		   		}
		   	}
		},
		computed: {
			numero_autenticacao_completos() {
		      var resultado = this.pedido.guia.armas;
		      var result = resultado.filter(obj => {
		        return obj.numero_autenticacao_gru != null
		      });
		      // console.log(result.length);
		      // console.log(Object.keys(resultado).length);
		      if (result.length == Object.keys(resultado).length)
		        return true
		      else
		        return false
		    },
		    arquivosEnviar() {
		    	if(this.arquivos.length == 0) {
    				this.botao = '<i class="fa fa-check mr-3"></i>Enviar arquivos';
		    	}
		    	return this.arquivos.length;
		    }
		}
	}
</script>

<style>
</style>