<template>
<span>
	<div class="row pb-5" v-if="pedido != null">
		<!-- CR -->
		<div class="col-12" v-if="pedido.documentacao.codigo.toLowerCase().includes('cr') == true">
			<div class="accordion accordion-connected shadow-5" id="accordion-outros">
				<div class="card" v-for="(documento, index) in checklist" :key="index">
					<h6 class="card-title mb-0 border border-primary">
					<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" :href="'#collapse-outros-'+index"
						aria-expanded="false">
						<span class="mr-2 text-primary lead w-100">{{ documento.nome }}
							<span v-if="documento.nome == 'Identificação pessoal' || documento.nome == 'Comprovante de endereço residencial'" class="float-right badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
							<span v-if="documento.nome == 'Comprovante de ocupação lícita'" class="float-right badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
							<span class="float-right badge badge-warning" v-if="documento.nome == 'Comprovante de endereço de acervo' && !pedido.acervo_id"><i class="fa fa-question mr-2"></i>depende</span>
							<span class="float-right badge text-success" v-if="documento.nome == 'Comprovante de endereço de acervo' && pedido.acervo_id"><i class="fa fa-check mr-2"></i>é necessário</span>
							<span v-if="ficha.confirmar_laudos && documento.nome == 'Capacidade técnica comprovada por instrutor de armamento e tiro registrado pela Polícia Federal'" class="float-right">
								<span v-if="pedido.cr.procurador == true && pedido.cr.atirador_desportivo != true && pedido.cr.cacador != true && pedido.cr.colecionamento != true" class="badge text-danger"><i class="fa fa-times mr-2"></i>não é necessário</span>
								<span v-else class="badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
							</span>
							<span v-if="!ficha.confirmar_laudos && documento.nome == 'Capacidade técnica comprovada por instrutor de armamento e tiro registrado pela Polícia Federal'" class="float-right">
								<span v-if="pedido.cr.procurador == true && pedido.cr.atirador_desportivo != true && pedido.cr.cacador != true && pedido.cr.colecionamento != true" class="badge text-danger"><i class="fa fa-times mr-2"></i>não é necessário</span>
								<span v-else class="badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
							</span>
							<span class="float-right" v-if="ficha.psicologica && documento.nome == 'Atestado de aptidão psicológica'">
								<span v-if="pedido.cr.procurador == true && pedido.cr.atirador_desportivo != true && pedido.cr.cacador != true && pedido.cr.colecionamento != true" class="badge text-danger"><i class="fa fa-times mr-2"></i>não é necessário</span>
								<span v-else class="badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
							</span>
							<span class="float-right" v-if="!ficha.psicologica && documento.nome == 'Atestado de aptidão psicológica'">
								<span v-if="pedido.cr.procurador == true && pedido.cr.atirador_desportivo != true && pedido.cr.cacador != true && pedido.cr.colecionamento != true" class="badge text-danger"><i class="fa fa-times mr-2"></i>não é necessário</span>
								<span v-else class="badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
							</span>
							<span class="float-right" v-if="ficha.confirmar_laudos && documento.nome == 'Declaração de filiação a entidade de tiro desportivo ou de caça'">
								<span v-if="pedido.cr.procurador == true && pedido.cr.atirador_desportivo != true && pedido.cr.cacador != true && pedido.cr.colecionamento != true" class="badge text-danger"><i class="fa fa-times mr-2"></i>não é necessário</span>
								<span v-else class="badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
							</span>
							<span class="float-right" v-if="!ficha.confirmar_laudos && documento.nome == 'Declaração de filiação a entidade de tiro desportivo ou de caça'">
								<span v-if="pedido.cr.procurador == true && pedido.cr.atirador_desportivo != true && pedido.cr.cacador != true && pedido.cr.colecionamento != true" class="badge text-danger"><i class="fa fa-times mr-2"></i>não é necessário</span>
								<span v-else class="badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
							</span>
						</span>
					</a>
					</h6>
					<div :id="'collapse-outros-'+index" class="collapse" data-parent="#accordion-outros" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12 col-md-10" v-if="documento.nome == 'Declaração de filiação a entidade de tiro desportivo ou de caça'">
									<p v-html="documento.descricao"></p>
									<p class="alert alert-info" v-if="pedido.cr.procurador == true && pedido.cr.atirador_desportivo != true && pedido.cr.cacador != true && pedido.cr.colecionamento != true">
										<i class="fa fa-info-circle mr-2"></i>Não é necessário quando o CR é somente para PROCURADOR.
									</p>
									<p v-if="ficha.clube" class="d-block alert alert-info py-4">
										<i class="fa fa-info-circle mr-2"></i>Veja se o {{ ficha.clube }} emite Declarações de filiação para associados em seu site ou solicite uma diretamente com os responsáveis.
									</p>
									<p v-else class="alert alert-info">
										<i class="fa fa-info-circle mr-2"></i><strong>Você precisa de um clube de tiro e solicitar uma Declaração de filiação</strong>, use o botão ao lado para visitar os clubes que estão cadastrados no cacdocs ou a lista abaixo de clubes parceiros que ganham cupons de desconto para seus associados.
									</p>
								</div>
								<div class="col-12 col-md-8" v-else-if="documento.nome == 'Capacidade técnica comprovada por instrutor de armamento e tiro registrado pela Polícia Federal' || documento.nome == 'Atestado de aptidão psicológica'">
									<p v-html="documento.descricao"></p>
									<p class="alert alert-info" v-if="pedido.cr.procurador == true && pedido.cr.atirador_desportivo != true && pedido.cr.cacador != true && pedido.cr.colecionamento != true">
										<i class="fa fa-info-circle mr-2"></i>Não é necessário quando o CR é somente para PROCURADOR.
									</p>
									<p class="alert alert-info">
										<i class="fa fa-info-circle mr-2"></i>Em caso de dispensa, anexar ao processo comprovante de estabilidade.
									</p>
								</div>
								<div v-else class="col-12">
									<p v-if="documento.nome == 'Comprovante de endereço de acervo'">
										<span class="alert alert-info">
											<i class="fa fa-info-circle mr-2"></i>Comprovante de residência do segundo endereço
										</span>
									</p>
									<p v-html="documento.descricao"></p>
									<p v-if="documento.nome == 'Identificação pessoal' || documento.nome == 'Comprovante de endereço residencial' || documento.nome == 'Comprovante de endereço de acervo'" class="d-block alert alert-info py-4"><i class="fa fa-info-circle mr-2"></i>Atualmente é requerido uma xerox comum, leve a original para apresenta-la caso o atendente queira conferir ou leve uma cópia autenticada em cartório(não será necessário levar a original nesse caso).</p>
									<p v-if="documento.nome == 'Comprovante de endereço residencial' &&!ficha.comprovante_residencia || documento.nome == 'Comprovante de endereço de acervo'" class="text-center"><a class="btn btn-xs btn-outline-primary mt-5 imprimir" href="/imprimir/modelo-declaracao-imovel" target="_blank">
										<i class="fa fa-print mr-3"></i> Imprima o <strong>modelo de declaração do responsável pelo imóvel</strong> caso não possua um comprovante
									</a></p>
									<p v-if="documento.nome == 'Comprovante de endereço residencial' && ficha.comprovante_residencia">
										<span class="alert alert-success"><i class="fa fa-check text-success mr-3"></i> você informou possuir um comprovante.</span>
									</p>
								</div>
								<div v-if="documento.nome == 'Capacidade técnica comprovada por instrutor de armamento e tiro registrado pela Polícia Federal' || documento.nome == 'Atestado de aptidão psicológica'" class="col-12 col-md-3 text-center text-md-right">
									<a v-if="documento.nome == 'Capacidade técnica comprovada por instrutor de armamento e tiro registrado pela Polícia Federal'" class="btn btn-xs btn-block btn-primary external" target="_blank" href="http://www.pf.gov.br/servicos-pf/armas/credenciamento-de-instrutores/listagem-de-instrutores-de-armamento-e-tiro"><i class="fa fa-external-link-alt mr-3"></i>Abrir site da PF</a>
									<a v-if="documento.nome == 'Atestado de aptidão psicológica'" class="btn btn-xs btn-block btn-primary external" target="_blank" href="http://www.pf.gov.br/servicos-pf/armas/credenciamento-psicologos/psicologos-crediciados"><i class="fa fa-external-link-alt mr-3"></i>Abrir site da PF</a>
								</div>
								<div v-if="documento.nome == 'Declaração de filiação a entidade de tiro desportivo ou de caça' && !ficha.clube" class="col-12 col-md-2 align-self-center">
									<a class="btn btn-xs btn-block btn-primary" target="_blank" href="/clubes"><i class="fa fa-crosshairs mr-3"></i>Clubes</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<exibe-clubes v-if="pedido.documentacao.codigo.includes('17') == false || pedido.documentacao.codigo.includes('18') == false" :estado="pedido.endereco.estado"></exibe-clubes>
		</div>
		<!-- GT -->
		<div class="col-12" v-if="pedido.documentacao.codigo.toLowerCase().includes('gt') == true">
			<div class="accordion accordion-connected shadow-5" id="accordion-outros">
				<div class="card" v-for="(documento, index) in checklist">
					<h6 class="card-title mb-0 border border-primary">
						<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" :href="'#collapse-outros-'+index"
							aria-expanded="false">
							<span class="mr-2 text-primary lead w-100">{{ documento.nome }}
								<span v-if="pedido.guia.internacional == 1">
									<span v-if="documento.nome == 'Comprovante de inscrição' || documento.nome == 'Calendário da atividade' || documento.nome == 'Cópia do CRAF'" class="float-right badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
									<span v-if="documento.nome == 'Declaração de filiação (ITA03)' " class="float-right badge text-danger"><i class="fa fa-times mr-2"></i>não é necessário</span>
									<span v-if="documento.nome == 'Cópia do CR' || documento.nome == 'Espelho de GTE emitida pelo sistema'" class="float-right badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
								</span>
								<span v-else>
									<span v-if="documento.nome == 'Comprovante de inscrição' || documento.nome == 'Calendário da atividade' || documento.nome == 'Cópia do CRAF'" class="float-right badge text-danger"><i class="fa fa-times mr-2"></i>não é necessário</span>
									<span v-if="documento.nome == 'Declaração de filiação (ITA03)' " class="float-right badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
									<span v-if="documento.nome == 'Cópia do CR' || documento.nome == 'Espelho de GTE emitida pelo sistema'" class="float-right badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
								</span>
							</span>
						</a>
					</h6>
					<div :id="'collapse-outros-'+index" class="collapse" data-parent="#accordion-outros" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12 col-md-10" v-if="documento.nome == 'Declaração de filiação (ITA03)' && pedido.guia.internacional == 0">
									<p v-html="documento.descricao"></p>
									<p class="d-block alert alert-info py-4" v-if="ficha.clube">
										<i class="fa fa-info-circle mr-2"></i><strong>Veja se o {{ ficha.clube }} emite Declarações de filiação ITA03 para associados em seu site ou solicite uma diretamente com os responsáveis.</strong>
									</p>
									<p v-else class="alert alert-info">
										<i class="fa fa-info-circle mr-2"></i>Solicite ao seu clube de tiro.
									</p>
								</div>
								<div v-else class="col-12">
									<p v-html="documento.descricao"></p>
									<p v-if="documento.nome == 'Cópia do CR' || documento.nome == 'Cópia do CRAF' || documento.nome == 'Comprovante de endereço de acervo'" class="d-block alert alert-info py-4"><i class="fa fa-info-circle mr-2"></i>Atualmente é requerido uma xerox comum, leve a original para apresenta-la caso o atendente queira conferir ou leve uma cópia autenticada em cartório(não será necessário levar a original nesse caso).</p>
								</div>
								<div v-if="documento.nome == 'Declaração de filiação a entidade de tiro desportivo ou de caça' && !ficha.clube" class="col-12 col-md-2 align-self-center">
									<a class="btn btn-xs btn-block btn-primary" target="_blank" href="/clubes"><i class="fa fa-crosshairs mr-3"></i>Clubes</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- AF -->
		<div class="col-12" v-if="pedido.documentacao.codigo.toLowerCase().includes('af') == true">
			<div class="accordion accordion-connected shadow-5" id="accordion-outros">
				<div class="card" v-for="(documento, index) in checklist">
					<h6 class="card-title mb-0 border border-primary">
					<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" :href="'#collapse-outros-'+index"
						aria-expanded="false">
						<span class="mr-2 text-primary lead w-100">{{ documento.nome }}
							<span v-if="documento.nome.includes('CR') == true || documento.nome.includes('Declaração') == true || documento.nome.includes('Nota') == true || documento.nome.includes('identidade do requerente') == true" class="float-right badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
							<span v-if="pedido.registro_arma.tipo_compra_arma == 2 && documento.nome.includes('Guia') == true || pedido.registro_arma.tipo_compra_arma == 2 && documento.nome.includes('Invoice') == true || pedido.registro_arma.tipo_compra_arma == 2 && documento.nome.includes('Internacional') == true" class="float-right badge text-success text-lowercase">é necessário</span>
							<span v-if="pedido.registro_arma.tipo_compra_arma != 2 && documento.nome.includes('Guia') == true || pedido.registro_arma.tipo_compra_arma != 2 && documento.nome.includes('Invoice') == true || pedido.registro_arma.tipo_compra_arma != 2 && documento.nome.includes('Internacional') == true" class="float-right badge text-danger text-lowercase"><i class="fa fa-times mr-2"></i>não é necessário</span>
							<span class="float-right badge text-danger" v-if="documento.nome.includes('revalidação') == true && ficha.cr_validade != null && crVencendo >= 90"><i class="fa fa-times mr-2"></i>não é necessário</span>
							<span class="float-right badge badge-warning" v-if="documento.nome.includes('revalidação') == true && ficha.cr_validade != null && crVencendo < 90"><i class="fa fa-exclamation mr-2"></i>é recomendado</span>
							<span class="float-right badge badge-warning" v-if="documento.nome.toLowerCase().includes('revalidação')"><i class="fa fa-question mr-2"></i>depende</span>
							<span v-if="documento.nome.toLowerCase().includes('autorização') == true" class="float-right badge text-success text-lowercase"><i class="fa fa-check mr-2"></i>é necessário</span>
							<span v-if="documento.nome.toLowerCase().includes('modalidade') == true && pedido.registro_arma.calibre_de_uso == 'Permitido'" class="float-right badge text-danger text-lowercase"><i class="fa fa-times mr-2"></i>não é necessário</span>
							<span v-if="documento.nome.toLowerCase().includes('modalidade') == true && pedido.registro_arma.calibre_de_uso == 'Restrito'" class="float-right badge text-success text-lowercase"><i class="fa fa-check mr-2"></i>é necessário</span>
						</span>
					</a>
					</h6>
					<div :id="'collapse-outros-'+index" class="collapse" data-parent="#accordion-outros" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12" v-if="documento.nome.toLowerCase().includes('modalidade') == true">
									<p v-html="documento.descricao"></p>
									<p class="d-block alert alert-info text-center py-4" v-if="ficha.clube">
										Veja se o {{ ficha.clube }} emite Declarações de Modalidade de Provas para associados em seu site ou solicite uma diretamente com os responsáveis.
									</p>
									<p v-else>
										Solicite ao seu clube de tiro.
									</p>
								</div>
								<div v-else class="col-12">
									<p v-html="documento.descricao"></p>
									<p v-if="documento.nome.toLowerCase().includes('cópia') == true && !documento.nome.toLowerCase().includes('autenticada') == true" class="d-block alert alert-info py-4">Atualmente é requerido uma xerox comum, leve a original para apresenta-la caso o atendente queira conferir ou leve uma cópia autenticada em cartório(não será necessário levar a original nesse caso).</p>
									<div class="d-block" v-if="documento.nome.toLowerCase().includes('revalidação') == true">
										<div v-if="ficha.cr_validade != null && crVencendo < 90" class="alert alert-warning text-center" style="border-radius: 8px">Seu CR está <strong>vencendo</strong> em {{ crVencendo }} dias, que tal contratar o nosso serviço para geração de sua renovação para que possa adicionar o <strong>Protocolo de revalidação</strong> junto da documentação de registro? <router-link to="/documentacoes" class="font-weight-bold" target="_blank">Clique aqui</router-link>! <span class="d-block small-2">
											<i class="fa fa-info-circle mr-2"></i>
											Abriremos o link em uma nova janela
										</span></div>
										<div v-if="ficha.cr_validade != null && crVencendo >= 90" class="alert alert-success text-center" style="border-radius: 8px">
											Tudo certo! Seu CR ainda possui {{ crVencendo }} dias de validade.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- TR -->
		<div class="col-12" v-if="pedido.documentacao.codigo.toLowerCase().includes('tr') == true">
			<div class="accordion accordion-connected shadow-5" id="accordion-outros">
				<div class="card" v-for="(documento, index) in checklist">
					<h6 class="card-title mb-0 border border-primary">
					<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" :href="'#collapse-outros-'+index"
						aria-expanded="false">
						<span class="mr-2 text-primary lead w-100">{{ documento.nome }}
							<span v-if="documento.nome == 'Cópia autenticada do CRAF' || documento.nome == 'Cópia do seu Certificado de Registro (CR)' || documento.nome == 'Cópia da identidade do requerente'" class="float-right badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
							<span v-if="documento.nome == 'Cadastro Técnico Federal de Atividade Potencialmente Poluidoras CTF/APP' && pedido.transferencia != null && pedido.transferencia.acervo.toLowerCase().includes('caça')" class="float-right badge text-success text-lowercase">é necessário</span>
							<span v-if="documento.nome == 'Cadastro Técnico Federal de Atividade Potencialmente Poluidoras CTF/APP' && pedido.transferencia != null && !pedido.transferencia.acervo.toLowerCase().includes('caça')" class="float-right badge text-danger text-lowercase"><i class="fa fa-times mr-2"></i>não é necessário</span>
							<span class="float-right badge text-danger" v-if="documento.nome == 'Cópia do CR do cedente' && pedido.transferencia != null && ficha.rm == pedido.transferencia.outro_rm.replace('ª','')"><i class="fa fa-times mr-2"></i>não é necessário</span>
							<span class="float-right badge text-success" v-if="documento.nome == 'Cópia do CR do cedente' && pedido.transferencia != null && ficha.rm != pedido.transferencia.outro_rm.replace('ª','')"><i class="fa fa-check mr-2"></i>é necessário</span>
							<span class="float-right badge badge-warning" v-if="documento.nome.toLowerCase().includes('revalidação')"><i class="fa fa-question mr-2"></i>depende</span>
							<span v-if="ficha.confirmar_laudos && documento.nome == 'Declaração de RANKING'" class="float-right badge text-danger"><i class="fa fa-times mr-2"></i>não é necessário</span>
							<span v-if="!ficha.confirmar_laudos && documento.nome == 'Declaração de RANKING'" class="float-right badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
							<span v-if="ficha.confirmar_laudos && documento.nome.toLowerCase().includes('modalidade')" class="float-right badge text-danger"><i class="fa fa-times mr-2"></i>não é necessário</span>
							<span v-if="!ficha.confirmar_laudos && documento.nome.toLowerCase().includes('modalidade')" class="float-right badge text-success"><i class="fa fa-check mr-2"></i>é necessário</span>
						</span>
					</a>
					</h6>
					<div :id="'collapse-outros-'+index" class="collapse" data-parent="#accordion-outros" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12" v-if="documento.nome == 'Declaração de RANKING' || documento.nome.toLowerCase().includes('modalidade') == true">
									<p v-html="documento.descricao"></p>
									<p class="d-block alert alert-info text-center py-4" v-if="ficha.clube">
										<i class="fa fa-info-circle mr-2"></i>Veja se o {{ ficha.clube }} emite Declarações de Ranking e Declaração de Modalidade de Provas para associados em seu site ou solicite uma diretamente com os responsáveis.
									</p>
									<p v-else class="alert alert-info">
										<i class="fa fa-info-circle mr-2"></i>Solicite ao seu clube de tiro.
									</p>
								</div>
								<div v-else class="col-12">
									<p v-html="documento.descricao"></p>
									<p v-if="documento.nome.toLowerCase().includes('cópia') && !documento.nome.toLowerCase().includes('autenticada')" class="d-block alert alert-info py-4">Atualmente é requerido uma xerox comum, leve a original para apresenta-la caso o atendente queira conferir ou leve uma cópia autenticada em cartório(não será necessário levar a original nesse caso).</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</span>
</template>

<script>
	import ExibeClubes from './ExibeClubes.vue';
	import dayjs from 'dayjs';
	export default {
		name: 'DocumentosRestantes',
		components: {
			ExibeClubes,
		},
		data() {
			return {
                currentURL: window.location.pathname,
				checklist: [],
				pedido: null,
				ficha: null,
				codigo: null,
			}
		},
		mounted() {
            this.codigo = this.currentURL.replace('/meu-pedido/', '');
			this.getPedido();
		},
		methods: {
			getPedido: function () {
				axios.get('/meu-pedido/'+this.codigo)
				.then((res) => {
					this.pedido = res.data.pedido;
					this.ficha = res.data.pedido.ficha;
					this.checklist = res.data.checklistRestantes;
				})
				.catch((err) => {
					this.$toast.error(err.response.data.error);
				});
			},
		},
		computed: {
			crVencendo: function () {
	      		if(this.ficha.cr_validade) {
		        	var restante = dayjs(this.ficha.cr_validade).diff(new Date(), 'day');
		        	// console.log(Math.abs(restante));
		        	return restante;
	      		}
	    	},
		}

	}
</script>

<style>

</style>
