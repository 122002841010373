<template>
	<div class="d-flex justify-content-end align-items-center py-0 px-0 my-0">
		<button v-tooltip.boundary.window="mensagem" @click="toggleFavorito()" id="favorito" class="btn btn-sm btn-light" v-html="botao">
		</button>
	</div>
</template>

<script>
	export default {
		name: 'ProdutoFavorito',
		props: {
			produto: Number,
		},
		data() {
			return {
                botao: 'Adicionar como favorito <i class="ml-2 far fa-heart text-danger"></i>',
                status: 0,
                mensagem: 'Adicionar como favorito',
			}
		},
		mounted() {
			this.checkFavorito();
		},
		methods: {
			colorirEstrela: function () {
				$('#favorito').find('svg').attr('data-prefix', 'fa');
			},
			esvaziarEstrela: function () {
				$('#favorito').find('svg').attr('data-prefix', 'far');
			},
			checkFavorito: function () {
				axios.get('/meu-favorito/'+this.produto)
				.then( (res) => {
					if (res.data.status == true) {
						this.mensagem = 'Remover dos favoritos';
						this.botao = 'Remover dos favoritos <i class="ml-2 fa fa-heart text-danger"></i>';	
						this.status = 1;
					} else {
						this.status = 0;
						this.botao = 'Adicionar como favorito <i class="ml-2 far fa-heart text-danger"></i>';
						this.mensagem = 'Adicionar como favorito';
					}
				});
			},
			toggleFavorito: function() {
				if (this.status == 1) {
					axios.post('/meu-favorito/remover/'+this.produto, { produto: this.produto })
					.then( (res) => {
						if (res.data.status == true) {
							this.botao = 'Remover dos favoritos <i class="ml-2 fa fa-heart text-danger"></i>';
							this.mensagem = 'Remover dos favoritos';
							this.status = 1;
							this.$toast.success(res.data.message);
						} else {
							this.status = 0;
							this.botao = 'Adicionar como favorito <i class="ml-2 far fa-heart text-danger"></i>';
							this.$toast.error(res.data.message);
							this.mensagem = 'Adicionar como favorito';
						}
					})
					.catch((err) => {
						this.$toast.error(err.response.data.error);
					});
				} else {
					axios.post('/meu-favorito/adicionar/'+this.produto, { produto: this.produto })
					.then( (res) => {
						if (res.data.status == true) {
							this.botao = 'Remover dos favoritos <i class="ml-2 fa fa-heart text-danger"></i>';
							this.mensagem = 'Remover dos favoritos';
							this.status = 1;
							this.$toast.success(res.data.message);
						} else {
							this.status = 0;
							this.botao = 'Adicionar como favorito <i class="ml-2 far fa-heart text-danger"></i>';
							this.$toast.error(res.data.message);
							this.mensagem = 'Adicionar como favorito';
						}
					})
					.catch((err) => {
						this.$toast.error(err.response.data.error);
					});
				}
			}
		},
	}
</script>