/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import VueTheMask from "vue-the-mask";
// import VueMask from 'v-mask';
import { register } from 'register-service-worker';
import VTooltip from 'v-tooltip';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


AOS.init();

Vue.use(VueIziToast);
// Vue.use(VueMask);
Vue.use(VueTheMask);
Vue.use(VTooltip);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('avisos', require('./components/Avisos.vue').default);

Vue.component('usuarios-online', require('./components/admin/UsuariosOnline.vue').default);
Vue.component('admin-usuario-controles', require('./components/admin/usuario/Controles.vue').default);

Vue.component('formulario-produto', require('./components/FormularioProduto.vue').default);
Vue.component('formulario-avaliacao', require('./components/FormularioAvaliacao.vue').default);
Vue.component('formulario-contato', require('./components/FormularioContato.vue').default);
Vue.component('formulario-denuncia', require('./components/FormularioDenuncia.vue').default);
Vue.component('formulario-vota-util', require('./components/FormularioVotaUtil.vue').default);
Vue.component('formulario-avatar', require('./components/FormularioAvatar.vue').default);
Vue.component('formulario-informacoes', require('./components/FormularioInformacoes.vue').default);
Vue.component('conta-voto', require('./components/ContaVoto.vue').default);
Vue.component('produto-propostas', require('./components/ProdutoPropostas.vue').default);
Vue.component('fez-propostas', require('./components/FezPropostas.vue').default);

// Pedidos
Vue.component('passo-a-passo', require('./components/PassoAPasso.vue').default);
Vue.component('documentos-manuais', require('./components/DocumentosManuais.vue').default);
Vue.component('documentos-automaticos', require('./components/DocumentosAutomaticos.vue').default);
Vue.component('documentos-restantes', require('./components/DocumentosRestantes.vue').default);

Vue.component('exibe-clubes', require('./components/ExibeClubes.vue').default);
Vue.component('lista-planos', require('./components/ListaPlanos.vue').default);
Vue.component('pedido-pasta', require('./components/PedidoPasta.vue').default);
Vue.component('certidoes-idoneidade', require('./components/CertidoesIdoneidade.vue').default);
Vue.component('sgte', require('./components/Sgte.vue').default);

// Produtos
Vue.component('produto-favorito', require('./components/ProdutoFavorito.vue').default);
Vue.component('historico-valores', require('./components/HistoricoValores.vue').default);
Vue.component('grafico', require('./components/Grafico.vue').default);

// views
  // AF
Vue.component('Af001', require('./views/contratar/Af001.vue').default);
Vue.component('Af002', require('./views/contratar/Af002.vue').default);
Vue.component('Af003', require('./views/contratar/Af003.vue').default);
Vue.component('Af004', require('./views/contratar/Af004.vue').default);
  // CR
Vue.component('Cr003', require('./views/contratar/Cr003.vue').default);
Vue.component('Cr004', require('./views/contratar/Cr004.vue').default);
Vue.component('Cr009', require('./views/contratar/Cr009.vue').default);
Vue.component('Cr010', require('./views/contratar/Cr010.vue').default);
Vue.component('Cr013', require('./views/contratar/Cr013.vue').default);
Vue.component('Cr014', require('./views/contratar/Cr014.vue').default);
Vue.component('Cr015', require('./views/contratar/Cr015.vue').default);
Vue.component('Cr016', require('./views/contratar/Cr016.vue').default);
Vue.component('Cr017', require('./views/contratar/Cr017.vue').default);
Vue.component('Cr018', require('./views/contratar/Cr018.vue').default);
Vue.component('Cr019', require('./views/contratar/Cr019.vue').default);
Vue.component('Cr020', require('./views/contratar/Cr020.vue').default);
  // GT
Vue.component('Gt001', require('./views/contratar/Gt001.vue').default);
Vue.component('Gt002', require('./views/contratar/Gt002.vue').default);
  // TR
Vue.component('Tr001', require('./views/contratar/Tr001.vue').default);
Vue.component('Tr002', require('./views/contratar/Tr002.vue').default);

register('/OneSignalSDKWorker.js', {
  registrationOptions: { scope: '/' },
  ready (registration) {
    console.log('Service worker is active.')
  },
  registered (registration) {
    console.log('Service worker has been registered.')
  },
  cached (registration) {
    console.log('Content has been cached for offline use.')
  },
  updatefound (registration) {
    console.log('New content is downloading.')
  },
  updated (registration) {
    console.log('New content is available; please refresh.')
  },
  offline () {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error (error) {
    console.error('Error during service worker registration:', error)
  }
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.prototype.$eventBus = new Vue();

const app = new Vue({
    el: '#app',
});
