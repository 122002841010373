<template>
	<div class="row">
		<div class="col-12" v-if="pedido != null">
			<!-- Documentos -->
			<div class="accordion accordion-connected shadow-5" id="capa-doc">
				<div class="card" v-for="(documento, index) in checklist" v-if="documento.nome == 'Capa do requerimento' && rm == 1">
					<h6 class="card-title mb-0 bg-success">
					<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" href="#collapse-doc-capa"
						aria-expanded="false">
						<span class="mr-2 text-white lead">{{ documento.nome }}</span>
					</a>
					</h6>
					<div id="collapse-doc-capa" class="collapse" data-parent="#capa-doc" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12 col-md-10">
									<p v-html="documento.descricao"></p>
								</div>
								<div class="col-12 col-md-2 align-self-center">
									<button :id="'imprimir-'+documento.id" class="btn btn-primary imprimir" @click="imprimir(documento.id)"><i class="fa fa-print mr-3"></i><span>Imprimir</span></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- CR -->
			<div class="accordion accordion-connected shadow-5" id="accordion-doc" v-if="pedido.documentacao.codigo.toLowerCase().includes('cr') == true">
				<div class="card" v-for="(documento, index) in checaSoProcurador" v-if="documento.nome != 'Capa do requerimento'">
					<h6 class="card-title mb-0 bg-primary">
					<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" :href="'#collapse-doc-'+index"
						aria-expanded="false">
						<span class="mr-2 text-white lead">{{ documento.nome }}</span>
					</a>
					</h6>
					<div :id="'collapse-doc-'+index" class="collapse" data-parent="#accordion-doc" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12 col-md-10">
									<p v-html="documento.descricao"></p>
								</div>
								<div class="col-12 col-md-2 align-self-center">
									<button :id="'imprimir-'+documento.id" class="btn btn-primary imprimir" @click="imprimir(documento.id)"><i class="fa fa-print mr-3"></i><span>Imprimir</span></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- GT -->
			<div class="accordion accordion-connected shadow-5" id="accordion-doc" v-if="pedido.documentacao.codigo.toLowerCase().includes('gt') == true">
				<div class="card" v-for="(documento, index) in checklist" v-if="documento.nome != 'Capa do requerimento' && documento.nome != 'Requerimento de Guia para o Exterior' && pedido.guia.internacional == 0">
					<h6 class="card-title mb-0 bg-primary">
					<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" :href="'#collapse-doc-'+index"
						aria-expanded="false">
						<span class="mr-2 text-white lead">{{ documento.nome }}</span>
					</a>
					</h6>
					<div :id="'collapse-doc-'+index" class="collapse" data-parent="#accordion-doc" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12 col-md-10">
									<p v-html="documento.descricao"></p>
								</div>
								<div class="col-12 col-md-2 align-self-center">
									<button :id="'imprimir-'+documento.id" class="btn btn-primary imprimir" @click="imprimir(documento.id)"><i class="fa fa-print mr-3"></i><span>Imprimir</span></button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card" v-for="(documento, index) in checklist" v-if="documento.nome != 'Capa do requerimento' && documento.nome != 'Requerimento de Guia para o Exterior' && pedido.guia.internacional == 1">
					<h6 class="card-title mb-0 bg-primary">
					<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" :href="'#collapse-doc-'+index"
						aria-expanded="false">
						<span class="mr-2 text-white lead">{{ documento.nome }}</span>
					</a>
					</h6>
					<div :id="'collapse-doc-'+index" class="collapse" data-parent="#accordion-doc" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12 col-md-10">
									<p v-html="documento.descricao"></p>
								</div>
								<div class="col-12 col-md-2 align-self-center">
									<a :id="'imprimir-'+documento.id" class="btn btn-primary imprimir" @click="imprimir(documento.id)"><i class="fa fa-print mr-3"></i><span>Imprimir</span></a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card" v-for="(documento, index) in checklist" v-if="documento.nome != 'Capa do requerimento' && documento.nome == 'Requerimento de Guia para o Exterior' && pedido.guia.internacional == 1">
					<h6 class="card-title mb-0 bg-primary">
					<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" :href="'#collapse-doc-'+index"
						aria-expanded="false">
						<span class="mr-2 text-white lead">{{ documento.nome }}</span>
					</a>
					</h6>
					<div :id="'collapse-doc-'+index" class="collapse" data-parent="#accordion-doc" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12 col-md-10">
									<p v-html="documento.descricao"></p>
								</div>
								<div class="col-12 col-md-2 align-self-center">
									<a :id="'imprimir-'+documento.id" class="btn btn-primary imprimir" @click="imprimir(documento.id)"><i class="fa fa-print mr-3"></i><span>Imprimir</span></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- AF -->
			<div class="accordion accordion-connected shadow-5" id="accordion-doc" v-if="pedido.documentacao.codigo.toLowerCase().includes('af') == true">
				<div class="card" v-for="(documento, index) in checaCompraComoClube" v-if="documento.nome != 'Capa do requerimento'">
					<h6 class="card-title mb-0 bg-primary">
					<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" :href="'#collapse-doc-'+index"
						aria-expanded="false">
						<span class="mr-2 text-white lead">{{ documento.nome }}</span>
					</a>
					</h6>
					<div :id="'collapse-doc-'+index" class="collapse" data-parent="#accordion-doc" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12 col-md-10">
									<p v-html="documento.descricao"></p>
								</div>
								<div class="col-12 col-md-2 align-self-center">
									<button :id="'imprimir-'+documento.id" class="btn btn-primary imprimir" @click="imprimir(documento.id)"><i class="fa fa-print mr-3"></i><span>Imprimir</span></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- TR -->
			<div class="accordion accordion-connected shadow-5" id="accordion-doc" v-if="pedido.documentacao.codigo.toLowerCase().includes('tr') == true">
				<div class="card" v-for="(documento, index) in checklist" v-if="documento.nome != 'Capa do requerimento'">
					<h6 class="card-title mb-0 bg-primary">
					<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" :href="'#collapse-doc-'+index"
						aria-expanded="false">
						<span class="mr-2 text-white lead">{{ documento.nome }}</span>
					</a>
					</h6>
					<div :id="'collapse-doc-'+index" class="collapse" data-parent="#accordion-doc" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12 col-md-10">
									<p v-html="documento.descricao"></p>
								</div>
								<div class="col-12 col-md-2 align-self-center">
									<button :id="'imprimir-'+documento.id" class="btn btn-primary imprimir" @click="imprimir(documento.id)"><i class="fa fa-print mr-3"></i><span>Imprimir</span></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'DocumentosAutomaticos',
		data() {
			return {
                currentURL: window.location.pathname,
				checklist: [],
				pedido: null,
				codigo: null,
				rm: null,
			}
		},
		mounted() {
            this.codigo = this.currentURL.replace('/meu-pedido/', '');
			this.getPedido();
		},
		methods: {
			getPedido: function () {
				axios.get('/meu-pedido/'+this.codigo)
				.then((res) => {
					this.pedido = res.data.pedido;
					this.checklist = res.data.checklistAutomaticas;
					this.rm = res.data.rm;
				})
				.catch((err) => {
					this.$toast.error(err.response.data.error);
				});
			},
			imprimir: function(documento = null, arquivo = null) {
		      	var carregandoPagina = window.open('/aguarde', '_blank');
	    	  	$('#imprimir-'+documento).addClass('btn-success').removeClass('btn-primary');
                $('#imprimir-'+documento).find('svg').attr('data-icon', 'check');
                $('#imprimir-'+documento).find('svg').removeClass('mr-3');
		      	$('#imprimir-'+documento).children('span').addClass('d-none');
		      	axios.post('/meu-pedido/'+this.codigo+'/imprimir/'+documento+'/'+arquivo)
		      	.then(res => {
			        // let link = document.createElement('a');
			        // link.href = res.url;
			        // link.target = '_blank';
			        // link.click();
			        carregandoPagina.location.href = res.data.url;
			        this.$toast.success(res.data.message);
		      	})
	      		.catch(err => {
			        // console.error(err);
			        this.$toast.error(err.response.data.error);
	      		});
		  	}
		},
		computed: {
			checaSoProcurador() {
				if (this.pedido != null) {
					if (this.pedido.cr != null) {
						if (this.pedido.cr.procurador == true && this.pedido.cr.atirador_desportivo != true && this.pedido.cr.cacador != true && this.pedido.cr.colecionamento != true) {
							var myArray = this.checklist;
							this.checklist = myArray.filter(function(obj) {
								console.log(obj.nome);
								return obj.nome != 'Declaração de segurança do acervo' && obj.nome != 'Termo de Ciência, Compromisso e Responsabilidade';
							});
							return this.checklist;
						} else {
							return this.checklist;
						}
					} else {
						return this.checklist;
					}
				}
			},
			checaCompraComoClube() {
				if (this.pedido != null) {
					if (this.pedido.registro_arma != null) {
						if (this.pedido.registro_arma.clube == true) {
							var myArray = this.checklist;
							this.checklist = myArray.filter(function(obj) {
								console.log(obj.nome);
								return obj.nome != 'Declaração para aquisição de arma de fogo';
							});
							return this.checklist;
						} else {
							return this.checklist;
						}
					} else {
						return this.checklist;
					}
				} else {
					return this.checklist;
				}
			}
		}
	}
</script>

<style>
	
</style>