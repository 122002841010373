<template>
<span>
	<div class="row" v-if="pedido != null">
		<!-- CR -->
		<div class="col-12 pb-7" v-if="pedido.documentacao.codigo.toLowerCase().includes('cr') == true">
			<certidoes-idoneidade :forca-exibicao="forcaExibicao" :pedido="pedido" v-if="pedido.documentacao.codigo == 'CR-001' || pedido.documentacao.codigo == 'CR-002' || pedido.documentacao.codigo == 'CR-003' || pedido.documentacao.codigo == 'CR-004'"></certidoes-idoneidade>
			<div class="accordion accordion-connected shadow-5" id="accordion-guia">
				<div class="card">
					<h6 class="card-title bg-dark mb-0">
						<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" href="#collapse-gru-cr"
							aria-expanded="false">
							<span class="mr-2 text-white lead">Guia de recolhimento da união</span>
						</a>
					</h6>
						<div id="collapse-gru-cr" class="collapse" data-parent="#accordion-guia" style="">
							<div class="card-body" v-if="pedido.documentacao.codigo == 'CR-001' || pedido.documentacao.codigo == 'CR-002'">
								<div class="row no-gutters">
									<div class="col-12">
										<div class="alert alert-info py-4">
											É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
											<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
										</div>
										<p>
											<li>Primeiro passo, acesse o site do Tesouro.</li>
											<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
											<li>Segundo passo, preencha os campos:</li>
											<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
											<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
											<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
											<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
											<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
										</p>
										</p>
										<li>Terceiro passo, preencha os campos:</li>
										<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}23</span></p>
										<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
										<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
										<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
										<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
										<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">100,00</span></p>
										<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">100,00</span></p>
										Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
										<p class="alert alert-info py-4">
											Pague o boleto no <strong>Banco do Brasil</strong>, <br>
											Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
										</p>
									</div>
							</div>
						</div>
						<div class="card-body" v-if="pedido.documentacao.codigo == 'CR-003' || pedido.documentacao.codigo == 'CR-004'">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}24</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									</p>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
						<div class="card-body" v-if="pedido.documentacao.codigo == 'CR-009' || pedido.documentacao.codigo == 'CR-010'">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}24</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									</p>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
						<div class="card-body" v-if="pedido.documentacao.codigo == 'CR-013' || pedido.documentacao.codigo == 'CR-014'">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}24</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									</p>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
						<div class="card-body" v-if="pedido.documentacao.codigo == 'CR-015' || pedido.documentacao.codigo == 'CR-016'">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}24</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									</p>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
						<div class="card-body" v-if="pedido.documentacao.codigo == 'CR-017' || pedido.documentacao.codigo == 'CR-018'">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}27</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									</p>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
						<div class="card-body" v-if="pedido.documentacao.codigo == 'CR-019' || pedido.documentacao.codigo == 'CR-020'">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}28</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">25,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">25,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									</p>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 pb-7" v-if="pedido.documentacao.codigo.toLowerCase().includes('gt') == true">
			<div class="accordion accordion-connected shadow-5" id="accordion-gm">
				<sgte :forca-exibicao="forcaExibicao" :pedido="pedido"></sgte>
				<div class="text-center alert alert-danger" v-if="pedido.guia.armas.length > 1">Cada arma adicionada no requerimento precisa ter sua própria GRU e você deverá fazer um pedido de guia no SGTE para cada arma. <br>
				<strong>Ou seja você deverá gerar {{ pedido.guia.armas.length }} GRUs no valor de R$ 20,00 cada</strong>.</div>
				<div class="card">
					<h6 class="card-title bg-dark mb-0">
					<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" href="#collapse-gru-1"
						aria-expanded="false">
						<span class="mr-2 text-white lead">Guia de recolhimento da união</span>
					</a>
					</h6>
					<div id="collapse-gru-1" class="collapse" data-parent="#accordion-gm" style="">
						<div class="card-body" v-if="pedido.documentacao.codigo == 'GT-001' || pedido.documentacao.codigo == 'GT-002'">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p class="mt-4"><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
										</p>
										<li>Terceiro passo, preencha os campos:</li>
										<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}67</span></p>
										<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
										<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
										<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
										<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
										<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">20,00</span></p>
										<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">20,00</span></p>
										Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									</p>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- AF -->
		<div class="col-12 pb-7" v-if="pedido.documentacao.codigo.toLowerCase().includes('af') == true">
			<div class="accordion accordion-connected shadow-5" id="accordion-af">
				<div class="card" v-if="pedido.documentacao.codigo == 'AF-003' || pedido.documentacao.codigo == 'AF-004'">
					<h6 class="card-title bg-dark mb-0">
						<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" href="#collapse-gru-aa"
							aria-expanded="false">
							<span class="mr-2 text-white lead">Guia de recolhimento da união - Autorização para Aquisição de Produto Controlado</span>
						</a>
					</h6>
					<div id="collapse-gru-aa" class="collapse" data-parent="#accordion-af" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
										</p>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}41</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">25,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">25,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card" v-if="pedido.documentacao.codigo == 'AF-001' || pedido.documentacao.codigo == 'AF-002'">
					<h6 class="card-title bg-dark mb-0">
						<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" href="#collapse-gru-af"
							aria-expanded="false">
							<span class="mr-2 text-white lead">Guia de recolhimento da união - Apostilamento de Pessoa Física de Produto Controlado</span>
						</a>
					</h6>
					<div id="collapse-gru-af" class="collapse" data-parent="#accordion-af" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
										</p>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}24</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card" v-if="pedido.documentacao.codigo == 'AF-001' || pedido.documentacao.codigo == 'AF-002'">
					<h6 class="card-title bg-dark mb-0">
						<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" href="#collapse-gru-rf"
							aria-expanded="false">
							<span class="mr-2 text-white lead">Guia de recolhimento da união - Registro de Arma de Fogo</span>
						</a>
					</h6>
					<div id="collapse-gru-rf" class="collapse" data-parent="#accordion-af" style="">
						<div class="card-body">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
										</p>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}71</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">88,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">88,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- TR -->
		<div class="col-12 pb-7" v-if="pedido.documentacao.codigo.toLowerCase().includes('tr') == true">
			<div class="accordion accordion-connected shadow-5" id="accordion-guia">
				<div class="card">
					<h6 class="card-title bg-dark mb-0">
						<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" href="#collapse-gru-aa"
							aria-expanded="false">
							<span class="mr-2 text-white lead">Guia de recolhimento da união - Autorização para Aquisição de Produto Controlado</span>
						</a>
					</h6>
					<div id="collapse-gru-aa" class="collapse" data-parent="#accordion-guia" style="">
						<div class="card-body" v-if="pedido.documentacao.codigo == 'TR-001' || pedido.documentacao.codigo == 'TR-002'">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
										</p>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}41</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">25,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">25,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<h6 class="card-title bg-dark mb-0">
						<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" href="#collapse-gru-rf"
							aria-expanded="false">
							<span class="mr-2 text-white lead">Guia de recolhimento da união - Registro de Arma de Fogo</span>
						</a>
					</h6>
					<div id="collapse-gru-rf" class="collapse" data-parent="#accordion-guia" style="">
						<div class="card-body" v-if="pedido.documentacao.codigo == 'TR-001' || pedido.documentacao.codigo == 'TR-002'">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
										</p>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}71</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">88,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">88,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<h6 class="card-title bg-dark mb-0">
						<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" href="#collapse-gru-ac1"
							aria-expanded="false">
							<span class="mr-2 text-white lead">Guia de recolhimento da união - Apostilamento de Pessoa Física para o CESSIONÁRIO</span>
						</a>
					</h6>
					<div id="collapse-gru-ac1" class="collapse" data-parent="#accordion-guia" style="">
						<div class="card-body" v-if="pedido.documentacao.codigo == 'TR-001' || pedido.documentacao.codigo == 'TR-002'">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
										</p>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}24</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<h6 class="card-title bg-dark mb-0">
						<a class="d-flex align-items-center collapsed px-5 py-5" data-toggle="collapse" href="#collapse-gru-ac2"
							aria-expanded="false">
							<span class="mr-2 text-white lead">Guia de recolhimento da união - Apostilamento de Pessoa Física para o CEDENTE</span>
						</a>
					</h6>
					<div id="collapse-gru-ac2" class="collapse" data-parent="#accordion-guia" style="">
						<div class="card-body" v-if="pedido.documentacao.codigo == 'TR-001' || pedido.documentacao.codigo == 'TR-002'">
							<div class="row no-gutters">
								<div class="col-12">
									<div class="alert alert-info py-4">
										É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
										<p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
									</div>
									<p>
										<li>Primeiro passo, acesse o site do Tesouro.</li>
										<a class="btn btn-xs btn-block btn-primary external" target="_blank" href="https://consulta.tesouro.fazenda.gov.br/gru_novosite/gru_simples.asp"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
										<li>Segundo passo, preencha os campos:</li>
										<p><strong>Unidade Gestora (UG):</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">167086</span>
										<strong class="d-block">Gestão:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">00001</span>
										<strong class="d-block">Nome da Unidade:</strong> <span class="h2 ml-4" style="">Fundo do Exército</span>
										<strong class="d-block">Código de Recolhimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">11300-0</span><small>(é a primeira opção)</small>
										<span class="d-block mt-4">Clique em <button class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Avançar</button></span>
										</p>
									</p>
									<li>Terceiro passo, preencha os campos:</li>
									<p><strong>Número de Referência:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">2{{ pad(ficha.rm) }}24</span></p>
									<p><strong>Competência (mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(new Date(), 'MM/YYYY') }}</span></p>
									<p><strong>Vencimento (dd/mm/aaaa):</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ adicionarMesData(vencimento, 1) }}</span></p>
									<p><strong>CNPJ ou CPF do Contribuinte:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;" v-if="pedido.transferencia != null">{{ pedido.transferencia.outro_cpf }}</span></p>
									<p><strong>Nome do Contribuinte / Recolhedor:</strong> <span class="h2 ml-4" style="" v-if="pedido.transferencia != null">{{ pedido.transferencia.outro_nome }}</span></p>
									<p><strong>(=)Valor Principal:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									<p><strong>(=)Valor Total:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">50,00</span></p>
									Clique em <a class="btn text-light" style="background-color: #3b5876;cursor: default !important;">Emitir GRU</a>
									<p class="alert alert-info py-4">
										Pague o boleto no <strong>Banco do Brasil</strong>, <br>
										Procure pagar no caixa eletrônico ou no caixa convencional pois é preciso do comprovante, caso você pague pela/pelo internet/celular você precisará levar uma declaração de pagamento do banco junto com a GRU.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</span>
</template>

<script>
	import dayjs from 'dayjs';
	import CertidoesIdoneidade from './CertidoesIdoneidade.vue';
	import Sgte from './Sgte.vue';
	export default {
		name: 'PassoAPasso',
		components: {
			CertidoesIdoneidade,
			Sgte
		},
		props: {
			forcaExibicao: Boolean,
		},
		data() {
			return {
                currentURL: window.location.pathname,
                getURL: '/meu-pedido',
				checklist: [],
				pedido: null,
				ficha: null,
				user: null,
				codigo: null,
			}
		},
		mounted() {
			if (this.currentURL.includes('admin')) {
            	this.codigo = this.currentURL.replace('/admin/pedido/', '');
            	this.getURL = '/admin/pedido';
			} else {
            	this.codigo = this.currentURL.replace('/meu-pedido/', '');
            	this.getURL = '/meu-pedido';
            }
			this.getPedido();
		},
		methods: {
			getPedido: function () {
				axios.get(this.getURL+'/'+this.codigo)
				.then((res) => {
					this.pedido = res.data.pedido;
					this.user = res.data.pedido.user;
					this.ficha = res.data.pedido.ficha;
				})
				.catch((err) => {
					this.$toast.error(err.response.data.error);
				});
			},
			pad: function (n) {
      			return (n < 10) ? ("0" + n) : n;
    		},
    		formatarData: function (data, formato) {
				return dayjs(data).format(formato);
			},
			adicionarMesData: function (data, mes) {
				return dayjs(data).add(mes, 'month').format('DD/MM/YYYY');
			},
		},
		computed: {
			vencimento() {
				var today = new Date();
				return new Date(today.getFullYear(), today.getMonth()+1, 0);
			},
		}
	}
</script>

<style>
	
</style>