<template>
	<form class="col-xl-12" method="post" @submit.prevent="fazerPedido()" v-if="documentacao != null">
		<p class="lead">
			Confirme abaixo:
		</p>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_informacoes" value="true" class="custom-control-input" id="leu-informacoes">
			    <label class="custom-control-label h6" for="leu-informacoes">Eu {{ nome }}, afirmo que os dados que estão em minha <a href="/minhas-informacoes">ficha de informações</a> estão corretos.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_termos" value="true" class="custom-control-input" id="leu-termos">
			    <label class="custom-control-label h6" for="leu-termos">Eu {{ nome }}, li os <a href="/termos" target="_blank">termos de uso</a> e <a href="/privacidade" target="_blank">privacidade</a> e concordo com os mesmos para a utilização dos serviços oferecidos pelo cacdocs.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_checklist" value="true" class="custom-control-input" id="leu-checklist">
			    <label class="custom-control-label h6" for="leu-checklist">Eu {{ nome }}, li as informações da documentação <a :href="'/documentacoes/'+ documentacao.categoria.slug +'/'+ documentacao.slug" target="_blank">{{ documentacao.nome }}</a> e sua <a :href="'/documentacoes/checklist/'+ documentacao.slug" target="_blank">lista de verificação</a> e sei quais documentos serão gerados pelo cacdocs e quais são de minha responsabilidade.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_custos" value="true" class="custom-control-input" id="leu-custos">
			    <label class="custom-control-label h6" for="leu-custos">Eu {{ nome }}, entendo que não é responsabilidade do cacdocs o <span class="text-primary">pagamento de taxas</span> e <span class="text-primary">custos</span> sendo esses de minha responsabilidade. Exemplo: Guia de Recolhimento da União (GRU).</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_contratar" value="true" class="custom-control-input" id="deseja-contratar">
			    <label class="custom-control-label h6" for="deseja-contratar">Eu {{ nome }}, afirmo que desejo contratar {{ documentacao.nome }}.</label>
			</div>
		</div>
		<div v-if="enviando == true" class="text-center py-11">
			<i class="fa fa-spin fa-spinner fa-2x"></i>
		</div>
		<span v-if="enviando == false">
			<p class="lead mt-7 mb-0">
				Motivo
			</p>
			<p>Se achar necessário, preencha abaixo com o motivo do cancelamento:</p>
			<div class="form-group">
				<input type="text" v-model="motivo" placeholder="Exemplo: Mudança de país" class="form-control">
			</div>
		</span>
		<div class="form-group">
			<button :disabled="pedindo" class="btn btn-primary" v-html="botao"></button>
		</div>
	</form>
</template>

<script>
	export default {
		name: 'Cr018',
		data() {
			return {
				enviando: false,
				nome: 'Usuário',

				motivo: null,

				documentacao: null,
                currentURL: window.location.pathname,
                botao: '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento',
                pedindo: false,
			}
		},
		mounted() {
			this.getAcervo();
			this.getNome();
			this.getDocumentacao();
		},
		methods: {
			getAcervo: function () {
				var app = this;
				axios.get('/acervo')
				.then((res) => {
					app.acervos = res.data;
				});
			},
			getNome: function () {
				var app = this;
				axios.get('/info/name')
				.then((res) => {
					app.nome = res.data;
				});
			},
			getDocumentacao: function () {
				var app = this;
				axios.get('/documentacao/'+this.currentURL.replace('/contratar/', ''))
				.then((res) => {
					app.documentacao = res.data;
				});
			},
			adicionarALista: function () {
				this.atividades.push(this.atividade_atual);
				this.atividade_atual = null;
			},
			removerDaLista: function (index) {
				this.$delete(this.atividades, index);
			},
			fazerPedido: function () {
            	this.botao = '<i class="fa fa-spin fa-spinner mr-2"></i>Concluindo pedido e direcionando para pagamento';
				this.pedindo = true;
				this.enviando = true;
				axios.post(this.currentURL, {
					doc_id: this.documentacao.id,
					cancelamento_motivo: this.motivo,
				})
				.then(res => {
					this.$toast.info("Pedido efetuado, direcionando para página de pagamento.");
					setTimeout(function() {
						window.location.href = '/meu-pedido/'+res.data+'/pagar';
					}, 4000);
				})
				.catch(err => {
					this.enviando = false;
                	this.botao = '<i class="fa fa-check mr-2"></i>Tente novamente';
					this.pedindo = false;
					this.$toast.error(err.response.data.error);
				});
			}
		},
	}
</script>

<style>
	
</style>