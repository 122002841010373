<template>
    <table class="table table-responsive table-striped table-hover w-100 rounded-bottom">
        <thead class="thead-dark text-left">
            <tr>
                <th width="1%">#</th>
                <th width="90%" style="min-width: 420px;">Interessado</th>
                <th width="10%" style="min-width: 220px;">Valor</th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="propostas == null || propostasContagem == 0">
                <td class="w-100 py-7" colspan="3">
                    <i class="fa fa-spin fa-spinner fa-2x"></i>
                </td>
            </tr>
            <tr v-for="(proposta, index) in propostas" v-if="propostas != null && propostasContagem > 0">
                <td class="align-middle h3">{{ index+1 }}</td>
                <td class="align-middle text-left">
                    <div class="d-flex justify-content-center justify-content-lg-between py-4 align-items-center flex-wrap">
                        <div class="col-auto col-lg-2">
                            <div class="mb-5 mb-lg-0 avatar avatar-md flex-shring-0 bg-primary-3 text-center d-inline-block mr-2">
                                <img v-if="proposta.user.avatar == null" :src="'https://ui-avatars.com/api/?size=128&name='+ proposta.user.name +'&rounded=true&color=2c3038&background=518ef8'" :alt="proposta.user.name" class="avatar rounded-0 avatar-md flex-shrink-0 d-inline-block">
                                <div v-else>
                                    <img :src="'/storage/'+proposta.user.avatar" :alt="proposta.user.name" class="avatar rounded-circle avatar-md flex-shrink-0 d-inline-block">
                                </div>
                            </div>
                        </div>
                        <div class="col-10">
                            <h4 class="mb-3">{{ proposta.user.name }} <small v-if="proposta.user.ficha.rm != null" class="text-muted">({{ proposta.user.ficha.rm }}ª RM)</small> </h4>
                            <p class="mb-0">
                                Enviada: {{ created_at(proposta.created_at) }}
                            </p>
                            <p class="mb-0" v-if="proposta.user.endereco && proposta.user.endereco.cidade != null && proposta.user.endereco.estado != null && proposta.cancelado != true && proposta.recusado != true">
                                Residência: {{ proposta.user.endereco.cidade }} - {{ proposta.user.endereco.estado }}
                            </p>
                            <p class="mb-0" v-if="proposta.user.telefone && proposta.user.telefone.numero != null && proposta.cancelado != true && proposta.recusado != true">
                                Telefone: <a :href="'tel:' + proposta.user.telefone.numero.replace(/\D/g,'')">{{ proposta.user.telefone.numero }}</a>
                            </p>
                            <div v-if="proposta.cancelado != true && proposta.recusado != true">
                                <p class="mb-0">
                                    E-mail: <a :href="'mailto:'+ proposta.user.email">{{ proposta.user.email }}</a>
                                </p>
                                <p class="mb-0" v-if="proposta.cancelado != true && proposta.recusado != true && proposta.aceito == true && proposta.user.telefone != null">
                                    Telefone: <a :href="'tel:'+proposta.user.telefone.numero">{{ proposta.user.telefone.numero }}</a>
                                </p>
                                <p class="mb-0">
                                    Estado:
                                    <span v-if="proposta.cancelado != true && proposta.aceito != true && proposta.recusado == true" class="alert px-3 py-1 small alert-dark">recusado</span>
                                    <span v-else-if="proposta.cancelado != true && proposta.recusado != true && proposta.aceito == true" class="alert px-3 py-1 small alert-success">aceito</span>
                                    <span v-else class="alert px-3 py-1 small alert-primary">aguardando</span>
                                </p>
                                <div v-if="proposta.produto.contrato.length > 0" v-for="contrato in proposta.produto.contrato">
                                    <p class="mb-0" v-if="proposta.cancelado != true && proposta.recusado != true && proposta.aceito == true && proposta.id == contrato.proposta_id">
                                        Contrato: <span class="alert px-3 py-1 small alert-primary" v-if="proposta.produto.contrato.length > 0 && proposta.id == contrato.proposta_id">gerado</span> <span class="alert px-3 py-1 small alert-warning" v-else>aguardando</span>
                                    </p>
                                </div>
                                <div class="d-flex justify-content-lg-center mt-4" v-if="proposta.cancelado != true && proposta.recusado != true && proposta.aceito != true">
                                    <button @click="abrirModalAceitar(proposta.id)" class="btn btn-success btn-sm mr-2">Aceitar</button>
                                    <button @click="abrirModalRejeitar(proposta.id)" class="btn btn-danger btn-sm">Rejeitar</button>
                                </div>
                                <div class="d-flex justify-content-lg-center mt-4" v-else-if="proposta.cancelado != true && proposta.recusado != true && proposta.aceito == true">
                                    <div v-if="proposta.contrato != null && proposta.contrato.proposta_id == proposta.id">
                                        <a class="btn btn-sm btn-primary mr-2" href="/meus-contratos">Verifique seus contratos</a>
                                    </div>
                                    <div v-else>
                                        <button class="btn btn-sm btn-primary mr-2" @click="abrirModalContrato(proposta.id)">Emitir contrato</button>
                                    </div>
                                    <button @click="abrirModalCancelar(proposta.id)" class="btn btn-danger btn-sm">Cancelar</button>
                                </div>
                            </div>
                            <div v-else>
                                <p class="mb-0">
                                    Estado:
                                    <span v-if="proposta.cancelado == true" class="alert px-3 py-1 small alert-danger">cancelado</span>
                                    <span v-if="proposta.recusado == true" class="alert px-3 py-1 small alert-danger">recusada</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="align-middle" style="min-width: 220px;">
                    <h2 class="mb-0"><small>R$</small> {{ proposta.valor }}</h2>
                </td>
                <div class="modal" :id="'modalAceitar_'+proposta.id" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-full" role="document">
                        <div class="modal-content rounded-0">
                            <div class="modal-header my-auto">
                                <h5 class="mb-0 text-muted">
                                    Aceitar proposta
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                            <div class="modal-body p-0" id="result">
                                <div class="row text-center justify-content-center">
                                    <div class="col-md-12 col-lg-10 pt-7">
                                        <h2 class="mb-2 mb-lg-0">
                                            Olá {{ userNome }},
                                        </h2>
                                        <div class="lead mb-7">
                                            <p class="mb-0">Deseja realmente aceitar a proposta?</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button @click="aceitarProposta(proposta.id)" class="btn btn-primary"><i class="fa fa-check mr-3"></i>Aceitar proposta</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal" :id="'modalRejeitar_'+proposta.id" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-full" role="document">
                        <div class="modal-content rounded-0">
                            <div class="modal-header my-auto">
                                <h5 class="mb-0 text-muted">
                                    Rejeitar proposta
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                            <div class="modal-body p-0" id="result">
                                <div class="row text-center justify-content-center">
                                    <div class="col-md-12 col-lg-10 pt-7">
                                        <h2 class="mb-2 mb-lg-0">
                                            Olá {{ userNome }},
                                        </h2>
                                        <div class="lead mb-7">
                                            <p class="mb-0">Deseja realmente rejeitar a proposta?</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button @click="rejeitarProposta(proposta.id)" class="btn btn-primary"><i class="fa fa-times mr-3"></i>Rejeitar proposta</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal" :id="'modalCancelar_'+proposta.id" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-full" role="document">
                        <div class="modal-content rounded-0">
                            <div class="modal-header my-auto">
                                <h5 class="mb-0 text-muted">
                                    Cancelar proposta
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                            <div class="modal-body p-0" id="result">
                                <div class="row text-center justify-content-center">
                                    <div class="col-md-12 col-lg-10 pt-7">
                                        <h2 class="mb-2 mb-lg-0">
                                            Olá {{ userNome }},
                                        </h2>
                                        <div class="lead mb-7">
                                            <p class="mb-0">Deseja realmente cancelar a proposta aceita?</p>
                                            <div v-if="proposta.contrato != null && proposta.contrato.proposta_id == proposta.id">
                                                <p class="mb-0">Você tem um contrato ativo, o mesmo será cancelado juntamente com a proposta.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button @click="cancelarProposta(proposta.id)" class="btn btn-primary"><i class="fa fa-times mr-3"></i>Cancelar proposta</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal" :id="'modalContrato_'+proposta.id" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-full" role="document">
                        <form @submit.prevent="emitirContrato(proposta.id, proposta.user_id)" class="modal-content rounded-0">
                            <div class="modal-header my-auto">
                                <h5 class="mb-0 text-muted">
                                    Emitir contrato
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                            <div class="modal-body p-0" id="result">
                                <div class="row text-center justify-content-center">
                                    <div class="col-md-12 col-lg-10 pt-7">
                                        <h2 class="mb-5">
                                            Olá {{ userNome }},
                                        </h2>
                                        <div class="lead mb-7 text-left">
                                            <p class="mb-6">Preencha os campos abaixo com as informações negociadas de pagamento para gerarmos o contrato.</p>
                                            <div class="form-group">
                                                <label for="forma_pagamento" class="text-left">Forma de pagamento</label>
                                                <select required v-model="forma_pagamento" class="form-control" id="forma_pagamento">
                                                    <option disabled selected hidden :value="null">Escolha uma opção</option>
                                                    <option value="1">Dinheiro</option>
                                                    <option value="2">Cheque</option>
                                                    <option value="3">Cartão</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label for="entrada" class="text-left">Entrada</label>
                                                <select required v-model="escolha_entrada" id="entrada" class="form-control">
                                                    <option :value="null" disabled selected hidden>Escolha uma opção</option>
                                                    <option :value="false">Sem entrada</option>
                                                    <option :value="true">Com entrada</option>
                                                </select>
                                                <input :required="escolha_entrada == true" type="money" v-model="entrada" class="form-control" placeholder="Coloque o valor" v-if="escolha_entrada == true">
                                            </div>
                                            <div class="form-group">
                                                <label for="parcelas" class="text-left">Parcelas</label>
                                                <input required type="number" min="1" class="form-control" v-model="parcelas">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="submit" class="btn btn-primary" v-html="botaoContrato"></button>
                            </div>
                        </form>
                    </div>
                </div>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import dayjs from 'dayjs';
    export default {
        name: 'ProdutoPropostas',
        props: {
            userNome: String,
        },
        data() {
            return {
                propostas: null,
                contrato: null,
                produto: window.location.pathname.replace('/meu-produto/', ''),
                forma_pagamento: null,
                parcelas: 1,
                escolha_entrada: null,
                entrada: null,

                botaoContrato: '<i class="fa fa-check mr-3"></i>Emitir contrato',
            }
        },
        mounted() {
            this.carregarPropostas();
            $(document).on('shown.bs.modal', '.modal', function () {
                $('.modal-backdrop').before($(this));
            });
        },
        methods: {
            created_at: function(data) {
                return dayjs(data).format('DD/MM/YYYY');
            },
            carregarPropostas: function () {
                axios.get('/produto/'+ this.produto +'/propostas')
                .then((res) => {
                    this.propostas = res.data;
                });
            },
            abrirModalAceitar: function (id) {
                $('div[id="modalAceitar_'+id+'"]').modal('show');
            },
            fecharModalAceitar: function (id) {
                $('div[id="modalAceitar_'+id+'"]').modal('hide');
            },
            abrirModalRejeitar: function (id) {
                $('div[id="modalRejeitar_'+id+'"]').modal('show');
            },
            fecharModalRejeitar: function (id) {
                $('div[id="modalRejeitar_'+id+'"]').modal('hide');
            },
            abrirModalCancelar: function (id) {
                $('div[id="modalCancelar_'+id+'"]').modal('show');
            },
            fecharModalCancelar: function (id) {
                $('div[id="modalCancelar_'+id+'"]').modal('hide');
            },
            aceitarProposta: function(id) {
                axios.post('/produto/'+ this.produto +'/proposta/'+ id +'/aceitar')
                .then((res) => {
                    this.propostas = res.data;
                    this.fecharModalAceitar(id);
                });
            },
            rejeitarProposta: function(id) {
                axios.post('/produto/'+ this.produto +'/proposta/'+ id +'/rejeitar')
                .then((res) => {
                    this.propostas = res.data;
                    this.fecharModalRejeitar(id);
                });
            },
            cancelarProposta: function(id) {
                axios.post('/produto/'+ this.produto +'/proposta/'+ id +'/cancelar')
                .then((res) => {
                    this.propostas = res.data;
                    this.fecharModalCancelar(id);
                });
            },
            abrirModalContrato: function (id) {
                $('div[id="modalContrato_'+id+'"]').modal('show');
            },
            fecharModalContrato: function (id) {
                $('div[id="modalContrato_'+id+'"]').modal('hide');
            },
            emitirContrato: function(id, comprador) {
                this.botaoContrato = '<i class="fa fa-spin fa-spinner mr-3"></i>Emitindo contrato';
                axios.post('/produto/'+ this.produto +'/proposta/'+ id +'/contrato', {
                    comprador_id: comprador,
                    forma_pagamento: this.forma_pagamento,
                    entrada: this.entrada,
                    parcelas: this.parcelas,
                    id: id,
                })
                .then((res) => {
                    this.botaoContrato = '<i class="fa fa-check mr-3"></i>Emitir contrato';
                    this.propostas = res.data.propostas;
                    this.contrato = res.data.contrato;
                    this.$toast.success('Seu contrato foi gerado', 'Tudo certo');
                    this.fecharModalContrato(id);
                })
                .catch((err) => {
                    this.$toast.error(err.response.data.error, 'Deu errado');
                    this.botaoContrato = '<i class="fa fa-check mr-3"></i>Tente novamente';
                });
            }
        },
        computed: {
            propostasContagem() {
                if(this.propostas != null) {
                    return this.propostas.length;
                } else {
                    return 0;
                }
            }
        }
    }
</script>

<style>
.modal-backdrop.show {
    /*z-index: 1040 !important;*/
}
.modal {
    z-index: 10455 !important;
}
</style>