<template>
	<span>
		<div class="card" v-if="pedido.geracao_manual == 0 || forcaExibicao == true">
			<h6 class="card-title bg-dark mb-0">
			<a class="d-flex align-items-center p-5 collapsed" data-toggle="collapse" href="#collapse-sgte-1"
				aria-expanded="false">
				<span class="text-white w-100 lead">Fazer o pedido de guia usando o Sistema Eletrônico de Guia de Tráfego (SGTE)</span>
			</a>
			</h6>
			<div id="collapse-sgte-1" class="collapse" data-parent="#accordion-gm" style="">
				<div class="card-body">
					<div class="row no-gutters">
						<div class="col-12">
							<p>
								<a href="http://www.sgte.eb.mil.br/guiatrafego/index.jsp" target="_blank" class="btn btn-block btn-primary mb-5"><i class="fa fa-external-link-alt mr-3"></i>Abrir SGTE</a>
								Acesse o sistema de Guia de Tráfego Eletrônica (<strong>SGTE</strong>)  e siga os passos abaixo:
								<li>Escolha a opção: <strong>SGTE - Usuário Requerente - PF</strong>;</li>
								<li>Preencha os campos:</li>
								<h2 class="ml-5 mt-4"><small class="mr-3 d-block" style="font-size:15px !important;">CPF</small> {{ ficha.cpf }}</h2>
								<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">CR</small> {{ ficha.cr }}</h2>
								<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Data de nascimento</small> {{ formatarData(ficha.data_nascimento,"DD/MM/YYYY") }}</h2>
								<li>Preencha a sequência de caracteres listados e clique em <button class="px-1 py-1 text-uppercase" style="background: #05117EFF; color:#fff !important;">entrar</button></li>
							</p>
							<hr>
							<p>
								Após autenticação do usuário o sistema exibe a tela para seleção de opção:
								<li>Selecione <strong>Solicitar Emissão Guia de Tráfego</strong></li>
								<li>
									Preencha os dados Número de Autenticação da GRU paga, Data do pagamento da GRU e E-mail
									para contato e clique no botão <button  style="background: #05117EFF; color:#fff !important;" class="px-5 py-1">Preencher Guia</button><br><strong>Todos os campos são obrigatórios.</strong>
								</li>
							</p>
							<hr>
							<p>
								<li>O Sistema validará os dados no SIGMA e exibirá o CPF, CR, Nome do Proprietário, SFPC, Responsável e Validade do CR</li>
								<li>Preencha os dados:</li>
								<h2 v-if="pedido.guia.internacional == 0" class="ml-5 mt-4"><small class="mr-3 d-block" style="font-size:15px !important;">Finalidade</small> Atividade de Tiro Desportivo</h2>
								<h2 v-if="pedido.guia.internacional == 1" class="ml-5 mt-4"><small class="mr-3 d-block" style="font-size:15px !important;">Finalidade</small> Treinamento e/ou Compoetição sediado no Exterior</h2>
								<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Validade da Guia</small> {{ formatarData(ficha.cr_validade,'DD/MM/YYYY') }}</h2>
								<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Rede de Fiscalização (Nº CODOM)</small> SFPC mais próximo a você <a href="http://www.dgo.eb.mil.br/images/Pagina_Inicial/relacao_de_om/relacao_om_codug.pdf" target="_blank" class="btn btn-primary">Relação de OM</a><a :href="'/rm/'+ficha.rm" class="btn btn-success" target="_blank">Informações da {{ ficha.rm }}ª Região Militar</a></h2>
							</p>
							<hr>
							<p>
								Clique na opção abaixo intitulado <strong>LOCAL DE ORIGEM</strong>.
								<li>O sistema validará os dados no SIGMA e exibirá o País Origem, UF Origem (Unidade Federativa) e a Cidade como Local de Origem</li>
							</p>
							<hr>
							<p>
								Clique na opção seguinte intitulado <strong>LOCAL DE ORIGEM</strong>.
								<h2 v-if="pedido.guia.internacional == 0" class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">País de Destino</small> Brasil</h2>
								<h2 v-if="pedido.guia.internacional == 0" class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">UF Destino</small> {{ pedido.endereco.estado }}</h2>
								<h2 v-if="pedido.guia.internacional == 0" class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Cidade Destino</small> {{ pedido.endereco.cidade }}</h2>
								<p v-if="pedido.guia.internacional == 0">O resto pode deixar em branco.</p>
								<h2 v-if="pedido.guia.internacional == 1" class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">País de Destino</small> {{ pedido.guia.pais }}</h2>
								<h2 v-if="pedido.guia.internacional == 1" class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">UF Destino</small> {{ pedido.guia.estado }}</h2>
								<h2 v-if="pedido.guia.internacional == 1" class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Cidade Destino (Exterior)</small> {{ pedido.guia.cidade }}</h2>
								<p v-if="pedido.guia.internacional == 1">O resto pode deixar em branco.</p>
							</p>
							<hr>
							<p>
								Clique na opção seguinte intitulado <strong>PRODUTO</strong>.
								<li>Em Armamento</li>
								<li>Preencha o Número SIGMA do Armamento desejado:</li>
								<div v-for="(arma, index) in pedido.guia.armas" class="mt-4" v-if="index == 0">
									<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">SIGMA</small> {{ arma.acervo.sigma }}</h2>
									<li class="ml-5">e clique no botão <button  style="background: #05117EFF; color:#fff !important;" class="px-2 py-1">Preencher</button></li>
									<li class="ml-5">O sistema validará os dados no SIGMA e exibirá o Número de Série, Produto, Modelo, Calibre, Espécie e Marca do armamento</li>
									<li class="ml-5">Verifique todos os dados</li>
									<li class="ml-5">e clique no botão <button  style="background: #05117EFF; color:#fff !important;" class="px-4 py-1">Incluir Armamento</button></li>
									<hr>
									<li>Em Munição</li>
									<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Quantidade de munição</small> {{ arma.municoes }}</h2>
									<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Calibre</small> {{ arma.acervo.calibre }}</h2>
									<h2 class="ml-5" v-if="arma.justificativa && arma.municoes > 750"><small class="mr-3 d-block" style="font-size:15px !important;">Justificativa</small> {{ arma.justificativa }}</h2>
									<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Espécie</small> {{ arma.tipo.split('/')[1] }}</h2>
									<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Marca</small> {{ arma.tipo.split('/')[0] }}</h2>
									<li class="ml-5">e clique no botão <button  style="background: #05117EFF; color:#fff !important;" class="px-4 py-1">Incluir Munição</button></li>
								</div>
							</p>
							<p>Não esqueça de <strong>Incluir Armamento</strong> e <strong>Incluir Munição</strong></p>
							<hr>
							<p>
								Confira o pedido na opção seguinte intitulada <strong class="text-uppercase">Seleção de Produtos</strong>.
								<li>Caso deseje excluir o produto da lista de Seleção clique no botão <i class="far fa-times-circle text-danger"></i></li>
								<li>Caso não deseje validar clique no botão <button style="background: #05117EFF; color:#fff !important;" class="px-4 py-1">Cancelar</button> e o sistema retorna para a página principal</li>
								<li>Clique no botão <button style="background: #05117EFF; color:#fff !important;" class="px-4 py-1">Visualizar Rascunho</button> o sistema exibirá um arquivo em “pdf” para prévia verificação de dados antes da inclusão de Solicitação</li>
								<li class="font-weight-bold">Clique no botão <button style="background: #05117EFF; color:#fff !important;" class="px-4 py-1">Gerar Solicitação de Guia</button></li>
								<li class="h4 mt-3">Imprima a solicitação e anexe à documentação</li>
							</p>
							<span v-if="pedido.guia.armas.length > 1">
								<div class="text-center alert alert-info py-3" v-if="pedido.guia.armas.length > 1"><h3 class="mb-0">Repita o processo inteiro com o Número de Autenticação da GRU de outra GRU de R$ 20,00 PAGA e colocando as informações da próxima arma, <strong style="text-decoration: underline;">até ter feito de todas</strong>.</h3></div>
								<div class="text-center alert alert-danger">Cada arma adicionada no requerimento precisa ter sua própria GRU e você deverá fazer um pedido de guia no SGTE para cada arma.</div>
								<div v-for="(arma, index) in pedido.guia.armas" class="mt-4 border py-5" v-if="index != 0">
									<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">SIGMA da arma nº {{ index+1 }}</small> {{ arma.acervo.sigma }}</h2>
									<li class="ml-5">e clique no botão <button  style="background: #05117EFF; color:#fff !important;" class="px-2 py-1">Preencher</button></li>
									<li class="ml-5">O sistema validará os dados no SIGMA e exibirá o Número de Série, Produto, Modelo, Calibre, Espécie e Marca do armamento</li>
									<li class="ml-5">Verifique todos os dados</li>
									<li class="ml-5">e clique no botão <button  style="background: #05117EFF; color:#fff !important;" class="px-4 py-1">Incluir Armamento</button></li>
									<h2 class="ml-5 mt-5 mb-0"><small class="mr-3 d-block" style="font-size:15px !important;">Em Munição da arma nº {{ index+1 }}</small></h2>
									<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Quantidade de munição</small> {{ arma.municoes }}</h2>
									<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Calibre</small> {{ arma.acervo.calibre }}</h2>
									<h2 class="ml-5" v-if="arma.justificativa && arma.municoes > 750"><small class="mr-3 d-block" style="font-size:15px !important;">Justificativa</small> {{ arma.justificativa }}</h2>
									<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Espécie</small> {{ arma.tipo.split('/')[1] }}</h2>
									<h2 class="ml-5"><small class="mr-3 d-block" style="font-size:15px !important;">Marca</small> {{ arma.tipo.split('/')[0] }}</h2>
									<li class="ml-5">e clique no botão <button  style="background: #05117EFF; color:#fff !important;" class="px-4 py-1">Incluir Munição</button></li>
								</div>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</span>
</template>

<script>
	import dayjs from 'dayjs';
	export default {
		name: 'Sgte',
		props: {
			pedido: Object,
			forcaExibicao: Boolean,
		},
		data() {
			return {
                currentURL: window.location.pathname,
				ficha: this.pedido.ficha,
			}
		},
		methods: {
			formatarData: function (data, formato) {
				return dayjs(data).format(formato);
			},
		}
	};
</script>

<style>
	
</style>
