<template>
	<div class="row">
		<div class="col-12" v-if="pedido != null">
			<h2 class="mt-5 w-100">Pasta</h2>
			<!-- Pastas -->
			<!-- CR -->
			<div class="col-12 pb-3" v-if="pedido.documentacao.codigo.toLowerCase().includes('cr') == true">
				<div class="float-left pt-3 bg-primary rounded text-center pl-4 pb-3">
          			<i class="fas fa-folder-open mr-3 text-white fa-3x"></i>
	          	</div>
	            <p class="lead text-center">
	                Leve sua documentação em uma <strong>pasta BRANCA sem elástico</strong>, organize todos os documentos seguindo a ordem listada no checklist.
	            </p>
				<p class="alert text-center alert-info small-2 mb-5">Alguns postos não exigem a pasta para apostilamento, confirme com o posto antes de levar a documentação.</p>
			</div>
			<!-- GT -->
			<div class="col-12 pb-3" v-if="pedido.documentacao.codigo.toLowerCase().includes('gt') == true">
				<div class="float-left pt-3 rounded text-center pl-4 pb-3">
					<i class="fas fa-folder-open mr-3 fa-3x"></i>
				</div>
				<p class="lead text-center">
					Leve sua documentação em uma <strong>pasta CINZA sem elástico</strong>, organize todos os documentos seguindo a ordem listada no checklist.
				</p>
				<p class="alert text-center alert-info small-2 mb-5">Alguns postos não exigem a pasta para apostilamento, confirme com o posto antes de levar a documentação.</p>
			</div>
			<!-- AF -->
			<div class="col-12 pb-3" v-if="pedido.documentacao.codigo.toLowerCase().includes('af') == true">
				<div class="float-left pt-3 bg-primary rounded text-center pl-4 pb-3" v-if="pedido.registro_arma.tipo_compra_arma == 0">
	              <i class="fas fa-folder-open mr-3 text-warning fa-3x"></i>
	            </div>
	            <div class="float-left pt-3 rounded text-center pl-4 pb-3" v-if="pedido.registro_arma.tipo_compra_arma == 1 || pedido.registro_arma.tipo_compra_arma == 2">
	              <i class="fas fa-folder-open mr-3 text-primary fa-3x"></i>
	            </div>
	            <div class="lead text-center" v-if="pedido.registro_arma.tipo_compra_arma == 0">
	              Leve sua documentação em uma <strong>pasta AMARELA sem elástico</strong>, organize todos os documentos seguindo a ordem listada no checklist.
	            </div>
	            <div class="lead text-center" v-if="pedido.registro_arma.tipo_compra_arma == 1 || pedido.registro_arma.tipo_compra_arma == 2">
	              Leve sua documentação em uma <strong>pasta AZUL sem elástico</strong>, organize todos os documentos seguindo a ordem listada no checklist.
	            </div>
				<p class="alert text-center alert-info small-2 mb-5 mt-5">Alguns postos não exigem a pasta para apostilamento, confirme com o posto antes de levar a documentação.</p>
			</div>
			<!-- TR -->
			<div class="col-12 pb-3" v-if="pedido.documentacao.codigo.toLowerCase().includes('tr') == true">
				<div class="float-left pt-3 bg-primary rounded text-center pl-4 pb-3">
	          		<i class="fas fa-folder-open mr-3 text-warning fa-3x"></i>
	          	</div>
	            <p class="lead text-center">
	                Leve sua documentação em uma <strong>pasta AMARELA sem elástico</strong>, organize todos os documentos seguindo a ordem listada no checklist.
	            </p>
				<p class="alert text-center alert-info small-2 mb-5">Alguns postos não exigem a pasta para apostilamento, confirme com o posto antes de levar a documentação.</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PedidoPasta',
		data() {
			return {
                currentURL: window.location.pathname,
				checklist: [],
				pedido: null,
				codigo: null,
				rm: null,
			}
		},
		mounted() {
            this.codigo = this.currentURL.replace('/meu-pedido/', '');
			this.getPedido();
		},
		methods: {
			getPedido: function () {
				axios.get('/meu-pedido/'+this.codigo)
				.then((res) => {
					this.pedido = res.data.pedido;
					this.checklist = res.data.checklistAutomaticas;
					this.rm = res.data.rm;
				})
				.catch((err) => {
					this.$toast.error(err.response.data.error);
				});
			},
		}
	}
</script>