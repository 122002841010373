<template>
  <table
    class="table table-striped table-hover w-100 rounded-bottom my-0"
    style="overflow-x: hidden"
  >
    <tbody class="w-100" style="overflow-x: hidden">
      <transition-group name="slide">
        <tr
          v-if="usuariosOnline > 0"
          v-for="(usuario, index) in usuarios"
          v-bind:key="'user'+index"
          class="w-100"
        >
          <td class="align-middle">
            <div class="d-flex align-items-center justify-content-start">
              <img
                v-if="usuario.avatar != null"
                :src="'/storage/'+usuario.avatar"
                alt="cacdocs"
                class="avatar avatar-xs flex-shrink-0 d-inline-block"
              />
              <img
                v-else-if="usuario.nome == null || usuario.nome.length == 0"
                src="/storage/users/default.png"
                alt="cacdocs"
                class="avatar avatar-xs flex-shrink-0 d-inline-block bg-light"
              />
              <img
                v-else
                :src="'https://ui-avatars.com/api/?size=128&name='+usuario.nome+'&rounded=true&color=2c3038&background=518ef8'"
                alt="cacdocs"
                class="avatar avatar-xs flex-shrink-0 d-inline-block"
              />
              <span class="ml-2 text-capitalize">
                <span
                  v-if="usuario.nome != null || usuario.nome.length > 0"
                >{{ usuario.nome.toLowerCase() }}</span>
                <em v-else class="small">não informado</em>
              </span>
            </div>
          </td>
          <td class="align-middle font-weight-bold text-right" width="10%" style="min-width: 80px">
            <p
              v-if="usuario.role == 1"
              class="mb-0 text-center alert alert-primary-2 small px-2 py-1"
            >admnistrador</p>
            <p
              v-else-if="usuario.role == 2"
              class="mb-0 text-center alert alert-primary-3 small px-2 py-1"
            >funcionário</p>
            <p
              v-else-if="usuario.role == 3"
              class="mb-0 text-center alert alert-primary small px-2 py-1"
            >despachante</p>
            <p v-else class="mb-0 text-center alert alert-success small px-2 py-1">usuário</p>
          </td>
          <td class="align-middle text-right">
            <a :href="'/admin/usuarios/visualizar/'+usuario.id" class="btn btn-primary btn-sm">
              <i class="fa fa-user"></i>
            </a>
          </td>
        </tr>
      </transition-group>
      <tr v-if="usuariosOnline == 0">
        <td class="align-middle text-center small">nenhuma atividade recente</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "UsuariosOnline",
  data() {
    return {
      usuarios: []
    };
  },
  mounted() {
    var app = this;
    this.atualizarLista();
    var refreshing = setInterval(function() {
      app.atualizarLista();
    }, 60000);
  },
  methods: {
    atualizarLista: function() {
      axios
        .get("/usuarios-online")
        .then(res => {
          // if(res.data.length != this.usuarios.length)
          this.usuarios = res.data;
        })
        .catch(err => {
          this.$toast.error(
            "Não conseguimos atualizar os usuários online, tente atualize a página!"
          );
        });
    }
  },
  computed: {
    usuariosOnline() {
      return this.usuarios.length;
    }
  }
};
</script>

<style>
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
</style>