<template>
    <span>
        <div class="d-none d-md-block bg-primary text-white pl-6 py-0" style="bottom: 0px;left:0px;position:fixed;z-index:99999;border-top-right-radius: 5px;" v-if="arma_selecionada != null">
            Arma selecionada: <span style="text-decoration:underline;font-size: 20px;font-weight:800;">{{ armaSelecionada.marca}}, {{ armaSelecionada.modelo }}({{ armaSelecionada.sigma }})</span> <button class="btn-primary btn ml-5" @click="arma_selecionada = null"><i class="fa fa-times"></i></button>
        </div>
        <form class="col-xl-12" method="post" @submit.prevent="fazerPedido()" v-if="documentacao != null">
            <p class="lead">
                Confirme abaixo:
            </p>
            <div class="form-group">
                <div class="custom-control custom-checkbox text-small">
                    <input required type="checkbox" name="confirma_informacoes" value="true" class="custom-control-input" id="leu-informacoes">
                    <label class="custom-control-label h6" for="leu-informacoes">Eu {{ nome }}, afirmo que os dados que estão em minha <a href="/minhas-informacoes">ficha de informações</a> estão corretos.</label>
                </div>
            </div>
            <div class="form-group">
                <div class="custom-control custom-checkbox text-small">
                    <input required type="checkbox" name="confirma_acervo" value="true" class="custom-control-input" id="leu-acervo">
                    <label class="custom-control-label h6" for="leu-acervo">Eu {{ nome }}, afirmo que os dados que estão em meu <a href="/meu-acervo">acervo de armas</a> estão corretos.</label>
                </div>
            </div>
            <div class="form-group">
                <div class="custom-control custom-checkbox text-small">
                    <input required type="checkbox" name="confirma_termos" value="true" class="custom-control-input" id="leu-termos">
                    <label class="custom-control-label h6" for="leu-termos">Eu {{ nome }}, li os <a href="/termos" target="_blank">termos de uso</a> e <a href="/privacidade" target="_blank">privacidade</a> e concordo com os mesmos para a utilização dos serviços oferecidos pelo cacdocs.</label>
                </div>
            </div>
            <div class="form-group">
                <div class="custom-control custom-checkbox text-small">
                    <input required type="checkbox" name="confirma_checklist" value="true" class="custom-control-input" id="leu-checklist">
                    <label class="custom-control-label h6" for="leu-checklist">Eu {{ nome }}, li as informações da documentação <a :href="'/documentacoes/'+ documentacao.categoria.slug +'/'+ documentacao.slug" target="_blank">{{ documentacao.nome }}</a> e sua <a :href="'/documentacoes/checklist/'+ documentacao.slug" target="_blank">lista de verificação</a> e sei quais documentos serão gerados pelo cacdocs e quais são de minha responsabilidade.</label>
                </div>
            </div>
            <div class="form-group">
                <div class="custom-control custom-checkbox text-small">
                    <input required type="checkbox" name="confirma_custos" value="true" class="custom-control-input" id="leu-custos">
                    <label class="custom-control-label h6" for="leu-custos">Eu {{ nome }}, entendo que não é responsabilidade do cacdocs o <span class="text-primary">pagamento de taxas</span> e <span class="text-primary">custos</span> sendo esses de minha responsabilidade. Exemplo: Guia de Recolhimento da União (GRU).</label>
                </div>
            </div>
            <div v-if="enviando == false">
                <p class="lead mt-7 mb-0">
                    Abaixo estão as armas que estão listadas em seu <a href="/meu-acervo" target="_blank">acervo de armas</a>.
                </p>
                <p>Escolha qual arma deseja incluir no pedido de transferência:</p>
            </div>
            <div v-if="enviando == true" class="text-center py-11">
                <i class="fa fa-spin fa-spinner fa-2x"></i>
            </div>
            <div v-if="enviando == false">
                <div v-if="acervos != null && acervos.length > 0">
                    <div class="row mt-6">
                        <div class="col-12 col-lg-4 w-100" v-for="(arma, index) in acervos" :key="index">
                            <div class="">
                                <input type="checkbox" v-model="arma_selecionada" style="visibility: hidden;" :id="'arma-'+arma.id" :true-value="arma.id" :false-value="null">
                                <label class="w-100" :for="'arma-'+arma.id">
                                    <div class="card position-relative w-100" v-bind:class="{ 'border border-primary': arma_selecionada == arma.id }" style="cursor: pointer;">
                                        <div v-if="arma_selecionada == arma.id" class="text-center" style="position:absolute;min-width:100px;right:-8px;top:-16px;z-index:44;">
                                            <h6 class="text-uppercase bg-primary px-4 py-2 rounded text-white mb-0 text-center small">selecionada</h6>
                                        </div>
                                        <div class="card-header text-center w-100">
                                            <img style="max-width: 120px" src="/img/market-categoria/espingarda.png" class="img-fluid align-middle" v-if="arma.especie.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes('espingarda')">
                                            <img style="max-width: 120px" src="/img/market-categoria/pistola.png" class="img-fluid align-middle" v-else-if="arma.especie.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes('pistola')">
                                            <img style="max-width: 120px" src="/img/market-categoria/revolver.png" class="img-fluid align-middle" v-else-if="arma.especie.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes('revolver')">
                                            <img style="max-width: 120px" src="/img/market-categoria/rifle.png" class="img-fluid align-middle" v-else-if="arma.especie.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes('rifle')">
                                            <img style="max-width: 120px" src="/img/market-categoria/incognita.png" class="img-fluid align-middle" v-else>
                                        </div>
                                        <div class="card-body">
                                            <p class="mb-0"><strong>Sigma:</strong> {{ arma.sigma }}</p>
                                            <p class="mb-0"><strong>Nº de série:</strong> {{ arma.numero_serie }}</p>
                                            <p class="mb-0"><strong>Marca:</strong> {{ arma.marca }}</p>
                                            <p class="mb-0"><strong>Modelo:</strong> {{ arma.modelo }}</p>
                                            <p class="mb-0"><strong>Calibre:</strong> {{ arma.calibre }}</p>
                                            <p class="mt-2" v-if="arma_selecionada == arma.id">
                                                <label class="w-100" :for="'calibre-'+index">Restrição de calibre? <a href="http://www.in.gov.br/web/dou/-/portaria-n-1.222-de-12-de-agosto-de-2019-210735786" target="_blank" class="float-right" data-toggle="tooltip" data-title="Clique aqui e veja os calibres permitidos e restritos"><i class="fa fa-question-circle text-primary"></i></a></label>
                                                <select class="form-control" v-model="calibre_de_uso" :id="'calibre-'+index">
                                                    <option value="Permitido">Permitido</option>
                                                    <option value="Restrito">Restrito</option>
                                                </select>
                                            </p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row justify-content-center align-items-center">
                    <div class="col-10 col-lg-8 text-center mt-7 border border-primary rounded p-4 p-lg-7">
                        <h3 class="mb-0">Olá {{ nome.split(' ')[0] }}!</h3>
                        <p class="lead">Para continuarmos com a contratação é preciso que adicione suas armas em seu acervo.</p>
                        <a href="/meu-acervo" class="btn btn-primary">Acervo de armas</a>
                    </div>
                </div>
            </div>
            <span v-if="acervos != null && acervos.length > 0 && enviando == false">
                <p class="lead mt-7 mb-0">
                    Informações de quem transfere a arma
                </p>
                <p>Preencha o formulário abaixo, lembre-se de conferir pois informações incorretas causarão indeferimento do pedido:</p>
                <p class="alert alert-info py-3 text-center">
                    Se você está fazendo pedido de transferência que foi concluído pelo <strong>classificados do cacdocs</strong> algumas informações estão presentes no <strong>Contrato de Compra e Venda</strong>.
                </p>
                <div class="form-row mt-5">
                    <div class="col-lg-6">
                        <div class="form-group">
                        <label for="outro_nome" class="control-label">Nome completo</label>
                        <input id="outro_nome" type="text" class="form-control" v-model="outro_nome" required>
                    </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                        <label for="outro_profissao" class="control-label">Posto/Profissão/Categoria Funcional</label>
                        <input type="text" id="outro_profissao" class="form-control" v-model="outro_profissao" required>
                    </div>
                    </div>
                    <div class="col-lg-4">
                    <div class="form-group">
                            <label class="control-label">Situação</label>
                            <div class="form-group">
                                <div class="custom-control custom-radio text-small">
                                    <input type="radio" required v-model="outro_situacao" name="outro_situacao" value="Militar do Exército" class="custom-control-input" id="outro_militar_exercito" >
                                    <label class="custom-control-label h6" for="outro_militar_exercito">Militar do Exército
                                    </label>
                                </div>
                                <div class="custom-control custom-radio text-small">
                                    <input type="radio" required v-model="outro_situacao" name="outro_situacao" value="Militar de outra Força" class="custom-control-input" id="outro_militar_outra_forca">
                                    <label class="custom-control-label h6" for="outro_militar_outra_forca">Militar de outra Força
                                    </label>
                                </div>
                                <div class="custom-control custom-radio text-small">
                                    <input type="radio" required v-model="outro_situacao" name="outro_situacao" value="Civil" class="custom-control-input" id="outro_civil">
                                    <label class="custom-control-label h6" for="outro_civil">Civil
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label for="outro_identidade" class="control-label">Identidade</label>
                            <input type="text" id="outro_identidade" v-mask="'##.###.###-#'" class="form-control" v-model="outro_identidade" required>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label for="outro_cpf" class="control-label">CPF</label>
                            <input type="text" id="outro_cpf" v-mask="'###.###.###-##'" class="form-control" v-model="outro_cpf" required>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="outro_endereco" class="control-label">Endereço completo</label>
                            <input type="text" id="outro_endereco" class="form-control" v-model="outro_endereco" required>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="outro_cr_atividade" class="control-label">Nº do CR e Atividade que a arma esta</label>
                            <input type="text" id="outro_cr_atividade" class="form-control" v-model="outro_cr_atividade" required>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="outro_regiao_militar" class="control-label">Região Militar</label>
                            <select required v-model="outro_regiao_militar" id="outro_regiao_militar" class="form-control">
                                <option v-for="i in 12" :value="i" :key="i">{{ i }}ª Região Militar</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row mt-7" v-if="informacoes != null">
                    <div class="col-12 mt-7 mb-5">
                        <p class="lead mb-0">Confirme suas informações</p>
                        <p class="mb-3">Verifique, complete as informações e confira antes de ir para o pagamento:</p>
                        <p class="alert alert-danger py-3 text-center">
                            Se alguma informação <strong>sua</strong> estiver incorreta corrija em sua <a class="font-weight-bold" href="/minhas-informacoes">ficha de informações</a> para que outros documentos não sejam gerados de forma incorreta.
                        </p>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label class="control-label">Nome completo</label>
                            <h3>{{ nome }}</h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label class="control-label">Posto/Profissão/Categoria Funcional</label>
                            <h3>{{ ficha.profissao }}</h3>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label class="control-label">Situação</label>
                            <div class="form-group">
                                <div class="custom-control custom-radio text-small">
                                    <input type="radio" required v-model="minha_situacao" name="minha_situacao" value="Militar do Exército" class="custom-control-input" id="militar_exercito" >
                                    <label class="custom-control-label h6" for="militar_exercito">Militar do Exército
                                    </label>
                                </div>
                                <div class="custom-control custom-radio text-small">
                                    <input type="radio" required v-model="minha_situacao" name="minha_situacao" value="Militar de outra Força" class="custom-control-input" id="militar_outra_forca">
                                    <label class="custom-control-label h6" for="militar_outra_forca">Militar de outra Força
                                    </label>
                                </div>
                                <div class="custom-control custom-radio text-small">
                                    <input type="radio" required v-model="minha_situacao" name="minha_situacao" value="Civil" class="custom-control-input" id="civil">
                                    <label class="custom-control-label h6" for="civil">Civil
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label class="control-label">Identidade</label>
                            <h3>{{ ficha.rg }}</h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label class="control-label">CPF</label>
                            <h3>{{ ficha.cpf }}</h3>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label class="control-label">Endereço completo</label>
                            <h3>{{ endereco.rua }}, {{ endereco.numero }}<span v-if="endereco.complemento">{{ endereco.complemento }}</span>, {{ endereco.bairro }}. {{ endereco.cidade }}/{{ endereco.estado }} - {{ endereco.cep }}</h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label class="control-label">Nº do CR</label>
                            <h3 class="text-wrap">{{ ficha.cr }}</h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label class="control-label">Atividades</label>
                            <input type="text" placeholder="Coloque suas atividades" class="form-control" v-model="minha_atividade" required>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label class="control-label font-weight-bold">A arma a ser transferida destina-se ao acervo de:</label>
                            <input type="text" placeholder="Coloque um dos seus acervos" class="form-control" v-model="meu_acervo" required>
                            <small>Ex: Atirador desportivo, preencha como está em seu Certificado de Registro.</small>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="custom-control custom-checkbox text-small">
                        <input required type="checkbox" name="confirma_transferencia" value="true" class="custom-control-input" id="leu-transferencia">
                        <label class="custom-control-label h6" for="leu-transferencia">Eu {{ nome }}, confirmo que os dados acima estão corretos e estou recebendo a arma acima de {{ outro_nome || '"aguardando preenchimento"' }}.</label>
                    </div>
                </div>
            </span>
            <div class="form-group" v-if="acervos != null && acervos.length > 0">
                <button :disabled="pedindo" class="btn btn-primary" v-html="botao"></button>
            </div>
        </form>
    </span>
</template>

<script>
	export default {
		name: 'Tr001',
		data() {
			return {
				acervos: null,
				enviando: false,

				outro_nome: null,
				outro_profissao: null,
				outro_situacao: null,
				outro_identidade: null,
				outro_cpf: null,
				outro_endereco: null,
				outro_cr_atividade: null,
				outro_regiao_militar: null,

				minha_situacao: null,
				minha_atividade: null,
				meu_acervo: null,
				arma_selecionada: null,
				calibre_de_uso: null,

				nome: 'Usuário',
				ficha: null,
				endereco: null,
				telefone: null,
				informacoes: null,

				documentacao: null,
                currentURL: window.location.pathname,
                botao: '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento',
                pedindo: false,
			}
		},
		mounted() {
			this.getAcervo();
			this.getInfo();
			this.getDocumentacao();
		},
		methods: {
			getAcervo: function () {
				var app = this;
				axios.get('/acervo')
				.then((res) => {
					app.acervos = res.data;
				});
			},
			getInfo: function () {
				var app = this;
				axios.get('/info')
				.then((res) => {
					app.informacoes = res.data;
					app.nome = res.data.name;
					app.telefone = res.data.telefone;
					app.endereco = res.data.endereco;
					app.ficha = res.data.ficha;
				});
			},
			getDocumentacao: function () {
				var app = this;
				axios.get('/documentacao/'+this.currentURL.replace('/contratar/', ''))
				.then((res) => {
					app.documentacao = res.data;
				});
			},
			fazerPedido: function () {
            	this.botao = '<i class="fa fa-spin fa-spinner mr-2"></i>Concluindo pedido e direcionando para pagamento';
				this.pedindo = true;
				if(this.arma_selecionada == null) {
                	this.botao = '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento';
					this.pedindo = false;
					this.$toast.error('Você precisa selecionar uma arma e selecionar se o calibre é restrito ou permitido', '', {
						position: 'center',
						overlay: true,
						timeout: 10000,
					});
				} else {
					this.enviando = true;

					axios.post(this.currentURL, {
						doc_id: this.documentacao.id,
						arma_selecionada: this.arma_selecionada,
						calibre_de_uso: this.calibre_de_uso,
						outro_profissao: this.outro_profissao,
						outro_nome: this.outro_nome,
						outro_identidade: this.outro_identidade,
						outro_cpf: this.outro_cpf,
						outro_situacao: this.outro_situacao,
						outro_endereco: this.outro_endereco,
						outro_cr_atividade: this.outro_cr_atividade,
						outro_regiao_militar: this.outro_regiao_militar,
						minha_atividade: this.minha_atividade,
						minha_situacao: this.minha_situacao,
						meu_acervo: this.meu_acervo,
					})
					.then(res => {
						this.$toast.info("Pedido efetuado, direcionando para página de pagamento.", 'Tudo certo');
						setTimeout(function() {
							window.location.href = '/meu-pedido/'+res.data+'/pagar';
						}, 4000);
					})
					.catch(err => {
						this.enviando = false;
	                	this.botao = '<i class="fa fa-check mr-2"></i>Tente novamente';
						this.pedindo = false;
						this.$toast.error(err.response.data.error, 'Deu errado');
					});
				}
			}
        },
        computed: {
            armaSelecionada() {
                if (this.arma_selecionada != null) {
                    let armas = this.acervos
                    let arma = armas.filter(k => {
                        return k.id == this.arma_selecionada
                    })
                    return arma[0]
                }
                return null
            }
        }
	}
</script>

<style>

</style>
