<template>
    <div>
        <div v-if="enviado == true">
            <div class="text-center">
                <i class="fa fa-check fa-4x text-success"></i>
                <h2 class="text-success">Enviamos sua mensagem!</h2>
                <p class="lead">Lhe responderemos o mais rápido possível.</p>
            </div>
        </div>
        <div v-if="enviado == false">
            <div class="text-center">
                <i class="fa fa-times fa-4x text-danger"></i>
                <h2 class="text-danger">Não conseguimos enviar sua mensagem!</h2>
                <p class="lead">Carregaremos novamente o formulário, por favor tente novamente.</p>
            </div>
        </div>
        <form @submit.prevent="captcha()" method="post" v-if="enviado == null">
            <div class="form-row">
                <div class="col-sm">
                    <div class="form-group">
                        <label for="contato-name">Seu nome completo</label>
                        <input placeholder="Preencha com seu nome" type="text" v-model="nome" class="form-control" id="contato-name"
                            required>
                        <div class="invalid-feedback">
                            Por favor, preencha com seu nome.
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group">
                        <label for="contato-email">Endereço de e-mail</label>
                        <input placeholder="Preencha com seu e-mail" type="email" v-model="email" class="form-control" id="contato-email"
                            required>
                        <div class="invalid-feedback">
                            Por favor, preencha com seu endereço de e-mail.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-12 col-lg-7">
                    <div class="form-group">
                        <label for="contato-assunto" class="w-100">Assunto <span class="float-right text-primary" v-if="assunto_selecionado == 'Outro'" @click="voltar()" style="cursor: pointer;"><i class="fa fa-arrow-left mr-2"></i>voltar</span></label>
                        <select v-if="assunto_selecionado != 'Outro'" required v-model="assunto_selecionado" id="contato-assunto" class="form-control">
                            <option selected disabled hidden :value="null">Escolha uma opção</option>
                            <option value="Dúvida">Tenho uma dúvida</option>
                            <option value="Sugestão">Tenho uma sugestão</option>
                            <option value="Crítica">Tenho uma crítica</option>
                            <option value="Parceria">Quero ser um clube parceiro</option>
                            <option value="Anunciante">Gostaria de anunciar no cacdocs</option>
                            <!-- <option value="Clube">Adicione meu clube a sua lista</option> -->
                            <option value="Despachante">Alterar meus dados de despachante</option>
                            <option value="Instrutor(a)">Alterar meus dados de instrutor(a)</option>
                            <option value="Psicológo(a)">Alterar meus dados de psicólogo(a)</option>
                            <option value="Orçamento">Quero um orçamento de documentação</option>
                            <option value="Exigência">Tenho uma exigência de documento (Exército)</option>
                            <option value="Outro">Outro assunto</option>
                        </select>
                        <input type="text" placeholder="Preencha com o assunto" id="contato-assunto" required v-if="assunto_selecionado == 'Outro'" v-model="assunto" class="form-control">
                        <div class="invalid-feedback">
                            Por favor, escolha um assunto.
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group">
                        <label for="contato-wa" class="w-100">WhatsApp <span class="float-right small text-primary">(opcional)</span></label>
                        <input placeholder="Preencha com seu whatsapp" type="tel" v-mask="['(##) ####-####', '(##) #####-####']" v-model="whatsapp" class="form-control" id="contato-wa">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="contato-mensagem">Mensagem</label>
                <textarea id="contato-mensagem" v-model="mensagem" rows="10" class="form-control"
                    required></textarea>
                <div class="invalid-feedback">
                    Por favor, preencha com sua mensagem.
                </div>
            </div>
            <div class="form-group" v-if="confirmar">
                <vue-recaptcha @verify="enviar" sitekey="6Let4SIUAAAAAOV74azmciDRh7sN_kdd5QtalSK2"></vue-recaptcha>
            </div>
            <div class="form-group">
                <button class="btn btn-primary btn-loading" type="submit" v-html="botao" :disabled="confirmar"></button>
            </div>
        </form>
    </div>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';
    export default {
        name: 'FormularioContato',
        props: {
            auth: Boolean,
        },
        data() {
            return {
                voltar_assuntos: false,
                enviado: null,
                confirmar: false,
                whatsapp: null,
                assunto_selecionado: null,
                assunto: null,
                nome: null,
                email: null,
                mensagem: null,
                botao: '<i class="fa fa-check mr-2"></i>Enviar mensagem',
            }
        },
        mounted() {
            if (this.auth == true)
                this.getInfo();
        },
        methods: {
            getInfo: function () {
                var app = this;
                axios.get('/info')
                .then((res) => {
                    app.nome = res.data.name;
                    app.email = res.data.email;
                });
            },
            voltar: function () {
                this.assunto_selecionado = null;
                this.assunto = null;
            },
            captcha: function () {
                this.confirmar = true;
                this.botao = '<i class="fa fa-check mr-2"></i>Confirme acima';
            },
            enviar: function () {
                this.botao = '<i class="fa fa-spin fa-spinner mr-2"></i>Enviando mensagem';
                if (this.assunto_selecionado != 'Outro')
                    var assunto = this.assunto_selecionado;
                else 
                    var assunto = this.assunto;
                axios.post('/contato', {
                    nome: this.nome,
                    email: this.email,
                    whatsapp: this.whatsapp,
                    assunto: assunto,
                    mensagem: this.mensagem,
                })
                .then(res => {
                    this.enviado = true;
                })
                .catch(err => {
                    this.enviado = false;
                    var app = this;
                    setTimeout(function() {
                        app.enviado = null;
                        app.confirmar = false;
                        app.botao = '<i class="fa fa-check mr-2"></i>Enviar mensagem';
                    }, 5000);
                });
            }
        },
        watch: {
            assunto_selecionado: function (val) {
                if (val == 'Despachante') {
                    this.mensagem = 'Solicito a alteração de minhas informações para as seguintes:\nEndereço comercial completo;\nTelefone para contato;\nE-mail;\nNós confirmaremos a solicitação de alteração via e-mail.';
                } else if (val == 'Psicológo(a)') {
                    this.mensagem = 'Solicito a alteração de minhas informações para as seguintes:\nNúmero de registro;\nData de credenciamento;\nEndereço comercial completo;\nTelefone para contato;\nE-mail;\nNós confirmaremos a solicitação de alteração via e-mail.';
                } else if (val == 'Instrutor(a)') {
                    this.mensagem = 'Solicito a alteração de minhas informações para as seguintes:\nData de credenciamento;\nEndereço comercial completo;\nTelefone para contato;\nE-mail;\nNós confirmaremos a solicitação de alteração via e-mail.';
                // } else if (val == 'Clube') {
                //     this.mensagem = 'Preencha este campo com algumas informações como:\nNome do clube;\nSite;\nEndereço completo;\nE-mail para contato caso não queira utilizar o e-mail pessoal;';
                } else if (val == 'Anunciante') {
                    this.mensagem = 'Antes de entrar em contato você já visitou a página: https://cacdocs.com.br/anuncie ??\nSe já tiver visitado e ainda precise de informações escreva aqui sua mensagem:\nLembre-se de colocar informações como:\nMarca/Empresa;\nProduto(s)/Serviço(s) a ser(em) anunciado(s);\nSite;\nE-mail para contato caso não queira utilizar o e-mail pessoal;';
                } else if (val == 'Parceria') {
                    this.mensagem = 'Antes de entrar em contato você já visitou a página: https://cacdocs.com.br/parceria ??\nSe já tiver visitado e ainda precise de informações escreva aqui sua mensagem:';
                } else if (val == 'Orçamento') {
                    this.mensagem = 'Nos diga qual documentação está necessitando? Já possui algo pronto?\nTalvez você só precise de uma consultoria.\nSe realmente quiser que façamos sua documentação ficaremos felizes em ajudar.';
                } else if (val == 'Exigência') {
                    this.mensagem = 'Sua exigência é de uma documentação contratada no cacdocs? Você pode solicitar ajuda na página: https://cacdocs.com.br/minhas-ajudas\nCaso contrário nos diga o que deu de errado e qual documentação tentou protocolar e tentaremos lhe ajudar.';
                } else {
                    this.mensagem = 'Preencha com sua mensagem';
                }
            }
        },
        components: { VueRecaptcha }
    }
</script>
