<template>
    <div class="card-header bg-white d-flex justify-content-around justify-content-md-between align-items-center flex-wrap" v-if="valores.length > 1">
		<div class="w-100">
			<div class="d-flex justify-content-between align-items-center mb-4">
				<span v-if="!mostrar_grafico">
					<div class="mr-auto h5 mb-0">
						<i class="fa fa-list-ol mr-2"></i> Histórico de valores
					</div>
				</span>
				<span v-else>
					<div class="mr-auto h5 mb-0">
						<i class="fa fa-chart-line mr-2"></i> Gráfico de progressão
					</div>
				</span>
				<button id="pesquisar" class="btn btn-primary btn-sm" v-if="!mostrar_grafico" @click="toggleHistoricoGrafico">
					Exibir gráfico
				</button>
				<button id="pesquisar" class="btn btn-primary btn-sm" v-else @click="toggleHistoricoGrafico">
					Exibir tabela
				</button>
			</div>
			<div class="row" v-if="mostrar_grafico">
				<div class="col-12">
					<grafico :dataSet="valores" :height="180"></grafico>
				</div>
			</div>
			<div class="" v-if="!mostrar_grafico">
				<table class="table table-hover table-stripped">
					<thead class="thead-dark">
						<tr>
							<th>Valor</th>
							<th>Data</th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="index != valores.length - 1" v-for="(valorProduto, index) in valores">
							<td>R$ {{ valorProduto.valor }}</td>
							<td>{{ created(valorProduto.created_at) }}</td>
						</tr>
						<tr class="bg-success text-white" v-if="index == valores.length - 1 && parseInt(valorProduto.valor) < parseInt(valores[valores.length - 2]['valor'])" v-for="(valorProduto, index) in valores">
							<td><strong>R$ {{ valorProduto.valor }}</strong></td>
							<td><strong>{{ created(valorProduto.created_at) }}</strong></td>
						</tr>
						<tr class="bg-primary text-white" v-if="index == valores.length - 1 && parseInt(valorProduto.valor) > parseInt(valores[valores.length - 2]['valor'])" v-for="(valorProduto, index) in valores">
							<td><strong>R$ {{ valorProduto.valor }}</strong></td>
							<td><strong>{{ created(valorProduto.created_at) }}</strong></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import dayjs from 'dayjs';
	import Grafico from "./Grafico";
	export default {
		name: 'HistoricoValores',
		components: {
			Grafico
		},
		props: {
			valores: Array,
		},
		data() {
			return {
				mostrar_grafico: false,
			}
		},
		methods: {
			toggleHistoricoGrafico: function() {
		      this.mostrar_grafico = !this.mostrar_grafico;
		    },
		    created: function(data) {
		    	return dayjs(data).format('DD/MM/YYYY');
		    }
		}
	}
</script>