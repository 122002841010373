<template>
	<div class="my-4 my-sm-5 card card-body">
        <form @submit.prevent="enviarAvaliacao()" method="post" class="row" v-if="!obrigado">
            <h5 class="mb-2 col-12">Nos conte como foi sua experiência utilizando nossa plataforma:</h5>
            <div class="col-12">
                <textarea required v-model="avaliacao" cols="30" rows="6" class="form-control"></textarea>
                <button type="submit" class="btn btn-sm btn-primary mt-2"><i class="fa fa-check mr-2"></i>Enviar avaliação</button>
            </div>
        </form>
        <div class="text-center" v-if="obrigado">
            <h6 class="mb-0"><i class="fa fa-check text-success mr-2"></i>Obrigado por nos avaliar.</h6>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'FormularioAvaliacao',
        data() {
            return {
                obrigado: false,
                enviando: false,
                currentURL: window.location.pathname,

                pedido: null,
                avaliacao: null,
            }
        },
        mounted() {
            this.pedido = this.currentURL.replace('/meu-pedido/', '');
        },
        methods: {
            enviarAvaliacao: function() {
                axios.post('/avaliacao', {
                    pedido: this.pedido,
                    avaliacao: this.avaliacao,
                })
                .then((response) => {
                    // console.log(response);
                    this.obrigado = true;
                    this.$toast.success('Sua avaliação foi enviada com sucesso');
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.error);
                    // console.error(error);
                });
            }
        },
	}
</script>

<style>
	
</style>