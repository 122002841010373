<template>
	<span>
		<div class="row justify-content-center pt-7" v-if="informacoes != null">
			<div class="col-12 col-md-6">
				<h2><span class="d-block">Imagem</span> de exibição atual</h2>
				<div class="d-flex justify-content-center pt-5 noselect">
					<img class="rounded-circle img-thumbnail" :src="'/storage/'+informacoes.avatar" :alt="informacoes.name" v-if="informacoes.avatar != null">
					<img class="rounded-circle img-thumbnail" :src="'https://ui-avatars.com/api/?size=128&name='+informacoes.name+'&rounded=true&color=2c3038&background=518ef8'" :alt="informacoes.name" v-else>
				</div>
				<p class="mb-0 text-left"><strong>Observação:</strong><br><p class="mb-0 text-center">Utilize o movimento de pinça ou o scroll do mouse para dar zoom, arraste a imagem, arraste ou redimensione o quadrado de corte.</p></p>
				<button type="button" id="pick-avatar" :disabled="desabilitar" :class="'mt-5 btn rounded btn-'+botao_cor +' btn-block'" v-html="botao"></button>
			</div>
			<avatar-cropper
			@uploading="handleUploading"
	      	@uploaded="handleUploaded"
	      	@completed="handleCompleted"
	      	@canceled="handleCanceled"
	      	trigger="#pick-avatar"
	      	upload-url="/api/v1/avatar"
	      	:labels="{ submit: 'Enviar', cancel: 'Cancelar' }"
	      	:cropperOptions="{ viewMode:1, dragMode: 'move', aspectRatio: 1, zoomable: true, responsive: true, rotatable: true, minContainerWidth: 300, minContainerHeight: 300, movable: true,}"
	      	/>
		</div>
	</span>
</template>

<script>
	import AvatarCropper from "vue-avatar-cropper";

	export default {
		name: 'FormularioAvatar',
		components: { AvatarCropper },
    	data() {
			return {
				informacoes: null,
				botao: '<i class="fa fa-image mr-3"></i>Clique aqui para alterar imagem',
				botao_cor: 'primary',
				desabilitar: false,
			}
		},
		mounted() {
			this.getInfo();
		},
		methods: {
			getInfo: function () {
				var app = this;
				axios.get('/info')
				.then((res) => {
					app.informacoes = res.data;
				});
			},
			handleUploading(form, xhr) {
				// console.log(form);
		    	this.desabilitar = true;
		      	this.botao = '<i class="fa fa-spin fa-spinner mr-3"></i>Enviando imagem...';
	      		this.botao_cor = 'primary';
		    },
			handleUploaded(response) {
				// console.log(response);
	        	this.desabilitar = false;
		    	this.botao = '<i class="fa fa-check mr-3"></i>Imagem de exibição atualizada!';
	      		this.botao_cor = 'success';
		    	var app = this;
		      	setTimeout(function() {
		      		app.botao = '<i class="fa fa-image mr-3"></i>Clique aqui para alterar imagem';
		      		app.botao_cor = 'primary';
		      	}, 3000);
		    },
		    handleCanceled(response) {
	      		this.botao = '<i class="fa fa-image mr-3"></i>Clique aqui para alterar imagem';
	      		this.botao_cor = 'primary';
		    },
		    handleCompleted(response, form, xhr) {
		    	this.informacoes = response;
	        	$("img.avatar").each( function(){
				    if($(this).attr('src'))
				        $(this).attr("src", '/storage/'+response.avatar)
				});
		    },
		    handlerError(message, type, xhr) {
		    	this.desabilitar = false;
	      		this.botao_cor = 'primary';
		      	this.botao = '<i class="fa fa-refresh mr-3"></i>Algo deu errado, tente novamente!';
		    }
		},
	}
</script>

<style>
	.avatar-cropper-overlay {
		position: absolute !important;
		z-index: 9999999 !important;
		overflow: hidden;
	}
	.avatar-cropper-image-container {
		max-width: 800px;
		height: auto;
		z-index: 99999999 !important; 
	}
	.avatar-cropper-mark {
		position: absolute !important;
		background: rgba(0,0,0,0) !important;
		z-index: 999999 !important;
	}
	.avatar-cropper-container {
		z-index: 99999999 !important; 
	}
	.avatar-cropper-btn {
		border: 1px solid #0f50d2 !important;
	}
</style>