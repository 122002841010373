<template>
	<span>
		<p class="text-right"><span v-html="contador"></span><span class="plus"></span> <span v-html="verbo"></span> este artigo útil</p>
	</span>
</template>

<script>
	export default {
		name: 'ContaVoto',
		data() {
			return {
				contador: 0,
                currentURL: window.location.pathname,
			}
		},
		created() {
			this.$eventBus.$on('MAIS_UM_VOTO_SIM', (data) => {
		    	this.adicionaContador();
		    });
		    this.contaVotos();
		},
		methods: {
			contaVotos: function () {
				var app = this;
				axios.post('/votos', {
                    urlPagina: this.currentURL,
                })
                .then(function (response) {
					app.contador = response.data.votos;
                    // console.log(response);
                })
                .catch(function (error) {
                    // console.error(error);
                });
			},
			adicionaContador: function () {
				this.contador += 1;
			}
		},
		computed: {
			verbo: function() {
				if (this.contador == 0 || this.contador > 1) {
					return 'visitantes acharam';
				} else {
					return 'visitante achou';
				}
			}
		}
	}
</script>