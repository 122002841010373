<template>
	<div class="my-4 my-sm-5 card card-body">
        <div class="d-flex flex-sm-row justify-content-between align-items-center" v-if="!mostrar_comentario && !obrigado">
            <div class="h5 mb-sm-0">Achou este artigo útil?</div>
            <div class="d-flex">
                <button type="button" class="btn btn-sm btn-outline-primary mx-1" @click="votarSim()">Sim</button>
                <button type="button" class="btn btn-sm btn-outline-primary mx-1" @click="mostrar_comentario = true">Não</button>
            </div>
        </div>
        <form @submit.prevent="votarNao()" method="post" class="row" v-if="mostrar_comentario && !obrigado">
            <div class="h5 mb-2 col-12">O que podemos melhorar?</div>
            <div class="col-12">
                <textarea required v-model="sugestao" cols="30" rows="4" class="form-control"></textarea>
                <button type="submit" class="btn btn-sm btn-primary mt-2"><i class="fa fa-check mr-2"></i>Enviar sugestão</button>
            </div>
        </form>
        <div class="text-center" v-if="obrigado">
            <h6 class="mb-0"><i class="fa fa-check text-success mr-2"></i>Obrigado por avaliar.</h6>
        </div>
    </div>
</template>

<script>
    var STORAGE_KEY = 'cacdocs_votos_util';
    var votosStorage = {
        fetch: function () {
            var votos = JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]');
            return votos;
        },
        save: function (votos) {
            localStorage.setItem(STORAGE_KEY, JSON.stringify(votos));
        }
    }
	export default {
		name: 'FormularioVotaUtil',
        data() {
            return {
                mostrar_comentario: false,
                sugestao_enviada: false,
                obrigado: false,
                votos: votosStorage.fetch(),
                currentURL: window.location.pathname,
                votoURL: null,
                voto: null,
                sugestao: null,
                axiosURL: null,
            }
        },
        mounted() {
            if(this.votos.length > 0 && this.votos.includes(this.currentURL)) {
                this.obrigado = true;
            }

            if(this.currentURL.includes('faq/')) {
                this.axiosURL = '/votar/faq';
                this.votoURL = this.currentURL.replace('/faq/', '');
            } else if(this.currentURL.includes('documentacoes/')) {
                this.axiosURL = '/votar/documentacao';
                this.votoURL = this.currentURL.replace('/documentacoes/', '');
            }
        },
        methods: {
            adicionarURL: function (){
                this.votos.push(this.currentURL);
                this.obrigado = true;
            },
            votarSim: function () {
                this.voto = true;
                this.adicionarURL();
                this.enviarVoto();
                this.$eventBus.$emit('MAIS_UM_VOTO_SIM');
            },
            votarNao: function () {
                this.voto = false;
                this.adicionarURL();
                this.enviarVoto();
            },
            enviarVoto: function() {
                axios.post(this.axiosURL, {
                    urlPagina: this.votoURL,
                    voto: this.voto,
                    sugestao: this.sugestao,
                })
                .then(function (response) {
                    // console.log(response);
                })
                .catch(function (error) {
                    // console.error(error);
                });
            }
        },
        watch: {
            votos: {
              handler: function(votos) {
                votosStorage.save(votos);
              }
            }
        },
	}
</script>

<style>
	
</style>