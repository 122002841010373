<script>
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  props: {
    dataSet: Array
  },
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart({
      labels: this.datas_dataset,
      datasets : [
        {
          label: 'Valores',
          backgroundColor: '#f87979',
          data: this.valores_dataset,
        }
      ]
    }, {
        legend: {
          display: false,
        },
      })
  },
  computed: {
    valores_dataset(){
      var array = this.dataSet;
      var resultado = [];
      array.forEach(obj => {
        resultado.push(parseFloat(obj.valor.replace('.', '').replace(',','.')))
      })
      return resultado;
    },
    datas_dataset(){
      var array = this.dataSet;
      var datas = [];
      array.forEach(obj => {
        datas.push(obj.created_at.split(' ')[0].split('-')[2]+'/'+obj.created_at.split(' ')[0].split('-')[1]+'/'+obj.created_at.split(' ')[0].split('-')[0])
      })
      return datas;
    }
  }
};
</script>
