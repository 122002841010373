<template>
	<div class="col-12 col-lg-12 mt-8">
		<div class="text-center text-lg-left">
			<h1 class="pb-3 mb-0">Temos planos para te oferecer!</h1>
			<p class="lead mb-8">Ofereça aos seus associados cupons de desconto para geração de documentações.</p>
			<h3 class="text-center pb-2">
				<div class="d-flex justify-content-center align-items-center">
					<div class="custom-control custom-switch text-center">
	                    <input type="checkbox" class="custom-control-input" id="switch-2" v-model="tipo_pagamento" >
						<label class="custom-control-label" for="switch-2"><span v-if="tipo_pagamento == false">mensal</span><span v-if="tipo_pagamento == true">anual</span></label>
	                </div>
				</div>
			</h3>
			<div class="mt-4 row justify-content-center align-items-center noselect">
				<div class="col-12 col-lg-4 mt-3 mt-lg-0">
					<div class="card border rounded position-relative p-5 overflow-hidden hover-box-shadow">
						<h5 class="text-uppercase text-muted text-left" style="border-bottom: 2px solid;">gratuito</h5>
						<transition name="fade" mode="out-in">
							<div v-if="pagamento_mensal == true">
								<h1 class="text-center"><span class="small font-weight-light mr-3">R$</span><span class="display-4 font-weight-bold">0<span class="small h6">,00</span></span></h1>
								<h5 class="text-center">mensalmente</h5>
							</div>
							<div v-if="pagamento_mensal == false">
								<h1 class="text-center"><span class="small font-weight-light mr-3">R$</span><span class="display-4 font-weight-bold">0<span class="small h6">,00</span></span></h1>
								<h5 class="text-center">anualmente</h5>
							</div>
						</transition>
						<h1 class="text-center"><span class="display-4 font-weight-bold">10</span> <span class="small font-weight-light">cupons</span></h1>
						<h5 class="text-center">de <span class="display-4 font-weight-bold">10</span> <span class="small font-weight-light">%</span></h5>
						<img src="/img/logo.svg" class="bg-logo" style="width: 200px;z-index:1;" />
					</div>
				</div>
				<div class="col-12 col-lg-4 mt-5 mt-lg-0 position-relative">
					<div v-if="tipo_pagamento == false" class="card border rounded position-relative p-5 overflow-hidden hover-box-shadow text-primary" style="border: 10px #478AD2 solid !important;">
						<div class="cr cr-top cr-right cr-blue text-uppercase">destaque</div>
						<h5 class="text-uppercase text-left" style="border-bottom: 2px #478AD2 solid;">inicial</h5>
						<transition name="fade" mode="out-in">
							<div v-if="pagamento_mensal == true">
								<h1 class="text-center"><span class="small font-weight-light mr-3">R$</span><span class="display-4 font-weight-bold">49<span class="small h6">,00</span></span></h1>
								<h5 class="text-center">mensalmente</h5>
								<h1 class="text-center"><span class="display-4 font-weight-bold">65</span> <span class="small font-weight-light">cupons</span></h1>
								<h5 class="text-center">de <span class="display-4 font-weight-bold">10</span> <span class="small font-weight-light">%</span></h5>
							</div>
							<div v-if="pagamento_mensal == false">
								<h1 class="text-center"><span class="small font-weight-light mr-3">R$</span><span class="display-4 font-weight-bold">499<span class="small h6">,00</span></span></h1>
								<h5 class="text-center">anualmente</h5>
								<h1 class="text-center"><span class="display-4 font-weight-bold">500</span> <span class="small font-weight-light">cupons</span></h1>
								<h5 class="text-center">de <span class="display-4 font-weight-bold">12</span> <span class="small font-weight-light">%</span></h5>
							</div>
						</transition>
						<img src="/img/logo.svg" class="bg-logo" style="width: 200px;z-index:1;" />
					</div>
					<div v-else class="card border rounded position-relative p-5 overflow-hidden hover-box-shadow">
						<h5 class="text-uppercase text-left" style="border-bottom: 2px solid;">inicial</h5>
						<transition name="fade" mode="out-in">
							<div v-if="pagamento_mensal == true">
								<h1 class="text-center"><span class="small font-weight-light mr-3">R$</span><span class="display-4 font-weight-bold">49<span class="small h6">,00</span></span></h1>
								<h5 class="text-center">mensalmente</h5>
								<h1 class="text-center"><span class="display-4 font-weight-bold">65</span> <span class="small font-weight-light">cupons</span></h1>
								<h5 class="text-center">de <span class="display-4 font-weight-bold">10</span> <span class="small font-weight-light">%</span></h5>
							</div>
							<div v-if="pagamento_mensal == false">
								<h1 class="text-center"><span class="small font-weight-light mr-3">R$</span><span class="display-4 font-weight-bold">499<span class="small h6">,00</span></span></h1>
								<h5 class="text-center">anualmente</h5>
								<h1 class="text-center"><span class="display-4 font-weight-bold">500</span> <span class="small font-weight-light">cupons</span></h1>
								<h5 class="text-center">de <span class="display-4 font-weight-bold">12</span> <span class="small font-weight-light">%</span></h5>
							</div>
						</transition>
						<img src="/img/logo.svg" class="bg-logo" style="width: 200px;z-index:1;" />
					</div>
				</div>
				<div class="col-12 col-lg-4 mt-5 mt-lg-0">
					<div v-if="tipo_pagamento == true" class="card border rounded position-relative p-5 overflow-hidden hover-box-shadow text-primary" style="border: 10px #478AD2 solid !important;">
						<div class="cr cr-top cr-right cr-blue text-uppercase">destaque</div>
						<h5 class="text-uppercase text-left" style="border-bottom: 2px #478AD2 solid;">intermediário</h5>
						<transition name="fade" mode="out-in">
							<div v-if="pagamento_mensal == true">
								<h1 class="text-center"><span class="small font-weight-light mr-3">R$</span><span class="display-4 font-weight-bold">119<span class="small h6">,00</span></span></h1>
								<h5 class="text-center">mensalmente</h5>
								<h1 class="text-center"><span class="display-4 font-weight-bold">150</span> <span class="small font-weight-light">cupons</span></h1>
								<h5 class="text-center">de <span class="display-4 font-weight-bold">15</span> <span class="small font-weight-light">%</span></h5>
							</div>
							<div v-if="pagamento_mensal == false">
								<h1 class="text-center"><span class="small font-weight-light mr-3">R$</span><span class="display-4 font-weight-bold">999<span class="small h6">,00</span></span></h1>
								<h5 class="text-center">anualmente</h5>
								<h1 class="text-center"><span class="display-4 font-weight-bold">1000</span> <span class="small font-weight-light">cupons</span></h1>
								<h5 class="text-center">de <span class="display-4 font-weight-bold">20</span> <span class="small font-weight-light">%</span></h5>
							</div>
						</transition>
						<img src="/img/logo.svg" class="bg-logo" style="width: 200px;z-index:1;" />
					</div>
					<div v-else class="card border rounded position-relative p-5 overflow-hidden hover-box-shadow">
						<h5 class="text-uppercase text-muted text-left" style="border-bottom: 2px solid;">intermediário</h5>
						<transition name="fade" mode="out-in">
							<div v-if="pagamento_mensal == true">
								<h1 class="text-center"><span class="small font-weight-light mr-3">R$</span><span class="display-4 font-weight-bold">119<span class="small h6">,00</span></span></h1>
								<h5 class="text-center">mensalmente</h5>
								<h1 class="text-center"><span class="display-4 font-weight-bold">150</span> <span class="small font-weight-light">cupons</span></h1>
								<h5 class="text-center">de <span class="display-4 font-weight-bold">15</span> <span class="small font-weight-light">%</span></h5>
							</div>
							<div v-if="pagamento_mensal == false">
								<h1 class="text-center"><span class="small font-weight-light mr-3">R$</span><span class="display-4 font-weight-bold">999<span class="small h6">,00</span></span></h1>
								<h5 class="text-center">anualmente</h5>
								<h1 class="text-center"><span class="display-4 font-weight-bold">1000</span> <span class="small font-weight-light">cupons</span></h1>
								<h5 class="text-center">de <span class="display-4 font-weight-bold">20</span> <span class="small font-weight-light">%</span></h5>
							</div>
						</transition>
						<img src="/img/logo.svg" class="bg-logo" style="width: 200px;z-index:1;" />
					</div>
				</div>
				<div class="col-12 mt-3 mt-lg-5 d-none">
					<div class="card border rounded position-relative p-5 overflow-hidden hover-box-shadow">
						<h5 class="text-uppercase text-muted text-left" style="border-bottom: 2px solid;">avançado</h5>
						<div class="row align-items-center mt-5">
							<div class="col-12 col-lg-4">
								<transition name="fade" mode="out-in">
									<div v-if="pagamento_mensal == true">
										<h1 class="text-center"><span class="small font-weight-light mr-3">R$</span><span class="display-4 font-weight-bold">xxx</span></h1>
										<h5 class="text-center">mensalmente</h5>
									</div>
									<div v-if="pagamento_mensal == false">
										<h1 class="text-center"><span class="small font-weight-light mr-3">R$</span><span class="display-4 font-weight-bold">xxxx</span></h1>
										<h5 class="text-center">anualmente</h5>
									</div>
								</transition>
							</div>
							<div class="col-12 col-lg-4">
								<transition name="fade" mode="out-in">
									<div v-if="pagamento_mensal == true">
										<h1 class="text-center"><span class="display-4 font-weight-bold">xxx</span> <span class="small font-weight-light">cupons</span></h1>
									</div>
									<div v-if="pagamento_mensal == false">
										<h1 class="text-center"><span class="display-4 font-weight-bold">xxxx</span> <span class="small font-weight-light">cupons</span></h1>
									</div>
								</transition>
							</div>
							<div class="col-12 col-lg-4">
								<h5 class="text-center">de <span class="display-4 font-weight-bold">xx</span> <span class="small font-weight-light">%</span></h5>
							</div>
							<div class="col-12 text-center">
								<h6 class="text-uppercase font-weight-light text-primary">vamos conversar!</h6>
							</div>
							<img src="/img/logo.svg" class="bg-logo" style="width: 300px;z-index:1;" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ListaPlanos',
		data() {
			return {
				planos: [],
				tipo_pagamento: false,
				pagamento_mensal: true,
				pagamento_anual: false,
			}
		},
		mounted() {
			this.getPlanos();
		},
		methods: {
			getPlanos: function() {
				axios.get('/planos')
				.then((res) => {
					this.clubes = res.data;
				})
				.catch((err) => {
					this.$toast.error(err.response.data.error);
				});
			}
		},
		watch: {
			tipo_pagamento (val) {
				if (val == false) {
					this.pagamento_anual = false;
					this.pagamento_mensal = true;
				} else {
					this.pagamento_anual = true;
					this.pagamento_mensal = false;
				}
			},
		}
	}
</script>

<style>
	h1, h5, h6, span {
		z-index: 99 !important;
	}
	.bg-logo {
		transform: rotate(35deg);filter: opacity(0.2);width: 516px;right: 0;position: absolute;top: 5%;z-index:-1;
	}
	@media screen and (max-width: 600px) {
		.bg-logo {
			filter: opacity(0.1) !important;
			width: 500px;
			right: -30%;
		}
	}
</style>