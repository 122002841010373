<template>
<span>
    <div class="accordion accordion-connected shadow-5" id="accordion-certidoes" v-if="pedido != null">
        <!-- Certidão Negativa Federal -->
        <div class="card" v-if="pedido.geracao_manual != true || forcaExibicao == true">
            <h6 class="card-title bg-dark mb-0">
            <a class="d-flex align-items-center p-5 collapsed" data-toggle="collapse" href="#collapse-m-1"
                aria-expanded="false">
                <span class="text-white w-100 lead">Certidão negativa <u>federal</u> de antecedente criminal <span class="float-right badge badge-light text-danger" v-if="ficha.confirmar_certidoes"><i class="fa fa-times mr-2"></i>não é necessário, você informou ser isento</span></span>
            </a>
            </h6>
            <div id="collapse-m-1" class="collapse" data-parent="#accordion-certidoes" style="">
                <div class="card-body">
                    <div class="row no-gutters">
                        <div class="col-12">
                            <p>
                                <li>Primeiro passo, acesse o site da Justiça Federal do estado do {{ pedido.endereco.estado }} </li>
                                <span v-if="pedido.endereco.estado == 'Acre' || pedido.endereco.estado == 'Amapá' || pedido.endereco.estado == 'Amazonas' || pedido.endereco.estado == 'Bahia' || pedido.endereco.estado == 'Goiás' || pedido.endereco.estado == 'Maranhão' || pedido.endereco.estado == 'Mato Grosso' || pedido.endereco.estado == 'Minas Gerais' || pedido.endereco.estado == 'Pará' || pedido.endereco.estado == 'Piauí' || pedido.endereco.estado == 'Rondônia' || pedido.endereco.estado == 'Roraima' || pedido.endereco.estado == 'Tocantins'">
                                    <a href="http://portal.trf1.jus.br/Servicos/Certidao/" target="_blank" class="btn btn-xs btn-block btn-primary"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                                </span>
                                <span v-else-if="pedido.endereco.estado == 'Espírito Santo' || pedido.endereco.estado == 'Rio de Janeiro'">
                                    <a href="http://procweb.jfrj.jus.br/certidao/emissao_cert.asp" target="_blank" class="btn btn-xs btn-block btn-primary"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                                </span>
                                <span v-else-if="pedido.endereco.estado == 'São Paulo' || pedido.endereco.estado == 'Mato Grosso do Sul'">
                                    <a href="http://web.trf3.jus.br/certidao/Certidao/Solicitar" target="_blank" class="btn btn-xs btn-block btn-primary"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                                </span>
                                <span v-else-if="pedido.endereco.estado == 'Rio Grande do Sul' || pedido.endereco.estado == 'Paraná' || pedido.endereco.estado == 'Santa Catarina'">
                                    <a href="https://www2.trf4.jus.br/trf4/processos/certidao/index.php" target="_blank" class="btn btn-xs btn-block btn-primary"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                                </span>
                                <span v-else-if="pedido.endereco.estado == 'Ceará' || pedido.endereco.estado == 'Alagoas' || pedido.endereco.estado == 'Sergipe' || pedido.endereco.estado == 'Rio Grande do Norte' || pedido.endereco.estado == 'Paraíba' || pedido.endereco.estado == 'Pernambuco'">
                                    <a href="https://www4.trf5.jus.br/certidoes/paginas/principal.faces;jsessionid=391E2D13FC52DD02C7F8E213FFE37137" target="_blank" class="btn btn-xs btn-block btn-primary"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                                </span>
                                <li>Segundo passo, preencha os campos:</li>
                                <div v-if="pedido.endereco.estado == 'Espírito Santo' || pedido.endereco.estado == 'Rio de Janeiro'">
                                    <p><strong>CPF Requisitante:</strong> <span class="h2 ml-4 d-block d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                                    <p><strong>CPF/CNPJ da certidão:</strong> <span class="h2 ml-4 d-block d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                                    Clique em <a class="btn font-weight-bold" style="border-top: #DFDFDF 1px solid;
                                        border-bottom: #172623 1px solid;
                                        border-left: #DFDFDF 1px solid;
                                        border-right: #172623 1px solid;
                                        color: black;
                                        font-family: bold 12px arial;
                                        background-color: #C0C0C0;
                                    border-radius:0px;cursor: default !important;padding:2px 8px;">Emitir Certidão</a>
                                </div>
                                <div v-else-if="pedido.endereco.estado == 'São Paulo' || pedido.endereco.estado == 'Mato Grosso do Sul'">
                                    <p><strong>Tipo de Certidão:</strong> <span class="h2 ml-4" style="">Certidão de Distribuição</span></p>
                                    <p v-if="pedido.estado == 'São Paulo'"><strong>Abrangência da certidão:</strong> <span class="h4 ml-4" style="">Justiça Federal de Primeiro Grau em São Paulo</span></p>
                                    <p v-if="pedido.estado == 'Mato Grosso do Sul'"><strong>Abrangência da certidão:</strong> <span class="h4 ml-4" style="">Justiça Federal de Primeiro Grau em Mato Grosso do Sul</span></p>
                                    <p><strong>Nome completo:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                                    <p><strong>Tipo de documento:</strong> <span class="h2 ml-4" style="">CPF</span></p>
                                    <p><strong>Número do CPF:</strong> <span class="h2 ml-4" style=" d-blockletter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                                    <p><strong>Digite o código acima:</strong> <span class="h4 ml-4" style="">Digite o código exibido</span></p>
                                    Clique em <a class="btn bg-light" style="    border-radius: 0px;
                                        font: 12px Verdana, Arial, Helvetica, sans-serif;
                                        cursor: default;
                                        border: 1px solid #C0BBB4;
                                        line-height: 1.5em;
                                        padding: 4px;
                                    vertical-align: middle;">Solicitar Certidão</a>
                                </div>
                                <div v-else-if="pedido.endereco.estado == 'Rio Grande do Sul' || pedido.endereco.estado == 'Paraná' || pedido.endereco.estado == 'Santa Catarina'">
                                    <p><strong>Número do CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                                    <p><strong>Nome completo:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                                    <p><strong>Tipo 1.Fins Gerais 1º Grau:</strong> <span class="h2 ml-4" style="">Criminal</span></p>
                                    Clique em <a class="btn bg-light" style="    border-radius: 0px;
                                        font: 12px Verdana, Arial, Helvetica, sans-serif;
                                        cursor: default;
                                        border: 1px solid #000;
                                        padding: 2px !important;
                                    vertical-align: middle;">Emitir Certidão Negativa</a>
                                </div>
                                <div v-else-if="pedido.endereco.estado == 'Ceará' || pedido.endereco.estado == 'Alagoas' || pedido.endereco.estado == 'Sergipe' || pedido.endereco.estado == 'Rio Grande do Norte' || pedido.endereco.estado == 'Paraíba' || pedido.endereco.estado == 'Pernambuco'">
                                    <p><strong>Nome completo:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                                    <p><strong>CPF ou CNPJ:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                                    <p><strong>Digite o código acima:</strong> <span class="h4 ml-4" style="">Digite o código exibido</span></p>
                                    Clique em <a class="btn bg-light" style="    border-radius: 0px;
                                        font: 12px Verdana, Arial, Helvetica, sans-serif;
                                        cursor: default;
                                        border: 1px solid #000;
                                        padding: 2px !important;
                                    vertical-align: middle;">Solicitar Certidão</a>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Certidão Estadual -->
        <div class="card" v-if="pedido.geracao_manual != true || forcaExibicao == true">
            <h6 class="card-title bg-dark mb-0">
            <a class="d-flex align-items-center p-5 collapsed" data-toggle="collapse" href="#collapse-es-4"
                aria-expanded="false">
                <span class="text-white lead w-100">Certidão negativa <u>estadual</u> de antecedente criminal  <span class="float-right badge badge-light text-danger" v-if="ficha.confirmar_certidoes"><i class="fa fa-times mr-2"></i>não é necessário, você informou ser isento</span></span>
            </a>
            </h6>
            <div id="collapse-es-4" class="collapse" data-parent="#accordion-certidoes" style="">
                <div class="card-body">
                    <div class="">
                        <div class="row" v-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'AC'">
                            <div class="col-12">
                                <li>Primeiro passo, acesse o site abaixo </li>
                                <a class="btn btn-xs btn-block btn-primary text-light" href="https://esaj.tjac.jus.br/sco/abrirCadastro.do" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                                <p><strong>Comarca:</strong> <span class="h2 ml-4 d-block" style="">{{ pedido.endereco.cidade }}</span></p>
                                <p><strong>Modelo:</strong> <span class="h2 ml-4 d-block">Ação Criminal</span></p>
                                <p><strong>E-mail:</strong> <span class="h2 ml-4 d-block">{{ user.email }}</span></p>
                                <p><strong>Código de segurança:</strong> <span class="h4 ml-4" style="">Marque a opção "Não sou um robô"</span></p>
                                <p><strong>Marque a opção:</strong> "Confirmo que as informações acima estão corretamente preenchidas"</p>
                                Clique em <button style="background-color: rgb(115,211,212);
                                border: 1px solid rgb(84,179,178);
                                color: #000000;">Enviar</button>
                            </p>
                        </div>
                    </div>
                    <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'AL'">
                        <div class="col-12">
                            <li>Primeiro passo, acesse o site abaixo </li>
                            <a class="btn btn-xs btn-block btn-primary text-light" href="http://www.jfal.gov.br/certidaoInter/emissaoCertidao.aspx" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                            <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                            <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                            <p><strong>Natureza:</strong> <span class="h2 ml-4 d-block">Criminal</span></p>
                            <p><strong>Digite o que você vê na imagem :</strong> <span class="h4 ml-4" style="">Preencha com o que está na imagem</span></p>
                            Clique em <button style="BORDER-TOP: #DFDFDF 1px solid !important;
                            BORDER-BOTTOM: #172623 1px solid !important;
                            BORDER-LEFT: #DFDFDF 1px solid !important;
                            BORDER-RIGHT: #172623 1px solid !important;
                            COLOR: black !important;
                            FONT: bold 12px arial;
                            BACKGROUND-COLOR: #eae2b3 !important;">Emitir</button>
                        </p>
                    </div>
                </div>
                <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'AP'">
                    <div class="col-12">
                        <li>Primeiro passo, acesse o site abaixo </li>
                        <a class="btn btn-xs btn-block btn-primary text-light" href="http://tucujuris.tjap.jus.br/tucujuris/pages/certidao-publica/certidao-publica.html" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                        <p><strong>Tipo de pessoa:</strong> <span class="h2 ml-4 d-block" style="">Física</span></p>
                        <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                        <p><strong>Sexo:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.sexo }}</span></p>
                        <p><strong>Data de nascimento:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.data_nascimento) }}</span></p>
                        <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                        <p><strong>Nome do pai:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_pai }}</span></p>
                        <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                        <p><strong>Número da identidade:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg }}</span></p>
                        <p><strong>UF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg_orgao.toUpperCase().split('/')[1] }}</span></p>
                        <p><strong>E-mail:</strong> <span class="h2 ml-4 d-block">{{ user.email }}</span></p>
                        <p><strong>Tipo da certidão:</strong> <span class="h2 ml-4 d-block">Criminal</span></p>
                        <p><strong>Digite o que você vê na imagem :</strong> <span class="h4 ml-4" style="">Preencha com o que está na imagem</span></p>
                        Clique em <button style="padding: 6px 12px;color: #fff;
                        background-color: #337ab7;
                        border-color: #2e6da4;">Enviar requisição</button>
                    </p>
                </div>
            </div>
            <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'AM'">
                <div class="col-12">
                    <li>Primeiro passo, acesse o site abaixo </li>
                    <a class="btn btn-xs btn-block btn-primary text-light" href="https://consultasaj.tjam.jus.br/sco/abrirCadastro.do" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                    <p><strong>Modelo:</strong> <span class="h2 ml-4 d-block">Ação Criminal</span></p>
                    <p><strong>E-mail:</strong> <span class="h2 ml-4 d-block">{{ user.email }}</span></p>
                    <p><strong>Código de segurança:</strong> <span class="h4 ml-4" style="">Marque a opção "Não sou um robô"</span></p>
                    <p><strong>Marque a opção:</strong> "Confirmo que as informações acima estão corretamente preenchidas"</p>
                    Clique em <button style="background-color: rgb(115,211,212);
                    border: 1px solid rgb(84,179,178);
                    color: #000000;">Enviar</button>
                </p>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'BA'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://www.ba.gov.br/antecedentes/solicitar_atestado.asp" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>Número da identidade:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg }}</span></p>
                <p><strong>Data de nascimento:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.data_nascimento) }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                <p><strong>Nome do pai:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_pai }}</span></p>
                <p><strong>Digite o que você vê na imagem :</strong> <span class="h4 ml-4" style="">Preencha com o que está na imagem</span></p>
                Clique em <button style="">Pesquisar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'CE'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://sistemas.sspds.ce.gov.br/AtestadoAntecedentes/" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>RG:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg }}</span></p>
                <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>Data de nascimento:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.data_nascimento) }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                Clique em <button style="">Pesquisar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'DF'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="https://procart.tjdft.jus.br/sistjinternet/sistj?visaoId=tjdf.sistj.internet.certidao.apresentacao.VisaoGerarCertidao" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Tipo de pessoa:</strong> <span class="h2 ml-4 d-block" style="">Física</span></p>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                <p><strong>Código de segurança:</strong> <span class="h4 ml-4" style="">Marque a opção "Não sou um robô"</span></p>
                Clique em <button style="">Consultar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'ES'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="https://sistemas.tjes.jus.br/certidaonegativa/sistemas/certidao/CERTIDAOPESQUISA.cfm" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Instância:</strong> <span class="h2 ml-4 d-block" style="">1ª instância</span></p>
                <p><strong>Natureza da Certidão:</strong> <span class="h2 ml-4 d-block" style="">Criminal</span></p>
                <p><strong>Tipo:</strong> <span class="h2 ml-4 d-block" style="">Pessoa física</span></p>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                <p><strong>Nome completo:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                Clique em <button class="" style="padding:4px 19px;border-radius:10px; border: 1px solid #77d5f7;
                background: #0078ae url(images/ui-bg_glass_45_0078ae_1x400.png) 50% 50% repeat-x;
                font-weight: normal;
                color: #ffffff;">Solicitar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'GO'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="https://projudi.tjgo.jus.br/CertidaoNegativaPositivaPublica?PaginaAtual=1&TipoArea=2&InteressePessoal=S" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                <p><strong>Data de nascimento:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.data_nascimento) }}</span></p>
                <p><strong>Território:</strong> <span class="h2 ml-4 d-block" style="">Estadual</span></p>
                Clique em <button class="" style="border: 1px solid #2b69aa;
                cursor: pointer;
                font-size: 13px !important;
                background-color: #2b69aa;
                border-radius: 3px;
                color: #fff;
                height: 30px;
                font-weight: bold;
                /* float: left; */">Gerar certidão</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'MA'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://jurisconsult.tjma.jus.br/#/certidao-generate-state-certificate-form" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Instância:</strong> <span class="h2 ml-4 d-block">Primeiro grau</span></p>
                <p><strong>Natureza:</strong> <span class="h2 ml-4 d-block">Ações penais</span></p>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                <p><strong>Nome do pai:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_pai }}</span></p>
                Clique em <button class="" style="color: #fff;
                background-color: #4b77be;border-radius: 2px;
                overflow: hidden;
                font-weight: 500;
                padding: 3px 20px;
                text-transform: uppercase;">Solicitar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'MT'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://cidadao.tjmt.jus.br/servicos/certidaonegativa/EmitirCertidao.aspx" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Tipo da Certidão:</strong> <span class="h2 ml-4 d-block">Criminal</span></p>
                <p><strong>Nome completo:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                Clique em <button class="btn" style="    background-color: #036;
                color: #fff;
                border: none;
                padding: 4px 6px;">Emitir certidão</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'MG'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://rupe.tjmg.jus.br/rupe/justica/publico/certidoes/criarSolicitacaoCertidao.rupe?solicitacaoPublica=true" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Instância:</strong> <span class="h2 ml-4 d-block">Primeiro grau</span></p>
                <p><strong>Natureza:</strong> <span class="h2 ml-4 d-block">Ações penais</span></p>
                <p><strong>Tipo:</strong> <span class="h2 ml-4 d-block" style="">Normal</span></p>
                <p><strong>Comarca:</strong> <span class="h2 ml-4 d-block" style="">{{ pedido.endereco.cidade }}</span></p>
                <p><strong>Pessoa:</strong> <span class="h2 ml-4 d-block" style="">Física</span></p>
                <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p>Preencha as informações de solicitante</p>
                <p><strong>Código de Verificação:</strong> Preencha os algarismos exibidos.</p>
                Clique em <button class="px-6">Salvar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'PA'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="https://consultas.tjpa.jus.br/certidao/pages/pesquisaGeralCentralCertidao.action" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Requerente:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                <p><strong>Endereco:</strong> <span class="h2 ml-4 d-block" style="">{{ pedido.endereco.rua }}, {{ pedido.endereco.numero }}<span v-if="pedido.endereco.complemento"> - {{ pedido.endereco.complemento }}</span>. {{ pedido.endereco.bairro }} - {{ pedido.endereco.cidade }}/{{ pedido.endereco.estado }}. CEP {{ pedido.endereco.cep }}</span></p>
                <p><strong>Documento:</strong> CPF</p>
                <p><strong>Número:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                <p>Digite os símbolos da imagem</p>
                Clique em <button class="px-4 py-1" style="color: #fff;
                background-color: #0065ff;
                background-image: linear-gradient(to bottom, #08f, #0032ff);
                border-color: #0028cc #0028cc #0022b2;">Consulta</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'PB'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="https://app.tjpb.jus.br/certo/paginas/publico/solicitarCertidao.jsf" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Pessoa:</strong> <span class="h2 ml-4 d-block" style="">Física</span></p>
                <p><strong>Instância:</strong> <span class="h2 ml-4 d-block" style="">1º Grau</span></p>
                <p><strong>Tipo de Certidão:</strong> <span class="h2 ml-4 d-block" style="">Criminal</span></p>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>Data de nascimento:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.data_nascimento) }}</span></p>
                <p><strong>Sexo:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.sexo }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                <p><strong>Tipo doc:</strong> <span class="h2 ml-4 d-block" style="">Registro Geral</span></p>
                <p><strong>Número:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg }}</span></p>
                <p><strong>UF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg_orgao.toUpperCase().split('/')[1] }}</span></p>
                <p><strong>CEP:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.cep }}</span></p>
                <p><strong>Estado:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.estado }}</span></p>
                <p><strong>Cidade:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.cidade }}</span></p>
                <p><strong>Bairro:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.bairro }}</span></p>
                <p><strong>Logradouro:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.rua }}</span></p>
                <p><strong>Número:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.numero }}</span></p>
                <p v-if="pedido.endereco.complemento"><strong>Complemento:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.complemento }}</span></p>
                <p><strong>E-mail:</strong> <span class="h2 ml-4 d-block">{{ user.email }}</span></p>
                Clique em <button class="btn py-2 px-4 font-weight-bold" style="background-repeat: repeat-x;
                border-color: #135138;
                color: #FFF!important;
                margin-right: 5px!important;background-image: linear-gradient(to bottom, #218b5f 0%, #155a3d 100%);">Enviar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'PR'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="https://esaj.tjsc.jus.br/sco/abrirCadastro.do" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Comarca:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.cidade }}</span></p>
                <p><strong>Modelo:</strong> <span class="h2 ml-4 d-block" style="">Certidão Criminal</span></p>
                <p><strong>E-mail:</strong> <span class="h2 ml-4 d-block">{{ user.email }}</span></p>
                <p><strong>Confirme:</strong> Não sou um robô</p>
                Clique no final da página no canto direito em <button class="py-1 px-5">Enviar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'PE'">
            <div class="col-12">
                <div class="alert alert-info py-4">
                    É muito comum dar um erro:  "Sua conexão não é particular" ou algo parecido. <span class="d-block"><strong>Exemplo:</strong> ao acessar o site do Tesouro e essa mensagem aparecer, clique na opção <strong>Avançado</strong> e escolha a opção <strong>Ir para consulta.tesouro.fazenda.gov.br (não seguro)</strong>.</span>
                    <p class="text-right"><i class="fa fa-info-circle mr-2"></i> explicamos no FAQ o motivo disso ocorrer</p>
                </div>
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="https://www.tjpe.jus.br/antecedentescriminaiscliente/xhtml/main.xhtml" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p>Clique em Emitir Certidão de Antecedentes Criminais</p>
                <p><strong>Pessoa:</strong> <span class="h2 ml-4 d-block" style="">Física</span></p>
                <p>Clique em <button>Prosseguir</button></p>
                <p><strong>Nome Completo:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                <p><strong>Número da identidade:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg }}</span></p>
                <p><strong>Órgão emissor:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg_orgao.split('/')[0] }}</span></p>
                <p><strong>UF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg_orgao.toUpperCase().split('/')[1] }}</span></p>
                <p><strong>Data Emissão:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.rg_emissao) }}</span></p>
                <p><strong>Data de nascimento:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.data_nascimento) }}</span></p>
                <p><strong>Nascionalidade:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.nacionalidade }}</span></p>
                <p><strong>Estado civil:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.estado_civil }}</span></p>
                <p><strong>CEP:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.cep }}</span></p>
                <p><strong>Logradouro:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.rua }}</span></p>
                <p><strong>Número:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.numero }}</span></p>
                <p v-if="pedido.endereco.complemento"><strong>Complemento:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.complemento }}</span></p>
                <p><strong>Bairro:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.bairro }}</span></p>
                <p><strong>Estado:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.estado }}</span></p>
                <p><strong>Cidade:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.cidade }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                <p><strong>Nome do pai:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_pai }}</span></p>
                <p>Informe o que está escrito na imagem abaixo</p>
                Clique em <button>Solicitar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'PI'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://www.tjpi.jus.br/themisconsulta/certidao" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Tipo de certidão:</strong> <span class="h2 ml-4 d-block" style="">Negativa criminal e Auditoria militar</span></p>
                <p><strong>Pessoa:</strong> <span class="h2 ml-4 d-block" style="">Física</span></p>
                <p><strong>Nome Completo:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                <p><strong>Número da identidade:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg }}</span></p>
                <p><strong>Órgão emissor:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg_orgao.split('/')[0] }}</span></p>
                <p><strong>Estado civil:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.estado_civil }}</span></p>
                <p><strong>Nome do pai:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_pai }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                <p><strong>CEP:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.cep }}</span></p>
                <p><strong>Endereço do requerente:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.rua }}, {{ pedido.endereco.numero }}</span><span v-if="pedido.endereco.complemento">{{ pedido.endereco.complemento }}</span></p>
                <p><strong>Bairro:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.bairro }}</span></p>
                <p><strong>Estado:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.estado }}</span></p>
                <p><strong>Cidade:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.cidade }}</span></p>
                <p>Digite os caracteres da imagem</p>
                Clique em <button style="    color: #fff;
                background-color: #337ab7;
                border-color: #2e6da4;    display: inline-block;
                padding: 6px 12px;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.42857143;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;">Emitir</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'RJ'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://atestadodic.detran.rj.gov.br/" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p>Clique em <strong>Solicitar atestado</strong></p>
                <p><strong>Número da identidade:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg }}</span></p>
                <p><strong>Órgão emissor:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg_orgao.split('/')[0] }}</span></p>
                <p><strong>Data Expedição:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.rg_emissao) }}</span></p>
                <p><strong>Nome Completo:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>Nome do pai:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_pai }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                <p><strong>Data de nascimento:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.data_nascimento) }}</span></p>
                <p><strong>CEP:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.cep }}</span></p>
                <p><strong>Logradouro:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.rua }}</span></p>
                <p><strong>Número:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.numero }}</span></p>
                <p v-if="pedido.endereco.complemento"><strong>Complemento:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.complemento }}</span></p>
                <p><strong>Bairro:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.bairro }}</span></p>
                <p><strong>UF:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.estado }}</span></p>
                <p><strong>Município:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.cidade }}</span></p>
                <p><strong>Telefone:</strong> <span class="h2 ml-4 d-block">{{ pedido.telefone.numero }}</span></p>
                <p><strong>Motivo da solicitação:</strong> <span class="h2 ml-4 d-block">Outro</span></p>
                <p>Preencha com o Código de Segurança</p>
                Clique em <button class="btn btn-primary">Solicitar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'RN'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://esaj.tjrn.jus.br/sco/abrirCadastro.do" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Comarca:</strong> <span class="h2 ml-4 d-block" style="">{{ pedido.endereco.cidade }}</span></p>
                <p><strong>Modelo:</strong> <span class="h2 ml-4 d-block">Certidão de Antecedentes Criminais</span></p>
                <p><strong>Pessoa:</strong> <span class="h2 ml-4 d-block" style="">Física</span></p>
                <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                <p><strong>RG:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg }}</span></p>
                <p><strong>Gênero:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.sexo }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                <p><strong>Nome do pai:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_pai }}</span></p>
                <p><strong>Nascimento:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.data_nascimento) }}</span></p>
                <p><strong>Logradouro:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.rua }}, {{ pedido.endereco.numero }}</span></p>
                <p v-if="pedido.endereco.complemento"><strong>Complemento:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.complemento }}</span></p>
                <p><strong>CEP:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.cep }}</span></p>
                <p><strong>Bairro:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.bairro }}</span></p>
                <p><strong>Município:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.cidade }}</span></p>
                <p><strong>E-mail:</strong> <span class="h2 ml-4 d-block">{{ user.email }}</span></p>
                <p>Digite o Código de segurança</p>
                Clique em <button style="    background-position: top;
                background-color: rgb(236,140,130);
                border: 1px solid rgb(214,78,63);
                color: #000000;
                height: 40px;">Enviar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'RS'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://www1.tjrs.jus.br/site/servicos/alvara_de_folha_corrida/" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Emissão de Documento</strong> <span class="h2 ml-4 d-block">Certidão Judicial Criminal Negativa</span></p>
                <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                <p><strong>Nome do pai:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_pai }}</span></p>
                <p><strong>Data de Nascimento:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.data_nascimento) }}</span></p>
                <p><strong>Nascionalidade:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.nacionalidade }}</span></p>
                <p><strong>Estado civil:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.estado_civil }}</span></p>
                <p><strong>RG:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg }}</span></p>
                <p><strong>Órgão Expedidor:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg_orgao.split('/')[0] }}</span></p>
                <p><strong>UF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg_orgao.toUpperCase().split('/')[1] }}</span></p>
                <p><strong>Endereco:</strong> <span class="h2 ml-4 d-block" style="">{{ pedido.endereco.rua }}, {{ pedido.endereco.numero }}<span v-if="pedido.endereco.complemento"> - {{ pedido.endereco.complemento }}</span>. {{ pedido.endereco.bairro }} - {{ pedido.endereco.cidade }}/{{ pedido.endereco.estado }}. CEP {{ pedido.endereco.cep }}</span></p>
                Clique em <button>Emitir documento</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'RO'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://webapp.tjro.jus.br/certidaoonline/pages/cnpg.xhtml" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Comarca:</strong> <span class="h2 ml-4 d-block" style="">{{ pedido.endereco.cidade }}</span></p>
                <p><strong>Tipo de Certidão:</strong> <span class="h2 ml-4 d-block">Certidão de Antecedentes Criminais</span></p>
                <p><strong>Nome Completo:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>Documento:</strong> <span class="h2 ml-4 d-block">CPF</span></p>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                Clique em <button>Emitir Certidão</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'RR'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://certidao.tjrr.jus.br/certidao/pages/certidao/certidao-negativa" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Tipo de Certidão:</strong> <span class="h2 ml-4 d-block">Criminal</span></p>
                <p><strong>Tipo pessoa:</strong> <span class="h2 ml-4 d-block">Física</span></p>
                <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                Clique em <button class="btn btn-primary" style="background: #226522 !important;">Emitir Certidão</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'SC'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="https://esaj.tjsc.jus.br/sco/abrirCadastro.do" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>Comarca:</strong> <span class="h2 ml-4 d-block">{{ pedido.endereco.cidade }}</span></p>
                <p><strong>Modelo:</strong> <span class="h2 ml-4 d-block" style="">Certidão Criminal</span></p>
                <p><strong>E-mail:</strong> <span class="h2 ml-4 d-block">{{ user.email }}</span></p>
                <p><strong>Confirme:</strong> Não sou um robô</p>
                Clique no final da página no canto direito em <button class="py-1 px-5">Enviar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'SP'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://www.ssp.sp.gov.br/servicos/atestado.aspx" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p>Clique em <strong>Solicitar atestado</strong></p>
                <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>Número do RG:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg }}</span></p>
                <p><strong>Data de Expedição:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.rg_emissao) }}</span></p>
                <p><strong>Sexo:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.sexo }}</span></p>
                <p><strong>Data de Nascimento:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.data_nascimento) }}</span></p>
                <p><strong>Nome do pai:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_pai }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                Clique em <button>Pesquisar</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'SE'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="http://187.17.2.52/antecedentes/index.asp?pagina=frmSolicitarAtestado" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>RG:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.rg }}</span></p>
                <p><strong>Nascimento:</strong> <span class="h2 ml-4 d-block" style="">{{ formatarData(ficha.data_nascimento) }}</span></p>
                <p><strong>Nome:</strong> <span class="h2 ml-4 d-block" style="">{{ user.name }}</span></p>
                <p><strong>Nome da mãe:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_mae }}</span></p>
                <p><strong>Nome do pai:</strong> <span class="h2 ml-4 d-block" style="">{{ ficha.filiacao_pai }}</span></p>
                Clique em <button style="font-family: Verdana;
                font-size: 8pt;
                color: #FFFFFF;
                background-color: #747474;
                border-top-style: solid;
                border-left-style: solid;
                border-right-style: solid;
                border-bottom-style: solid;
                border-top-color: #000000;
                border-left-color: #000000;
                border-right-color: #000000;
                border-bottom-color: #000000;
                border-top-width: 1px;
                border-left-width: 1px;
                border-right-width: 1px;
                border-bottom-width: 1px;
                height: 20px;
                width: Auto;
                text-transform: none;">Emitir Atestado</button>
            </div>
        </div>
        <div class="row" v-else-if="ficha.rg_orgao.toUpperCase().split('/')[1] == 'TO'">
            <div class="col-12">
                <li>Primeiro passo, acesse o site abaixo </li>
                <a class="btn btn-xs btn-block btn-primary text-light" href="https://eproc1.tjto.jus.br/eprocV2_prod_1grau/externo_controlador.php?acao=cj_online&acao_origem=&acao_retorno=cj" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                <p><strong>CPF:</strong> <span class="h2 ml-4 d-block" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                <p><strong>Competência:</strong> <span class="h2 ml-4 d-block">Criminal</span></p>
                Clique em <button>Consultar</button>
            </div>
        </div>
    </div>
</div>
</div>
</div>
<!-- Estadual Comarca -->
<div class="card" v-if="pedido.documentacao.codigo == 'CR-001' && currentURL.includes('admin') == false || pedido.documentacao.codigo == 'CR-002' && currentURL.includes('admin') == false || pedido.documentacao.codigo == 'CR-003' && currentURL.includes('admin') == false || pedido.documentacao.codigo == 'CR-004' && currentURL.includes('admin') == false">
<h6 class="card-title bg-dark mb-0">
<a class="d-flex align-items-center p-5 collapsed" data-toggle="collapse" href="#collapse-est-1"
aria-expanded="false">
<span class="text-white w-100 lead">Certidão negativa <u>estadual(comarca)</u> de antecedente criminal <span class="float-right badge badge-light text-danger" v-if="ficha.confirmar_certidoes"><i class="fa fa-times mr-2"></i>não é necessário, você informou ser isento</span>
<span v-if="!ficha.confirmar_certidoes && pedido.geracao_manual == true" class="float-right badge text-white"><i class="fa fa-check mr-2"></i>é necessário</span>
</span>
</a>
</h6>
<div id="collapse-est-1" class="collapse" data-parent="#accordion-certidoes" style="">
<div class="card-body">
    <div class="row no-gutters">
        <div class="col-12">
            <p>
                Você precisa requisitar pessoalmente no fórum de sua cidade uma Certidão Negativa Criminal que deve conter:
                <li>referir-se ao Estado (Comarca) onde reside o pesquisado;</li>
                <li>fazer referência a Juizados Especiais Criminais;</li>
                <li>o pesquisado deve ser: i) se processo de pessoa física, o próprio requerente; ou ii) se processo de pessoa jurídica, o representante legal e o substituto imediato nomeados.</li>
            </p>
        </div>
        <div class="col-12 align-self-start text-center">
            <span>
                <a :href="'https://www.google.com.br/maps/dir//F%C3%B3rum+de+'+ pedido.endereco.cidade.split(' ').join('+') + '+-+'+ pedido.endereco.estado.split(' ').join('+') + '/'" target="_blank" class="btn btn-xs btn-block btn-primary"><i class="fa fa-external-link-alt mr-3"></i>Abrir mapa</a>
            </span>
        </div>
    </div>
</div>
</div>
</div>
<!-- Certidão Militar  -->
<div class="card" v-if="pedido.geracao_manual != true || forcaExibicao == true">
<h6 class="card-title bg-dark mb-0">
<a class="d-flex align-items-center p-5 collapsed" data-toggle="collapse" href="#collapse-m-4"
aria-expanded="false">
<span class="text-white lead w-100">Certidão negativa <u>militar</u> de antecedente criminal  <span class="float-right badge badge-light text-danger" v-if="ficha.confirmar_certidoes"><i class="fa fa-times mr-2"></i>não é necessário, você informou ser isento</span></span>
</a>
</h6>
<div id="collapse-m-4" class="collapse" data-parent="#accordion-certidoes" style="">
<div class="card-body">
    <div class="">
        <div class="row" v-if="pedido.endereco.estado != 'Rio Grande do Sul' && pedido.endereco.estado != 'Minas Gerais' && pedido.endereco.estado != 'São Paulo'">
            <div class="col-12">
                <p>
                    <li>Primeiro passo, acesse o site do Superior Tribunal Militar de {{ pedido.endereco.estado }}</li>
                    <a class="btn btn-xs btn-block btn-primary text-light" href="https://www2.stm.jus.br/ceneg_internet/emitir/index.php" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                    <li>Segundo passo, preencha os campos:</li>
                    <p><strong>Nome completo:</strong> <span class="ml-4 d-block h2" style="">{{ user.name }}</span>
                    <strong class="d-block">Número do CPF:</strong> <span class="ml-4 h2" style="letter-spacing: 9px;">{{ ficha.cpf.replace(/\D/g,'') }}</span>
                    <strong class="d-block">Data de nascimento:</strong> <span class="ml-4 h2" style="letter-spacing: 9px;">{{ formatarData(ficha.data_nascimento) }}</span>
                    <strong class="d-block">Nome da mãe:</strong> <span class="ml-4 h2" style="">{{ ficha.filiacao_mae }}</span></p>
                    Clique em <a class="btn font-weight-bold" style="padding: 8px 14px;
                        border-radius: 4px;
                        border: solid 1px #007DC3;
                        border: 1px solid #d5d5d5;
                        background: #418BCA;
                        background-image: linear-gradient(to bottom, #3498db, #418BCA);
                        color: #FFFFFF;
                        font-family: Verdana, Arial, Helvetica, sans-serif;
                    font-size: 12px; cursor: default;">Emitir</a>
                </p>
            </div>
        </div>
        <div class="row" v-else-if="pedido.endereco.estado == 'Minas Gerais'">
            <div class="col-12">
                <p>
                    <li>Primeiro passo, acesse o site do Tribunal de Justiça Militar do Estado de  de {{ pedido.endereco.estado }}</li>
                    <a class="btn btn-xs btn-block btn-primary" href="http://www.tjmmg.jus.br/certidoes" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir</a>
                    <li>Segundo passo, preencha os campos:</li>
                    <p><strong>Nome completo:</strong> <span class="ml-4" style="">{{ user.name }}</span></p>
                    <p><strong>Número do CPF:</strong> <span class="ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                    <p><strong>Data de nascimento:</strong> <span class="ml-4" style="letter-spacing: 9px;">{{ formatarData(ficha.data_nascimento) }}</span></p>
                    <p><strong>Nome da mãe:</strong> <span class="ml-4" style="">{{ ficha.filiacao_mae }}</span></p>
                    Clique em <a class="btn font-weight-bold" style="padding: 4px 12px;
                        margin-bottom: 0;
                        font-size: 14px;
                        line-height: 20px;
                        cursor: default;
                        color: #333333;
                        text-align: center;
                        background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
                        text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
                        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
                        border-bottom-color: #b3b3b3;
                    vertical-align: middle;">Emitir</a>
                </p>
            </div>
        </div>
        <div class="row" v-else-if="pedido.endereco.estado == 'São Paulo'">
            <div class="col-12">
                <p>
                    <li>Primeiro passo, acesse o site do Tribunal de Justiça Militar do Estado de {{ pedido.endereco.estado }}</li>
                    <a class="btn btn-xs btn-block btn-primary" href="http://www.tjmsp.jus.br/certidao/autenticar.aspx" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                    <li>Segundo passo, preencha os campos:</li>
                    <p><strong class="d-block">Número do CPF:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf.replace(/\D/g,'') }}</span>
                    <strong class="d-block">Número do RG:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.rg }}</span>
                    <strong class="d-block">Emissão do RG:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.rg_emissao }}</span>
                    <strong class="d-block">UF do RG:</strong> <span class="h2 ml-4" style="">{{ ficha.rg_orgao.toUpperCase().split('/')[1] }}</span>
                    <strong class="d-block">Emissor do RG:</strong> <span class="h2 ml-4" style="">{{ ficha.rg_orgao.split('/')[0] }}</span></p>
                    Clique em <a class="btn font-weight-bold" style=" color: #ffffff;
                        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
                        padding: 4px 12px;
                        cursor: default;
                        margin-bottom: 0;
                        margin-left: .3em;
                        font-size: 14px;
                        text-transform: uppercase;
                        line-height: 20px; text-align: center;
                    background-image: linear-gradient(to bottom, #0088cc, #0044cc);">Confirmar</a>
                </p>
            </div>
        </div>
        <div class="row" v-else-if="pedido.endereco.estado == 'Rio Grande do Sul'">
            <div class="col-12">
                <p>
                    <li>Primeiro passo, acesse o site do Tribunal de Justiça Militar do Estado  de {{ pedido.endereco.estado }}</li>
                    <a class="btn btn-xs btn-block btn-primary" href="https://www.tjmrs.jus.br/certidoes/solicitar" target="_blank" ><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
                    <li>Segundo passo, preencha os campos:</li>
                    <p><strong class="d-block">Número do CPF:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.cpf }}</span></p>
                    <strong class="d-block">Data de Nascimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(ficha.data_nascimento) }}</span>
                    <strong class="d-block">Escopo da Certidão:</strong> <span class="h2 ml-4" style="">Criminal</span>
                    <strong class="d-block">Nome completo:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span>
                    <strong class="d-block">Número do RG:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.rg }}</span>
                    <strong class="d-block">Emissor do RG:</strong> <span class="h2 ml-4" style="">{{ ficha.rg_orgao.split('/')[0] }}</span>
                    <strong class="d-block">UF do RG:</strong> <span class="h2 ml-4" style="">{{ ficha.rg_orgao.toUpperCase().split('/')[1] }}</span>
                    <strong class="d-block">Nacionalidade:</strong> <span class="h2 ml-4" style="">{{ ficha.nacionalidade }}</span>
                    <strong class="d-block">Estado Civil:</strong> <span class="h2 ml-4" style="">{{ ficha.estado_civil }}</span>
                    <strong class="d-block">Nome da mãe (Completo):</strong> <span class="h2 ml-4" style="">{{ ficha.filiacao_mae }}</span>
                    @if(ficha.filiacao_pai) <strong class="d-block">Nome do pai (Completo, Opcional):</strong> <span class="h2 ml-4" style="">{{ ficha.filiacao_pai }}</span> @endif

                    <strong class="d-block">Endereço:</strong> <span class="h2 ml-4" style="">{{ pedido.endereco.rua }}, {{ pedido.endereco.numero }} <span v-if="pedido.endereco.complemento">- {{ pedido.endereco.complemento }}</span>{{','}} {{ pedido.endereco.bairro }}. {{ pedido.endereco.cidade }}/{{ pedido.endereco.estado }} - {{ pedido.endereco.cep }}</span>
                </p>
                <p>Clique em <a class="btn font-weight-bold" style="padding: 10px 16px;
                    border-radius: 4px;
                    border: solid 1px #007DC3;
                    border: 1px solid #d5d5d5;
                    cursor: default;
                    background: #418BCA;
                    background-image: linear-gradient(to bottom, #3498db, #418BCA);
                    color: #FFFFFF;
                    font-family: Verdana, Arial, Helvetica, sans-serif;
                font-size: 12px;">Solicitar</a></p>
            </p>
        </div>
    </div>
</div>
</div>
</div>
</div>
<!-- Certidão Eleitoral -->
<div class="card" v-if="pedido.geracao_manual != true || forcaExibicao == true ">
<h6 class="card-title bg-dark mb-0">
<a class="d-flex align-items-center p-5 collapsed" data-toggle="collapse" href="#collapse-m-5"
aria-expanded="false">
<span class="text-white lead w-100">Certidão negativa <u>eleitoral</u> de antecedente criminal <span class="float-right badge badge-light text-danger" v-if="ficha.confirmar_certidoes"><i class="fa fa-times mr-2"></i>não é necessário, você informou ser isento</span></span>
</a>
</h6>
<div id="collapse-m-5" class="collapse" data-parent="#accordion-certidoes" style="">
<div class="card-body">
<div class="row no-gutters">
    <div class="col-12">
        <p>
            <li>Primeiro passo, acesse o site da Justiça Eleitoral </li>
            <a class="btn btn-xs btn-block btn-primary" target="_blank" href="http://www.tse.jus.br/eleitor/certidoes/certidao-de-crimes-eleitorais"><i class="fa fa-external-link-alt mr-3"></i>Abrir site</a>
            <li>Segundo passo, preencha os campos:</li>
            <p>
                <strong class="d-block">Nome do eleitor:</strong> <span class="h2 ml-4" style="">{{ user.name }}</span>
                <strong class="d-block">Número do título de eleitor:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ ficha.titulo_eleitor }}</span>
                <strong class="d-block">Data de nascimento:</strong> <span class="h2 ml-4" style="letter-spacing: 9px;">{{ formatarData(ficha.data_nascimento) }}</span>
                <div v-if="ficha.filiacao_mae">
                    <strong class="d-block">Nome da mãe:</strong> <span class="h2 ml-4" style="">{{ ficha.filiacao_mae }}</span>
                </div>
                <div v-else>
                    <strong class="d-block">Nome da mãe:</strong> <span class="h2 ml-4" style="">Marque opção "não consta/em branco"</span>
                </div>
                <div v-if="ficha.filiacao_pai">
                    <strong class="d-block">Nome do pai:</strong> <span class="h2 ml-4" style="">{{ ficha.filiacao_pai }}</span>
                </div>
                <div v-else>
                    <strong class="d-block">Nome do pai:</strong> <span class="h2 ml-4" style="">Marque opção "não consta/em branco"</span>
                </div>
            </p>
            <p>Marque a caixa <strong>Não sou um robô</strong>.</p>
            Clique em <a class="btn font-weight-bold" style="border: #999 solid 1px;
                border-radius: 0px;
                background: #CCC;
                font-weight: bold;
                width: 175px;
            height: 37px;">Emitir Certidão</a>
        </p>
    </div>
</div>
</div>
</div>
</div>
<div class="col-12 mt-7 pb-4" v-if="pedido.documentacao.codigo == 'CR-001' || pedido.documentacao.codigo == 'CR-002' || pedido.documentacao.codigo == 'CR-003' || pedido.documentacao.codigo == 'CR-004'">
<div class="alert alert-info py-5 text-center" v-if="currentURL.includes('admin') == false">
Caso alguma Certidão dê positivo para ações criminais, pode ser também anexada uma “Certidão de Objeto e Pé” para cada ação, capaz de informar quem são as partes, qual o objeto da ação e em que pé está.
</div>
</div>
</div>
</span>
</template>

<script>
  import dayjs from 'dayjs';
  export default {
    name: 'CertidoesIdoneidade',
    props: {
      pedido: Object,
      forcaExibicao: Boolean,
    },
    data() {
      return {
        currentURL: window.location.pathname,
        user: this.pedido.user,
        ficha: this.pedido.ficha,
      }
    },
    methods: {
      formatarData: function (data) {
        return dayjs(data).format('DD/MM/YYYY');
      },
      pad: function (n) {
          return (n < 10) ? ("0" + n) : n;
      }
    }
  }
</script>
