<template>
	<form class="col-xl-12" method="post" @submit.prevent="fazerPedido()" v-if="documentacao != null">
		<p class="lead">
			Confirme abaixo:
		</p>
		<div class="form-group" v-if="ficha != null && ficha.data_nascimento != null">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_idade" :value="(ficha.data_nascimento != null && calc_idade < 25) ? false : true" class="custom-control-input" id="leu-idade">
			    <label v-bind:class="{ 'text-danger': ficha.data_nascimento != null && calc_idade < 25 }" class="custom-control-label h6" for="leu-idade">Eu {{ nome }}, afirmo que possuo mais de 25 anos de idade.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_informacoes" value="true" class="custom-control-input" id="leu-informacoes">
			    <label class="custom-control-label h6" for="leu-informacoes">Eu {{ nome }}, afirmo que os dados que estão em minha <a href="/minhas-informacoes">ficha de informações</a> estão corretos.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_termos" value="true" class="custom-control-input" id="leu-termos">
			    <label class="custom-control-label h6" for="leu-termos">Eu {{ nome }}, li os <a href="/termos" target="_blank">termos de uso</a> e <a href="/privacidade" target="_blank">privacidade</a> e concordo com os mesmos para a utilização dos serviços oferecidos pelo cacdocs.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_checklist" value="true" class="custom-control-input" id="leu-checklist">
			    <label class="custom-control-label h6" for="leu-checklist">Eu {{ nome }}, li as informações da documentação <a :href="'/documentacoes/'+ documentacao.categoria.slug +'/'+ documentacao.slug" target="_blank">{{ documentacao.nome }}</a> e sua <a :href="'/documentacoes/checklist/'+ documentacao.slug" target="_blank">lista de verificação</a> e sei quais documentos serão gerados pelo cacdocs e quais são de minha responsabilidade.</label>
			</div>
		</div>
		<div class="form-group">
			<div class="custom-control custom-checkbox text-small">
			    <input required type="checkbox" name="confirma_custos" value="true" class="custom-control-input" id="leu-custos">
			    <label class="custom-control-label h6" for="leu-custos">Eu {{ nome }}, entendo que não é responsabilidade do cacdocs o <span class="text-primary">pagamento de taxas</span> e <span class="text-primary">custos</span> sendo esses de minha responsabilidade. Exemplo: Guia de Recolhimento da União (GRU).</label>
			</div>
		</div>
		<div v-if="enviando == false">
			<p class="lead mt-7">
				Preencha abaixo com as informações da arma a ser adquirida.
			</p>
			<div class="form-row">
				<div class="col-lg-3">
					<label for="arma_tipo" class="control-label w-100">Tipo <i class="ml-2 float-right fa fa-question-circle text-primary" data-toggle="tooltip" data-title="Revólver, Rifle, Pistola, Espingarda, Carabina, etc.."></i></label>
					<input type="text" class="form-control" id="arma_tipo" required v-model="arma.tipo">
				</div>
				<div class="col-lg-3">
					<label for="arma_calibre" class="control-label">Calibre</label>
					<input type="text" class="form-control" id="arma_calibre" required v-model="arma.calibre">
				</div>
				<div class="col-lg-3">
					<label for="arma_marca" class="control-label">Marca</label>
					<input type="text" class="form-control" id="arma_marca" required v-model="arma.marca">
				</div>
				<div class="col-lg-3">
					<label for="arma_modelo" class="control-label">Modelo</label>
					<input type="text" class="form-control" id="arma_modelo" required v-model="arma.modelo">
				</div>
			</div>
			<div class="custom-control custom-checkbox text-small mt-4">
			    <input required type="checkbox" name="confirma_acervo" value="true" class="custom-control-input" id="leu-acervo">
			    <label class="custom-control-label h6" for="leu-acervo">Eu {{ nome }}, afirmo que os dados da arma acima estão corretos.</label>
			</div>
		</div>
		<div v-if="enviando == true" class="text-center py-11">
			<i class="fa fa-spin fa-spinner fa-2x"></i>
		</div>
		<span v-if="enviando == false">
			<p class="lead mt-7 mb-0">
				Informações da compra para o pedido de aquisição da arma de fogo
			</p>
			<p>Preencha o formulário abaixo, lembre-se de conferir pois informações incorretas causarão indeferimento do pedido:</p>
			<div class="form-row mt-5">
        		<div class="col-lg-4">
	              <div class="form-group">
	                  	<label class="control-label">Tipo de compra</label>
	                  	<div class="form-group">
							<div class="custom-control custom-radio text-small">
							    <input type="radio" required v-model="tipo_compra_arma" name="tipo_compra_arma" value="comercio" class="custom-control-input" id="comercio" >
							    <label class="custom-control-label h6" for="comercio">Comércio
							    </label>
							</div>
							<div class="custom-control custom-radio text-small">
							    <input type="radio" required v-model="tipo_compra_arma" name="tipo_compra_arma" value="industria" class="custom-control-input" id="industria">
							    <label class="custom-control-label h6" for="industria">Indústria nacional
							    </label>
							</div>
							<div class="custom-control custom-radio text-small">
							    <input type="radio" required v-model="tipo_compra_arma" name="tipo_compra_arma" value="importacao" class="custom-control-input" id="importacao">
							    <label class="custom-control-label h6" for="importacao">Importação
							    </label>
							</div>
						</div>
	              	</div>
		        </div>
		        <div class="col-lg-2">
        			<div class="form-group">
	                  	<label for="calibre_de_uso" class="control-label">Calibre de uso</label>
	                  	<select id="calibre_de_uso" required class="form-control" v-model="calibre_de_uso">
							<option value="Permitido">Permitido</option>
							<option value="Restrito">Restrito</option>
						</select>
	              	</div>
        		</div>
		        <div class="col-lg-4">
		        	<div class="form-group">
	                  	<label for="fornecedor" class="control-label">Fornecedor</label>
	                  	<input type="text" required v-model="fornecedor" id="fornecedor" class="form-control">
	              	</div>
		        </div>
		        <div class="col-lg-2">
		        	<div class="form-group">
	                  	<label for="fornecedor_cr" class="control-label">CR do fornecedor</label>
	                  	<input type="text" required v-model="fornecedor_cr" id="fornecedor_cr" class="form-control">
	              	</div>
		        </div>
        		<div class="col-12">
        			<div class="form-group alert-primary p-5 rounded">
	    				<label class="control-label">O registro da arma é para você ou uma entidade de tiro está adquirindo e você é o representante legal da mesma?</label>
	                  	<div class="form-group mb-0">
							<div class="custom-control custom-radio text-small">
							    <input type="radio" required v-model="clube" name="clube" :value="false" class="custom-control-input" id="compra_e_minha" >
							    <label class="custom-control-label h6" for="compra_e_minha">A arma é para mim
							    </label>
							</div>
							<div class="custom-control custom-radio text-small">
							    <input type="radio" required v-model="clube" name="clube" :value="true" class="custom-control-input" id="sou_responsavel_legal">
							    <label class="custom-control-label h6" for="sou_responsavel_legal">A arma é para entidade de tiro e eu sou o representante legal
							    </label>
							</div>
						</div>
        			</div>
        		</div>
        		<div class="col-12" v-show="clube == true">
        			<div class="form-row">
        				<div class="col-lg-6">
	        				<div class="form-group">
	        					<label for="clube_nome" class="control-label">Razão social do clube</label>
	        					<input type="text" :required="clube ? true : false" v-model="clube_nome" class="form-control" id="clube_nome">
	        				</div>
	        			</div>
	        			<div class="col-lg-4">
	        				<div class="form-group">
	        					<label for="clube_cnpj" class="control-label">CNPJ do clube</label>
	        					<input type="text" :required="clube ? true : false" v-model="clube_cnpj" class="form-control" id="clube_cnpj">
	        				</div>
	        			</div>
	        			<div class="col-lg-2">
	        				<div class="form-group">
	        					<label for="clube_cr" class="control-label">CR do clube</label>
	        					<input type="text" :required="clube ? true : false" v-model="clube_cr" class="form-control" id="clube_cr">
	        				</div>
	        			</div>
	        			<div class="col-lg-6">
	        				<div class="form-group">
	        					<label for="clube_telefone" class="control-label">Telefone do clube</label>
	        					<input type="text" :required="clube ? true : false" v-model="clube_telefone" v-mask="['(##) ####-####', '(##) #####-####']" class="form-control" id="clube_telefone">
	        				</div>
	        			</div>
	        			<div class="col-lg-6">
	        				<div class="form-group">
	        					<label for="clube_email" class="control-label">E-mail do clube</label>
	        					<input type="email" :required="clube ? true : false" v-model="clube_email" class="form-control" id="clube_email">
	        				</div>
	        			</div>
        			</div>
        		</div>
        		<div class="col-lg-12">
        			<div class="form-group">
	                  	<label for="local_de_entrega" class="control-label">Local de entrega da arma <small>(local e endereço)</small></label>
	                  	<input type="text" id="local_de_entrega" class="form-control" v-model="local_de_entrega" required>
	                  	<p class="mt-2 mb-0">Seguindo a <a href="http://www.dfpc.eb.mil.br/phocadownload/Portarian93_COLOG_29JUN18_Altera_a_Portaria_n_51_COLOG.pdf" target="_blank">Portaria 93 do COLOG, DE 29 DE JUNHO DE 2018</a>:</p>
	                  	<ul class="mb-5">
	                  		<li>
	                  			§7º O fornecedor deve entregar a arma no local indicado pelo adquirente, na declaração para
								aquisição de arma de fogo; ou diretamente na loja de armas, desde que o adquirente
								apresente o CRAF.
	                  		</li>
	                  	</ul>
	              	</div>
        		</div>
        		<div class="col-12" v-show="clube == false">
        			<div class="form-row">
		        		<div class="col-lg-6">
		        			<div class="form-group">
			                  	<label for="outro_cr_atividade" class="control-label">Você adquiriu outras armas de fogo nos últimos 12 meses?</label>
			                  	<div class="custom-control custom-radio text-small">
								    <input type="radio" :required="clube ? false : true" v-model="adquiri" name="adquiri" :value="true" class="custom-control-input" id="sim" >
								    <label class="custom-control-label h6" for="sim">Sim
								    </label>
								</div>
								<div class="custom-control custom-radio text-small">
								    <input type="radio" :required="clube ? false : true" v-model="adquiri" name="adquiri" :value="false" class="custom-control-input" id="nao">
								    <label class="custom-control-label h6" for="nao">Não
								    </label>
								</div>
			              	</div>
		        		</div>
		        		<div class="col-lg-6">
		        			<div class="form-group">
		        				<label for="outro_regiao_militar" class="control-label">Quantas armas adquiriu nos últimos 12 meses?</label>
		        				<input type="number" v-model="quantas_armas" min="0" class="form-control" :disabled="adquiri == false" :required="clube == false && adquiri == true && adquiri != false">
		        			</div>
		        		</div>
		        	</div>
		        </div>
			</div>
			<div v-show="clube == false">
				<p class="mb-0 mt-3">Seu nível é</p>
				<div class="form-group">
					<div class="custom-control custom-radio text-small">
					    <input :required="clube ? false : true" type="radio" v-model="meu_nivel" name="meu_nivel" value="1" class="custom-control-input" id="um" >
					    <label class="custom-control-label h6" for="um">Nível I
					    	<p class="alert alert-info mt-2 font-weight-light">Atirador de clube com 08 participações em prática de recreação, em treinamento ou competição no estande de tiro, em eventos distintos, no período de doze meses.</p>
					    </label>
					</div>
				</div>
				<div class="form-group">
					<div class="custom-control custom-radio text-small">
					    <input :required="clube ? false : true" type="radio" v-model="meu_nivel" name="meu_nivel" value="2" class="custom-control-input" id="dois">
					    <label class="custom-control-label h6" for="dois">Nível II
					    	<p class="alert alert-info mt-2 font-weight-light">Atirador participante de clube e federação com 08 participações em treinamentos ou competição no estande de tiro, em eventos distintos, no período de doze meses. <br>Das 08 participações, 02 devem ser competições, sendo pelo menos 01 competição de âmbito estadual/regional.
					    	</p>
					    </label>
					</div>
				</div>
				<div class="form-group">
					<div class="custom-control custom-radio text-small">
					    <input :required="clube ? false : true" type="radio" v-model="meu_nivel" name="meu_nivel" value="3" class="custom-control-input" id="tres">
					    <label class="custom-control-label h6" for="tres">Nível III
					    	<p class="alert alert-info mt-2 font-weight-light">Atirador participante de clube, federação e confederação com 08 participações de treinamento ou competição no estande de tiro, em eventos distintos, no período de doze meses. <br>Das 08 participações, 04 devem ser competições, sendo pelo menos 02 competições de âmbito nacional e/ou internacional.
					    	</p>
					    </label>
					</div>
				</div>
			</div>
			<div class="alert alert-info mt-2 pt-5 font-weight-light">
				<i class="fa fa-info-circle mr-3"></i>Limite da quantidade de armas previsto no <a href="http://www.planalto.gov.br/ccivil_03/_Ato2019-2022/2019/Decreto/D9846.htm" target="_blank">inciso I do art. 3º do Decreto nº 9.846/2019</a>:
				<br>
				<ul>
					<li><strong>Colecionador</strong>: 5 armas de cada modelo;</li>
					<li><strong>Atirador desportivo</strong>: 30 armas;</li>
					<li><strong>Caçador</strong>: 15 armas;</li>
					<li><strong>Entidades de tiro</strong>: 60 armas;</li>
				</ul>
			</div>
			<div class="form-group" v-show="clube == false">
              	<label class="control-label">A arma a ser registrada destina-se ao acervo de:</label>
              	<select :required="clube ? false : true" class="form-control" v-model="minha_atividade">
					<option value="colecionamento">Colecionamento</option>
					<option value="atirador">Tiro desportivo</option>
					<option value="caça">Caça</option>
				</select>
              	<!-- <input type="text" placeholder="Coloque um dos seus acervos" class="form-control" v-model="minha_atividade" required>
              	<small>Ex: Atirador desportivo - Tiro Desportivo, <strong>preencha como a atividade está em seu Certificado de Registro</strong>.</small> -->
        	</div>
	      	<div class="form-group">
				<div class="custom-control custom-checkbox text-small">
				    <input required type="checkbox" name="confirma_transferencia" value="true" class="custom-control-input" id="leu-transferencia">
				    <label class="custom-control-label h6" for="leu-transferencia">Eu {{ nome }}, confirmo que os dados acima estão corretos.</label>
				</div>
			</div>
		</span>
		<div class="form-group">
			<button :disabled="pedindo" class="btn btn-primary" v-html="botao"></button>
		</div>
	</form>
</template>

<script>
	import dayjs from 'dayjs';
	export default {
		name: 'Af003',
		data() {
			return {
				// acervos: null,
				enviando: false,

				arma: {
					tipo: null,
					calibre: null,
					marca: null,
					modelo: null,
				},

				tipo_compra_arma: null,
				minha_atividade: null,
				calibre_de_uso: null,
				meu_nivel: null,
				adquiri: null,
				local_de_entrega: null,
				quantas_armas: null,
				// portaria 136
				clube: null,
				acessorio: null,
				clube_nome: null,
				clube_cr: null,
				clube_cnpj: null,
				clube_telefone: null,
				clube_email: null,
				fornecedor: null,
				fornecedor_cr: null,
				nota_fiscal_data: null,
				autorizacao_data: null,

				nome: 'Usuário',
				ficha: null,
				endereco: null,
				telefone: null,
				informacoes: null,

				documentacao: null,
                currentURL: window.location.pathname,
                botao: '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento',
                pedindo: false,
			}
		},
		mounted() {
			// this.getAcervo();
			this.getInfo();
			this.getDocumentacao();
		},
		methods: {
			// getAcervo: function () {
			// 	var app = this;
			// 	axios.get('/acervo')
			// 	.then((res) => {
			// 		app.acervos = res.data;
			// 	});
			// },
			getInfo: function () {
				var app = this;
				axios.get('/info')
				.then((res) => {
					app.informacoes = res.data;
					app.nome = res.data.name;
					app.telefone = res.data.telefone;
					app.endereco = res.data.endereco;
					app.ficha = res.data.ficha;
				});
			},
			getDocumentacao: function () {
				var app = this;
				axios.get('/documentacao/'+this.currentURL.replace('/contratar/', ''))
				.then((res) => {
					app.documentacao = res.data;
				});
			},
			fazerPedido: function () {
            	this.botao = '<i class="fa fa-spin fa-spinner mr-2"></i>Concluindo pedido e direcionando para pagamento';
				this.pedindo = true;
				if (this.calc_idade < 25) {
					this.botao = '<i class="fa fa-check mr-2"></i>Concluir pedido e ir para o pagamento';
					this.pedindo = false;
					this.$toast.error('Você precisa ter mais de 25 anos para o pedido de registro', '', {
						position: 'center',
						overlay: true,
						timeout: 10000,
					});
				} else {
					this.enviando = true;

					axios.post(this.currentURL, {
						doc_id: this.documentacao.id,
						tipo_compra_arma: this.tipo_compra_arma,
						atividade: this.minha_atividade,
						arma_selecionada: this.arma_selecionada,
						calibre_de_uso: this.calibre_de_uso,
						nivel: this.meu_nivel,
						adquiri: this.adquiri,
						local_de_entrega: this.local_de_entrega,
						quantas_armas: this.quantas_armas,
						// portaria 136
						acessorio: this.acessorio,
						clube: this.clube,
						clube_nome: this.clube_nome,
						clube_cr: this.clube_cr,
						clube_cnpj: this.clube_cnpj,
						clube_telefone: this.clube_telefone,
						clube_email: this.clube_email,
						fornecedor: this.fornecedor,
						fornecedor_cr: this.fornecedor_cr,
						nota_fiscal_data: this.nota_fiscal_data,
						autorizacao_data: this.autorizacao_data,

						// arma
						arma_marca: this.arma.marca,
						arma_modelo: this.arma.modelo,
						arma_tipo: this.arma.tipo,
						arma_calibre: this.arma.calibre,
					})
					.then(res => {
						this.$toast.info("Pedido efetuado, direcionando para página de pagamento.", 'Tudo certo');
						setTimeout(function() {
							window.location.href = '/meu-pedido/'+res.data+'/pagar';
						}, 4000);
					})
					.catch(err => {
						this.enviando = false;
	                	this.botao = '<i class="fa fa-check mr-2"></i>Tente novamente';
						this.pedindo = false;
						this.$toast.error(err.response.data.error, 'Deu errado');
					});
				}
			}
		},
		watch: {
			clube: function (val) {
				if (val == true) {
					this.minha_atividade = 'clube';
				} else {
					this.minha_atividade = null;
				}
			}
		},
		computed: {
			calc_idade: function () {
	            if(this.ficha.data_nascimento != null) {
	                return Math.abs(dayjs(this.ficha.data_nascimento).diff(dayjs(new Date()), 'year'));
	            } else {
	            	return false;
	            }
	        }
		}
	}
</script>

<style>

</style>
