<template>
	<section class="py-5" v-if="propostas.length > 0">
		<div v-if="propostas.length == 1">
			<div class="row" v-for="(proposta, index) in propostas">
				<div class="col-12 text-center" v-if="proposta.cancelado == 0 && proposta.recusado == 0 && proposta.aceito == 0">
					<h5 class="mb-0">Proposta enviada:</h5>
					<h5 class="small mb-5 align-self-center">
                        {{ created_at(proposta.created_at) }}
                    </h5>
					<h1 class="text-center">
						<small>R$</small> {{ proposta.valor }}
					</h1>
	                <p class="px-3 py-1 alert alert-primary">aguardando</p>			
	                <div class="text-center py-4 alert alert-warning">Seu telefone e e-mail foram passados para o anunciante, aguardando resposta da proposta (<strong>aceita</strong> ou <strong>rejeitada</strong>)</div>
	                <button @click="abrirModalCancelar(proposta.id)" class="btn btn-danger btn-sm">Cancelar</button>
				</div>
				<div class="col-12 text-center" v-if="proposta.cancelado == 0 && proposta.recusado == 1 && proposta.aceito == 0">
					<h5 class="mb-0">Proposta enviada:</h5>
					<h5 class="small mb-5 align-self-center">
                        {{ created_at(proposta.created_at) }}
                    </h5>
					<h1 class="text-center">
						<small>R$</small> {{ proposta.valor }}
					</h1>
	                <p class="px-3 py-1 alert alert-danger">recusada</p>			
	                <div class="text-center py-4 alert alert-warning">Sua proposta foi recusada, você pode fazer uma nova proposta.</div>
				</div>
				<div class="col-12 text-center" v-if="proposta.cancelado == 0 && proposta.recusado == 0 && proposta.aceito == 1">
					<h5 class="mb-0">Proposta enviada:</h5>
					<h5 class="small mb-5 align-self-center">
                        {{ created_at(proposta.created_at) }}
                    </h5>
					<h1 class="text-center">
						<small>R$</small> {{ proposta.valor }}
					</h1>
	                <p class="px-3 py-1 alert alert-success">aceita</p>			
	                <div class="text-center py-4 alert alert-success">Sua proposta foi <strong>aceita</strong> entre em contato com o anunciante pelo telefone abaixo para acertar a transferência, pagamento e solicitar a geração do contrato de compra e venda.</div>
	                <h2><a :href="'tel:+55'+proposta.produto.autor.telefone.numero.replace(/\D/g,'')">{{ proposta.produto.autor.telefone.numero }}</a></h2>
	                <button v-if="proposta.contrato == null" disabled class="btn btn-primary btn-sm"><i class="fa fa-file-signature mr-2"></i>Aguardando a emissão do contrato</button>

	                <a v-if="proposta.contrato != null && proposta.contrato.status == 1" href="/meus-contratos" class="btn btn-primary btn-sm"><i class="fa fa-file-signature mr-2"></i>Visualizar seus contratos</a>
	                <button @click="abrirModalCancelar(proposta.id)" class="btn btn-danger btn-sm">Cancelar</button>
				</div>
				<div class="col-12 text-center" v-if="proposta.cancelado == 1 && proposta.recusado == 0 && proposta.aceito == 0">
					<h5 class="mb-0">Proposta enviada:</h5>
					<h5 class="small mb-5 align-self-center">
                        {{ created_at(proposta.created_at) }}
                    </h5>
					<h1 class="text-center">
						<small>R$</small> {{ proposta.valor }}
					</h1>
	                <p class="px-3 py-1 alert alert-danger">cancelada</p>			
	                <div class="text-center py-4 alert alert-warning">Seu telefone e e-mail foram ocultados do anunciante.</div>
				</div>
				<div class="modal" :id="'modalCancelar_'+proposta.id" tabindex="-1" role="dialog" aria-hidden="true">
	                <div class="modal-dialog modal-full" role="document">
	                    <div class="modal-content rounded-0">
	                        <div class="modal-header my-auto">
	                            <h5 class="mb-0 text-muted">
	                                Cancelar proposta
	                            </h5>
	                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
	                                <i class="fa fa-times"></i>
	                            </button>
	                        </div>
	                        <div class="modal-body p-0" id="result">
	                            <div class="row text-center justify-content-center">
	                                <div class="col-md-12 col-lg-10 pt-7">
	                                    <h2 class="mb-2 mb-lg-0">
	                                        Olá {{ userNome }},
	                                    </h2>
	                                    <div class="lead mb-7">
	                                        <p class="mb-0">Deseja realmente cancelar a proposta feita?</p>
	                                        <div v-if="proposta.produto.contrato.length > 0" v-for="contrato in proposta.produto.contrato">
	                                            <p class="mb-0" v-if="proposta.id == contrato.proposta_id">Você tem um contrato ativo, o mesmo será cancelado juntamente com a proposta e qualquer cupom de desconto vinculado.</p>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>
	                        <div class="modal-footer">
	                            <button @click="cancelarProposta(proposta.id)" class="btn btn-primary"><i class="fa fa-times mr-3"></i>Cancelar proposta</button>
	                        </div>
	                    </div>
	                </div>
	            </div>
            </div>
		</div>
		<div class="row" v-else>
			<div class="col-12">
				<h5 class="mb-2 text-left">Propostas enviadas:</h5>
				<div class="accordion" id="propostas">
					<div class="card" v-for="(proposta, index) in propostas">
						<div class="card-header d-flex justify-content-around justify-content-lg-between flex-wrap align-itens-center" :id="'heading_'+index"  data-toggle="collapse" :data-target="'#collapse_'+index" aria-expanded="true" :aria-controls="'collapse_'+index" style="cursor: pointer;">
							<h3 class="mb-0">
								<span v-if="proposta.cancelado == 0 && proposta.recusado == 0 && proposta.aceito == 0" class="px-3 py-1 d-block d-lg-inline mb-2 mb-lg-0 small alert alert-primary">aguardando</span>
								<span v-else-if="proposta.cancelado == 0 && proposta.recusado == 1 && proposta.aceito == 0" class="px-3 py-1 d-block d-lg-inline mb-2 mb-lg-0 small alert alert-danger">recusada</span>
				                <span v-else-if="proposta.cancelado == 0 && proposta.recusado == 0 && proposta.aceito == 1" class="px-3 py-1 d-block d-lg-inline mb-2 mb-lg-0 small alert alert-success">aceita</span>
				                <span v-else-if="proposta.cancelado == 1 && proposta.recusado == 0 && proposta.aceito == 0" class="px-3 py-1 d-block d-lg-inline mb-2 mb-lg-0 small alert alert-danger">cancelada</span> 
				                <small>R$</small> {{ proposta.valor }}
							</h3>
							<h5 class="small mb-0 align-self-center">
                                {{ created_at(proposta.created_at) }}
                            </h5>
						</div>
						<div :id="'collapse_'+index" class="collapse" :aria-labelledby="'heading_'+index" data-parent="#propostas">
							<div class="card-body row justify-content-center align-itens-center">
								<div class="col-12 text-center" v-if="proposta.cancelado == 0 && proposta.recusado == 0 && proposta.aceito == 0">
					                <div class="text-center py-4 alert alert-warning">Seu telefone e e-mail foram passados para o anunciante, aguardando resposta da proposta (<strong>aceita</strong> ou <strong>rejeitada</strong>)</div>
					                <button @click="abrirModalCancelar(proposta.id)" class="btn btn-danger btn-sm">Cancelar</button>
								</div>
								<div class="col-12 text-center" v-if="proposta.cancelado == 0 && proposta.recusado == 1 && proposta.aceito == 0">
					                <div class="text-center py-4 alert alert-warning">Sua proposta foi recusada, você pode fazer uma nova proposta.</div>
								</div>
								<div class="col-12 text-center" v-if="proposta.cancelado == 0 && proposta.recusado == 0 && proposta.aceito == 1">
					                <div class="text-center py-4 alert alert-success">Sua proposta foi <strong>aceita</strong> entre em contato com o anunciante pelo telefone abaixo para acertar a transferência, pagamento e solicitar a geração do contrato de compra e venda.</div>
					                <h2><a :href="'tel:+55'+proposta.produto.autor.telefone.numero.replace(/\D/g,'')">{{ proposta.produto.autor.telefone.numero }}</a></h2>
					                <button v-if="proposta.contrato == null" disabled class="btn btn-primary btn-sm"><i class="fa fa-file-signature mr-2"></i>Aguardando a emissão do contrato</button>

					                <a v-if="proposta.contrato != null && proposta.contrato.status == 1" href="/meus-contratos" class="btn btn-primary btn-sm"><i class="fa fa-file-signature mr-2"></i>Visualizar seus contratos</a>
					                <button @click="abrirModalCancelar(proposta.id)" class="btn btn-danger btn-sm">Cancelar</button>
								</div>
								<div class="col-12 text-center" v-if="proposta.cancelado == 1 && proposta.recusado == 0 && proposta.aceito == 0">
					                <div class="text-center py-4 alert alert-warning">Seu telefone e e-mail foram ocultados do anunciante.</div>
								</div>
								<div class="modal" :id="'modalCancelar_'+proposta.id" tabindex="-1" role="dialog" aria-hidden="true">
					                <div class="modal-dialog modal-full" role="document">
					                    <div class="modal-content rounded-0">
					                        <div class="modal-header my-auto">
					                            <h5 class="mb-0 text-muted">
					                                Cancelar proposta
					                            </h5>
					                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
					                                <i class="fa fa-times"></i>
					                            </button>
					                        </div>
					                        <div class="modal-body p-0" id="result">
					                            <div class="row text-center justify-content-center">
					                                <div class="col-md-12 col-lg-10 pt-7">
					                                    <h2 class="mb-2 mb-lg-0">
					                                        Olá {{ userNome }},
					                                    </h2>
					                                    <div class="lead mb-7">
					                                        <p class="mb-0">Deseja realmente cancelar a proposta feita?</p>
					                                        <div v-if="proposta.produto.contrato.length > 0" v-for="contrato in proposta.produto.contrato">
					                                            <p class="mb-0" v-if="proposta.id == contrato.proposta_id">Você tem um contrato ativo, o mesmo será cancelado juntamente com a proposta e qualquer cupom de desconto vinculado.</p>
					                                        </div>
					                                    </div>
					                                </div>
					                            </div>
					                        </div>
					                        <div class="modal-footer">
					                            <button @click="cancelarProposta(proposta.id)" class="btn btn-primary"><i class="fa fa-times mr-3"></i>Cancelar proposta</button>
					                        </div>
					                    </div>
					                </div>
					            </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import dayjs from 'dayjs';
	export default {
		name: 'FezPropostas',
		props: {
			userNome: String,
		},
		data() {
			return {
				propostas: [],
                produto: window.location.pathname.replace('/market/produto/', ''),
			}
		},
		mounted() {
			this.carregarPropostas();
            $(document).on('shown.bs.modal', '.modal', function () {
                $('.modal-backdrop').before($(this));
            });
		},
		methods: {
			created_at: function(data) {
                return dayjs(data).format('DD/MM/YYYY');
            },
			carregarPropostas: function () {
                axios.get('/produto/'+ this.produto +'/propostas')
                .then((res) => {
                    this.propostas = res.data;
                });
            },
            abrirModalCancelar: function (id) {
                $('div[id="modalCancelar_'+id+'"]').modal('show');
            },
            fecharModalCancelar: function (id) {
                $('div[id="modalCancelar_'+id+'"]').modal('hide');
            },
            cancelarProposta: function(id) {
                axios.post('/produto/'+ this.produto +'/proposta/'+ id +'/cancelar')
                .then((res) => {
                    this.propostas = res.data;
                    this.fecharModalCancelar(id);
                });
            },
		}
	}
</script>